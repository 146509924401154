import React from 'react'
import {Link} from 'react-router-dom'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'


const CustomToolbar = props => {

    let detailsHeadClass = 'hide__container';
    let notificationHeadClass = 'hide__container';

    if( props.propsName == 'fixes') 
    {
        detailsHeadClass = '';
    }
    else if( props.propsName == 'notificationDetails' || props.propsName == 'aboutDetails' ) 
    {
        notificationHeadClass = '';
    }

    return(
        <header className="toolbar">
            
            {/* Details head section */}
            <nav className="toolbar__navigation" id={detailsHeadClass}>
                <div className="toggle___button">
                    <DrawerToggleButton click={props.drawerClickHandler} />
                </div>
                <p className="prof_hd header__title__main">{props.headTitle}</p>
                <div className="spacer" />
            </nav>

            <nav className="toolbar__navigation" id={notificationHeadClass}>
                <div className="toggle___button">
                    <Link className="colorWhite" to={props.backLink}><i class="material-icons backBtnClas">west</i></Link>
                </div>
                <p className="prof_hd header__title__main">{props.headTitle}</p>
                <div className="spacer" />
            </nav>


        </header>
    )
}
 
export default CustomToolbar