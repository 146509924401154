import React, {Component} from 'react'
// import './AllFixes.css'

class NotificationComp extends Component  {

render() {
    return (
        <div>
           <img src="assets/images/notification.png" />
            <div class="container-fluid mt-3">
                {/* <div class="row">
                    <div class="col-md-8 col-12">
                        <div class="row mb-3">
                            <div class="col-md-12 col-12">
                                <div class="custom-notification-grid mb-2">
                                    <div class="row">
                                        <div class="col-md-2 col-2">
                                            <img src="assets/images/notification-icon.png" class="notification-icon-img" />
                                        </div>
                                        <div class="col-md-10 col-10 pl-0">
                                            <h5 class="notification-title">Your Fixes just got more secure!</h5>
                                            <p class="notification-description">Enter more details about the ticket type to explain what attendees will receive.</p>
                                        </div>
                                        <div class="col-md-12 col-12">
                                            <h6 class="notification-time">11 days ago</h6>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div> */}

                <div class="text-center w-100 custom-screen-middle">
                    {/* <img src="assets/images/empty-ticket.png" class="empty-ticket-img" /> */}
                    <h5 class="empty-ticket-title">No Notification</h5>
                </div>

            </div>
        </div>
    )
    }
}

export default NotificationComp