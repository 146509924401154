import React, {Component} from 'react'
import { Link, Redirect } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import Select from 'react-select';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const colourOptions = [];

class CompAddSkill extends Component {

    constructor() {
        super();
        this.state = {
            certificate_fixers: [],
            noCertificateClass: 'hide__container',
            imageFile: ''
        };
        this.getSkillDetails = this.getSkillDetails.bind(this)
        this.submitSkillEdit = this.submitSkillEdit.bind(this)
        this.getFixerSkills = this.getFixerSkills.bind(this)
        this.handleChange = this.handleChange.bind(this)
      }
 
      async componentDidMount() {
        await this.getSkillDetails();
        this.getFixerSkills();
      }
      
      async getSkillDetails() {
        const body = {
			user_id: cookies.get('techUserId')
        };

        await axiosInstance.post(`getUserSkillsDetails`, body)
        .then(res => {
           if(res.data.status == 'success' && res.data.statusCode =='200' )
           {	

            let skill_details = res.data.skill_details;
            let skillDetails = skill_details.map(skill_category => { return skill_category });
            this.setState({ skills: skillDetails });
  
            colourOptions.push(skill_details);

            this.setState({dropdownloaded: true})
           }
        })
        .catch(err => console.log(err));
      }

      getFixerSkills() {
        const body = {
			user_id: cookies.get('techUserId')
        };
            
        axiosInstance.post(`getFixerSkills`, body)
        .then(res => {
           if(res.data.status == 'success' && res.data.statusCode =='200' )
           {	
                let default_values = res.data.skill_details[0].skill;
                this.setState({default_values})
                this.setState({skill: default_values})
           }
        })
        .catch(err => console.log('Signup: ', err));
      }

      handleChange = (selectedOptions) => {
        // console.log(selectedOptions)
        //console.log(selectedOptions[0].skill_category)
  
        const options_1 = [];
        for(var i=0; i < selectedOptions.length; i++) {
            options_1.push(selectedOptions[i].value)
        }

        let def_value = this.state.default_values;
        let deflt_arr = def_value.split(',')

        for(var j=0; j < deflt_arr.length; j++) {
            options_1.push(deflt_arr[j])
        }

        var x_skills = options_1.toString();

        //console.log(deflt_arr)
        this.setState({skill: x_skills})

      }
        

      submitSkillEdit() {

        //show spinner 
        let spinner = 'lds-dual-ring custom-screen-middle';
        let overlay = 'overlay';
        this.setState({ spinner, overlay });

        const body = {
            user_id: cookies.get('techUserId'),
            skill: this.state.skill
        };

        axiosInstance.post(`updateUserSkills`, body)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            //hide spinner
            let spinner = 'lds-dual-ring custom-screen-middle hide__container';
            let overlay = 'overlay hide__container';
            this.setState({ spinner, overlay });

            this.setState({skillRedirect: true});
        }
        else if(res.data.statusCode ==='400' ) {
            
        }
        })
        .catch(err => console.log('Login: ', err));
        
    }

    render() {

        if (this.state.skillRedirect) {
            return <Redirect to="/Skills"/>;
        }

    return (
        <div class="mt-3 main-profilee">

            <div className="tickmarkClass">
                <Link onClick={this.submitSkillEdit}><i class="material-icons backBtnClas">check</i></Link>
            </div>

            <div className="col-md-12 col-12 mb-2">
                <label className="mb-1" style={{display: 'block'}}>Skills</label>

                {this.state.dropdownloaded ? 
                    <Select 
                        className="select__skill_category"
                        defaultValue={[colourOptions[2], colourOptions[3]]} 
                        isMulti
                        name="colors"
                        options={this.state.skills}
                        className="basic-multi-select"
                        classNamePrefix="select" 
                        onChange={this.handleChange}
                    />
                    :
                    null
                }
                
            </div>

            <div className={this.state.overlay}>
			    <div className={this.state.spinner}></div>
		    </div>

        </div>
    )
}
}
 
export default CompAddSkill