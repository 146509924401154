import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import Logo from '../logo-ts.png';
import { axiosInstance } from '../Services/PostData';
import { SocketConnection } from '../Services/SocketConnection';
import swal from 'sweetalert2';

import io from "socket.io-client";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

let socket = io.connect(SocketConnection)

class Otp extends Component { 

	constructor(){
		super();
		this.state = {
			otpValue: '',
			user_id:'',
			otpid: ''
		};
		this.onChangeEvent	  = this.onChangeEvent.bind(this);
		this.otpConfirmation  = this.otpConfirmation.bind(this);
		this.resendOtp  = this.resendOtp.bind(this);
		// this.getParamsDetails = this.getParamsDetails.bind(this);
	}

	onChangeEvent(e) {
		this.setState({[e.target.name]: e.target.value})
	}

	otpConfirmation()
	{		
		let errorMessageresponse = '';
		let params = queryString.parse(this.props.location.search);
		this.state.user_id = params.userid;
		this.state.otpid = params.otpid;

		if(this.state.user_id == undefined && this.state.otpid != '')
		{
			let spinner = 'lds-dual-ring custom-screen-middle';
			let overlay = 'overlay';
			this.setState({ spinner, overlay });

			const body = {
				otpValue: this.state.otpValue,
				userdetails: this.state.otpid
			  };
	
			axiosInstance.post(`otpConfirmation`, body)
			.then(res => {
			if(res.data.status === 'success' && res.data.statusCode ==='200' )
			{
				// this.props.history.push("/ForgotPassword");
				this.props.history.push({
					pathname: '/ForgotPassword',
					search: '?userid=' + this.state.otpid
				  })
			}
			else if(res.data.statusCode ==='400' ) {
				errorMessageresponse = res.data.msg
				this.setState({ errorMessageresponse});
			}

			let spinner = 'lds-dual-ring custom-screen-middle hide__container';
			let overlay = 'overlay hide__container';
			this.setState({ spinner, overlay });

			})
			.catch(err => console.log('Login: ', err));
		}
		else {

		const body = {
			otpValue: this.state.otpValue,
			userdetails: this.state.user_id
			};
		
		axiosInstance.post(`otpConfirmation`, body)
		.then(res => {
		if(res.data.status === 'success' && res.data.statusCode ==='200' )
		{
			let user_id = res.data.userDetails;
			if(res.data.approval_status == '0')
			{
				// localStorage.setItem('techUserId', res.data.userDetails)
				cookies.set('techUserId', res.data.userDetails );

				let socket_subs_id = "tech-user" + user_id;
				cookies.set('tech_socket_subscribeId', socket_subs_id );
				socket.emit('join room', socket_subs_id)

				this.props.history.push({
					pathname: '/ProfileSignup',
					search: '?userDetails=' + res.data.userDetails
				})

			}
			else {
				cookies.set('techUserId', res.data.userDetails );
				
				let socket_subs_id = "tech-user" + user_id;
				cookies.set('tech_socket_subscribeId', socket_subs_id );
				socket.emit('join room', socket_subs_id)

				// this.props.history.push({
				// 	pathname: '/Dashboard',
				// 	search: '?userid=' + res.data.userDetails
				//   })

				this.props.history.push({
					pathname: '/Profile'
				  })

				  socket.emit('join room', user_id)
			}
			
		}
		else if(res.data.statusCode ==='400' ) {
			errorMessageresponse = res.data.msg
			this.setState({ errorMessageresponse});
		}
		 })
		 .catch(err => console.log('Login: ', err));

		}
	}

	resendOtp() {

		let errorMessageresponse = '';
		let params = queryString.parse(this.props.location.search);
		let user_id = params.userid;
		let otp_id = params.otpid;
		let resend_id = '';

		if(user_id == undefined) {
			resend_id = otp_id
		}
		else if(otp_id == undefined) {
			resend_id = user_id
		}

		let spinner = 'lds-dual-ring custom-screen-middle';
		let overlay = 'overlay';
		this.setState({ spinner, overlay });

		const body = { user_id: resend_id };

		axiosInstance.post(`resendOtp`, body)
		.then(res => {
		if(res.data.status === 'success' && res.data.statusCode ==='200' )
		{
			let spinner = 'lds-dual-ring custom-screen-middle hide__container';
			let overlay = 'overlay hide__container';
			this.setState({ spinner, overlay });
		
			swal.fire({
				title: 'OTP sent to your register email',
				confirmButtonText: 'ok',
				confirmButtonColor: '#D90429'
			}).then ((result) => { })
		}
		else if(res.data.statusCode ==='400' ) {
			errorMessageresponse = res.data.msg
			this.setState({ errorMessageresponse});
		}

		})
		.catch(err => console.log('Login: ', err));
	}

	// getParamsDetails() {
	// 	let params = queryString.parse(this.props.location.search);
	// 	this.state.user_id = params.userid;
	// }
	componentDidMount() {
		var a = document.getElementById("otpValue"),
			b = document.getElementById("submit");
		a.focus();
		a.onkeyup = function() {
			if (this.value.length === parseInt(this.attributes["maxlength"].value)) {
				// a.classList.add("customwidth");
				b.focus();
			}
		}
	}

render() {
	
    return (
        <div className="limiter">
		<div className="container-login100 otp-screen">
			<div className="wrap-login100 p-l-55 p-r-55 p-t-20 p-b-20">
				<div className="text-center">
					{/* <img className="customlogo" src={Logo} alt="" /> */}
					<img  src="assets/gif/otp.gif" alt="" style={{width: '250px'}} />
				</div>
				<div className="text-center">
					<p className="color-tmp-1 otp-vrfy-lbl">OTP Verification</p>
					<p className="color-tmp-1 otp-msg">If OTP not Received please check registered email or resent OTP to email</p>
				</div>
				<form method="post" className="login100-form validate-form">
					{/* <span className="login100-form-title p-b-30">OTP</span> */}
					
					<div className="validate-input m-b-10 text-center">
						<input className=" otp_field_custom inpt-rnd" type="number" id="otpValue" name="otpValue" maxLength="4" 
								onChange={this.onChangeEvent} autoComplete='off' 
								/>
					</div>

					<div  className="m-b-5 text-center m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#D90429" }}>
						{this.state.errorMessageresponse}
					</div>
					
					<p className="cursorPointer text-center p-b-20" onClick={this.resendOtp}>
						Dont receive OTP ? <span className="color-tmp-5 linkUnderline">Resend OTP</span>
					</p>

					{/* <div className="p-t-8 p-b-50 p-r-5">
						<div className="float-right">
						<p className="cursorPointer linkUnderline p-t-6" onClick={this.resendOtp}> Resend OTP </p>
						</div>
						<div className="float-left">
							<Link to='/' className="txt2">
								Login
							</Link>
						</div>
					</div>	 */}
					
					<div className="container-login100-form-btn">
						<div className="wrap-login100-form-btn">
							<div className="login100-form-bgbtn"></div>
							<p className="custom-a-line-none">
								<button type="button" className="login100-form-btn" id="submit" onClick={this.otpConfirmation}> 
								Verify OTP
							</button>
                            </p>
						</div>
					</div>

				</form>
			</div>
		</div>

		<div className={this.state.overlay}>
			<div className={this.state.spinner}></div>
		</div>

	</div>
)
}
}

export default Otp