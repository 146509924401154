import React, {Component} from 'react'
import { Link } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import './CompSkills.css'
import swal from 'sweetalert2';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class CompAddCertificate extends Component {

    constructor() {
        super();
        this.state = {
            skill_fixers: [],
            noskillClass: 'hide__container'
        };
        this.getskills = this.getskills.bind(this)
      }
 
      componentDidMount() {
        this.getskills();
      }
      
      getskills() {
        const body = {
			user_id: cookies.get('techUserId')
        };
            
        axiosInstance.post(`getFixerSkills`, body)
        .then(res => {
           if(res.data.status == 'success' && res.data.statusCode =='200' )
           {	
               if(res.data.skill_details.length == 0)
               {
                   this.state.noskillClass = 'show';
               }
               let user_skill_details_ids = res.data.skill_details[0].skill;

               let skill_details = res.data.skill_details[0].user_skill_details;
               let skillDetails = skill_details.map(skill => {
                   return skill
               });
               this.setState({
                   skill_fixers: skillDetails,
                   user_skill_details_ids
               });
           }
        })
        .catch(err => console.log('Signup: ', err));
      }

      deleteSkillFunction(id, lable) {
        swal.fire({
            text: "Are you sure delete " +lable+ " Skill",
            showCancelButton: true,
            confirmButtonColor: '#D90429',
            confirmButtonText: 'Yes',
            cancelButtonText: "Cancel"
        }).then((result) => {
            if (result.value) {

                const options_1 = [];
                let def_value = this.state.user_skill_details_ids;
                let deflt_arr = def_value.split(',')

                for(var j=0; j < deflt_arr.length; j++) 
                {
                    if(deflt_arr[j] !== id)
                    {
                        options_1.push(deflt_arr[j])
                    }
                }
                var x_skills = options_1.toString();
                this.updateSkillDetails(x_skills)
            }
        });
      }

      updateSkillDetails(skills) {

        //show spinner 
        let spinner = 'lds-dual-ring custom-screen-middle';
        let overlay = 'overlay';
        this.setState({ spinner, overlay });

        const body = {
            user_id: cookies.get('techUserId'),
            skill: skills
        };

        axiosInstance.post(`updateUserSkills`, body)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            //hide spinner
            let spinner = 'lds-dual-ring custom-screen-middle hide__container';
            let overlay = 'overlay hide__container';
            this.setState({ spinner, overlay });

            this.getskills();
        }
        
        })
        .catch(err => console.log('Login: ', err));

      }
    render() {
        
    return (
        <div class="mt-3">
            <div class="" style={{padding: '10px'}}>
                {this.state.skill_fixers.map(skills => (
                    <p className="skillList__pr">
                        <i class="fa fa-tasks" ></i> {skills.label}
                        <i class="fa fa-trash" style={{float: 'right'}}
                           onClick={() => this.deleteSkillFunction(skills.value, skills.label) }></i>
                    </p>
			    ))}
            </div>
        </div>
    )
}
}
 
export default CompAddCertificate