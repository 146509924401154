import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import './ProfileToolbar.css'
import swal from 'sweetalert2';
import { axiosInstance } from '../../Services/PostData';
import { SocketConnection } from '../../Services/SocketConnection';
import io from "socket.io-client";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let socket = io.connect(SocketConnection)

const ProfileToolbar = props => {

    // console.log(props.drawerClickHandler)

    
    let routeRedirect = false

    let mainHeadClass = 'hide__container';
    let detailsHeadClass = 'hide__container';
    let addHeadClass = 'hide__container';
    let addClassName = '';
    let tickClassName = 'hide__container';
    let pencilClassName = '';
    let chatHeadClass = 'hide__container';

    if(props.propsName == 'profile' || props.propsName == 'fixes' ) {
        mainHeadClass = '';

        if( props.propsName == 'fixes') {
            pencilClassName = 'hide__container';
        }
    }
    else if( props.propsName == 'certificateDetails' || 
             props.propsName == 'skillDetails' || 
             props.propsName == 'editProfile' || 
             props.propsName == 'fixesDetails' || 
             props.propsName == 'chatDetails' || 
             props.propsName == 'authNumber' ) 
             {
                detailsHeadClass = '';

                if( 
                    // props.propsName == 'skillDetails' ||
                    props.propsName == 'authNumber' || 
                    props.propsName == 'editProfile' || 
                    props.propsName == 'chatDetails' || 
                    props.propsName == 'fixesDetails')
                {
                    addClassName = 'hide__container';
                }

                if(props.propsName == 'editProfile') {
                    addClassName = 'hide__container';
                    tickClassName = '';
                }
    }
    else if(props.propsName == 'addCertificate') {
        addHeadClass = '';
    }
    else if(props.propsName == 'chatDetailsTech') {
        chatHeadClass = '';
    }

    //***** Handling Submit options here ******
    
    function clickFunction() {
        swal.fire({
            title: "Are you sure?",
            text: "your chat will end and your ticket will assign to closed",
            showCancelButton: true,
            confirmButtonColor: '#D90429',
            cancelButtonText: "No",
            confirmButtonText: 'Yes',
            closeOnConfirm: false,
            closeOnCancel: false
          }).then((result) => {
            if (result.value) {
                routeRedirect = true
                if(routeRedirect) {
                    // return <Redirect to='/Dasahboard' />
                    // window.location.href("http://localhost:3000")
                    // window.location.replace("http://localhost:3000/Dashboard")
                    cancelFixes();
                }
            }
          })
    }

    function cancelFixes() {
        let queryValue = cookies.get('sessionTechChatRoomId');
        const body = { chat_room_id: queryValue };
        axiosInstance.post(`closeFixes`, body)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
        {
            let to_connect = "customer-user"+ res.data.cus_user;
            let message = 'Fixer closed your ticket';
            socket.emit('closeChatRoonConnection', to_connect, message)

            cookies.remove('sessionTechChatRoomId');
            window.location.reload(false)
        }
        })
        .catch(err => console.log('Login: ', err));
  
      }

    return(
        <header className="toolbar">
            
            {/* main head sectio */}
            <nav className="toolbar__navigation" id={mainHeadClass}>
                <div className="toggle___button">
                    <DrawerToggleButton click={props.drawerClickHandler} />
                </div>
                <p className="prof_hd header__title__main">{props.headTitle}</p>
                <div className="spacer" />
                <div className="profEditBtnPnc" id={pencilClassName}>
                    <Link className="colorWhite" to="/ProfileEdit"><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                </div>
            </nav>
            
            {/* Details head section */}
            <nav className="toolbar__navigation" id={detailsHeadClass}>
                <div className="toggle___button">
                    <Link className="colorWhite" to={props.backLink}><i className="material-icons backBtnClas">west</i></Link>
                </div>
                <p className="prof_hd header__title__main">{props.headTitle}</p>
                <div className="spacer" />
                
                {/* Plus (add) icon */}
                <div className="profEditBtnPnc" id={addClassName} >
                    <Link className="colorWhite" to={props.addLink}><i className="fa fa-plus" aria-hidden="true"></i></Link>
                </div>

                {/* Tick (submit) icon */}
                {/* <div className="profEditBtnPnc" id={tickClassName} >
                    <Link className="colorWhite" onClick={submitFunction}><i className="material-icons backBtnClas">check</i></Link>
                </div> */}
            </nav>
            
            {/* Details head section */}
            <nav className="toolbar__navigation" id={addHeadClass}>
                <div className="toggle___button">
                <Link className="colorWhite" to={props.backLink}><i className="material-icons backBtnClas">close</i></Link>
                </div>
                <p className="prof_hd header__title__main">{props.headTitle}</p>
                <div className="spacer" />
                {/* <div className="profEditBtnPnc">
                <Link className="colorWhite" to={props.submitLink}><i className="material-icons backBtnClas">check</i></Link>
                </div> */}
            </nav>

            {/* Chat head section */}
            <nav className="toolbar__navigation" id={chatHeadClass}>
                <div className="toggle___button">
                    <p className="colorWhite" onClick={clickFunction}><i class="material-icons backBtnClas">west</i></p>
                </div>
                <p className="prof_hd header__title__main">{props.headTitle}</p>
                <div className="spacer" />
                <Link to="/Dashboard" className="colorWhite"><i class="material-icons backBtnClas">remove</i></Link>
            </nav>


        </header>
    )
}
 
export default ProfileToolbar