import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../logo-ts.png';
import { axiosInstance } from '../Services/PostData';
import queryString from 'query-string';

class ForgotPassword extends Component {

    constructor(){
		super();
		this.state = {
			user_id: '',
			password: ''
		};
		this.submitFunction = this.submitFunction.bind(this)
		this.onChange = this.onChange.bind(this)
	}

	validate = () => {
		let passwordError = "";
		let confirmPasswordError = "";

		//username
		const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

		if( this.state.password.length < 8) {
			passwordError = "Password should be 8 characters";
		}
		else if( ! strongRegex.test(this.state.password)) {
			passwordError = "use special character, number and capital letter";
		}
        //username

        if(this.state.password !== this.state.confirm_password)
		{
			confirmPasswordError = "Password should be same";
		}

		if (passwordError || confirmPasswordError ) {
		  this.setState({ passwordError, confirmPasswordError});
		  return false;
		}
	
		return true;

	  };

	submitFunction() {

        let params = queryString.parse(this.props.location.search);
		this.state.user_id = params.userid;

		const user = {
            password: this.state.password,
            user_id: this.state.user_id
		  };

		  const isValid = this.validate();
    		if (isValid) {
				let passwordError = '';
				this.setState({ passwordError});
				
				let errorMessageresponse = '';
				const body = user;
				axiosInstance.post(`updatePassword`, body)
		 		.then(res => {
			  	if(res.data.status === 'success' && res.data.statusCode ==='200' )
			  	{
				//this.props.history.push("/Otp");
				  this.props.history.push({
					pathname: '/'
				  })
				}
				else if(res.data.statusCode ==='400' ) {
					errorMessageresponse = res.data.msg
					this.setState({ errorMessageresponse});
				}
		 		})
		 		.catch(err => console.log('Login: ', err));
			}

    }

    onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}
    
    render() {
    return (
        <div class="limiter">
        <div class="container-login100 login-bg-screen">
            <div class="wrap-login100 p-l-55 p-r-55 p-t-20 p-b-20">
                <div class="text-center"><img class="customlogo" src={Logo} alt="" /></div>
                <form method="post" class="login100-form validate-form">
                    <span class="login100-form-title p-b-30">
                        Reset Password
                    </span>

                    <div class="wrap-input100 validate-input m-b-23">
                        <span class="label-input100">New Password</span>
                        <input class="input100 inpt-rnd" type="password" name="password" placeholder="Type New Password" onChange={this.onChange} />
                        <span class="focus-input100" data-symbol="&#xf190;"></span>
                    </div>
                    <span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.passwordError}</span>

                    <div class="wrap-input100 validate-input">
                        <span class="label-input100">Confirm Password</span>
                        <input class="input100 inpt-rnd" type="password" name="confirm_password" placeholder="Confirm Password" onChange={this.onChange} />
                        <span class="focus-input100" data-symbol="&#x2714;"></span>
                    </div>
                    <span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.confirmPasswordError}</span>

                    <div class="p-t-8 p-b-24 p-r-5">
                        <div class="float-right">
                            <Link to="/" class="txt2" style={{textDecoration: 'none'}} >
                                Login
                            </Link>
                        </div>
                    </div>

                    <div class="container-login100-form-btn p-t-20">
                        <div class="wrap-login100-form-btn custom-box-none">
                            <button type="button" class="login100-form-btn custom-btn float-right" onClick={this.submitFunction}>
                                    Submit
                                </button>
                            <Link to="/" className="linknounderline"><button type="button" class="login100-form-btn custom-btn">
                                Cancel
                            </button>
							</Link>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
    )
}
}

export default ForgotPassword