import React, {Component} from 'react';
import PaymentCardComp from '../component/Payment/PaymentCardComp'
import CustomToolbar from '../component/Toolbar/CustomToolbar'

class PaymentCard extends Component {

    state = { sideDrawerOpen: false }

      drawerToggleClickHandler = () => {
        this.setState(prevState => {
          return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
      }

      backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
      }
      

    render() {
        
        return (
            <div style={{height: '100%'}}>

                <CustomToolbar drawerClickHandler={this.drawerToggleClickHandler} 
                         propsName = 'notificationDetails' 
                         headTitle = 'Payment Card'
                         addLink = ''
                         backLink = '/Dashboard' />


                <main>
                <div className="top__align__tool_nv"></div>
                  <PaymentCardComp />
                </main>

            </div>
        )
    }

}

export default PaymentCard
