import React, {Component} from 'react'
import { Link, Redirect, Route, Switch } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import { SocketConnection } from '../../Services/SocketConnection'
import './FixesNotificationComp.css'
import swal from 'sweetalert2';
import io from "socket.io-client";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

let socket = io.connect(SocketConnection)

class FixesNotificationComp extends Component {

    timer=0;
    constructor(){
		super();
		this.state = {
            responseValue: [],
            chatId: ''
        };
		this.getFixesRequest = this.getFixesRequest.bind(this);
		this.submitFixesRequestReply = this.submitFixesRequestReply.bind(this);
	}

    componentDidMount() { 
        if(cookies.get('techUserId') == undefined) {
            this.setState({loginRdirect: true})
        }
        else {
            this.getFixesRequest(); 
            socket.emit('join room', cookies.get('tech_socket_subscribeId'));
            socket.on('tech_fixer_notification', (name,message,data) => {
    
                let notTicketFound = 'hide__container';
                this.setState({ notTicketFound });
    
                let response = data;
                let responseDetails = response.map(detail_values => { return detail_values });
                this.setState({ responseValue: responseDetails });
            });
            
            //*** Snooze message notification ***
            socket.on('sendChatConfirmation', (name, message, type) => {
                if(type == 'snooze_reject') {
                    swal.fire({
                        text: message,
                        type: 'info'
                    }).then ((result) => { window.location.reload(false) })
                }
                else if(type == 'snooze_accept') {
                    swal.fire({
                        text: message,
                        type: 'info'
                    }).then ((result) => { window.location.reload(false) })
                }
            });

            //***close Request ***
            socket.on('closeChatRoonConnection', (name, message) => {
                swal.fire({
                    text: message,
                    type: 'info'
                  }).then ((result) => { this.setState({dashboardRedirect: true}) })
            })
        }
            
    }
  
	getFixesRequest() {

            const body = { tech_user_id: cookies.get('techUserId')};
		    axiosInstance.post(`getFixesRequest`, body)
		    .then(res => {
		    if(res.data.status === 'success' && res.data.statusCode ==='200' ) {
                
                let response = res.data.fixes_details;
                
                if(response.length !=0) {
                    let notTicketFound = 'hide__container';
                    this.setState({ notTicketFound });
                }
                let responseDetails = response.map(detail_values => { return detail_values });
		    	this.setState({ responseValue: responseDetails });
            }
		    })
            .catch(err => console.log('Login: ', err));
            

        // var notifiFunc = async function() {
        //     const body = { tech_user_id: cookies.get('techUserId')};
		//     await axiosInstance.post(`getFixesRequest`, body)
		//     .then(res => {
		//     if(res.data.status === 'success' && res.data.statusCode ==='200' ) {
                
        //         let response = res.data.fixes_details;
        //         if(response.length !=0) {
        //             let notTicketFound = 'hide__container';
        //             this.setState({ notTicketFound });
        //         }
        //         let responseDetails = response.map(detail_values => { return detail_values });
		//     	this.setState({ responseValue: responseDetails });
        //     }
		//     })
		//     .catch(err => console.log('Login: ', err));
        // }.bind(this);
                
        //  notifiFunc();
        //  this.timer=setInterval(notifiFunc, 10000)
    }


    fixesRequestReply(id, status) {

        let textMessage = '';
        if(status == 0) {
            textMessage = "Not ready to work on this ticket ?";
        }
        else if(status == 1) {
            textMessage = "Ready to work on this ticket!";
        }
        else if(status == 2) {
            textMessage = "You want to snooze on this ticket ?";
        }

        swal.fire({
            title: "Are you sure?",
            text: textMessage,
            showCancelButton: true,
            confirmButtonColor: '#D90429',
            cancelButtonText: "No",
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                // this.submitFixesRequestReply(id, status);
                this.getReason(id, status);
            }
        })
    }

    getReason(id, status) {
        if(status == 0) {
            swal.fire({
                text: "Enter your reason here",
                input: 'text',
                showCancelButton: false,
                confirmButtonColor: '#D90429',
                confirmButtonText: 'submit',
                closeOnConfirm: false,
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                  this.submitFixesRequestReply(id, status, result.value);
                }
            });
        }
        else {
            let reason = '';
            this.submitFixesRequestReply(id, status, reason);
        }
        
      }

    submitFixesRequestReply(id, status, reason) {

        const body = {
            fixes_request_id: id,
            approval_status: status,
            reason: reason
		  };

		axiosInstance.post(`fixesRequestReply`, body)
		.then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' ) {
            if(status == 1) {

                let to_connect = 'customer-user' + res.data.cus_user_id;
                let message = "fixers Accepted your ticket";
                let type = status
                
                socket.emit('sendChatConfirmation', to_connect, message, type)

                swal.fire({
                    text: 'Ticket created succesfully',
                    type: 'success'
                // }).then ((result) => { this.setState({ chatId : res.data.chatId, chatRedirect: true }) })
                }).then ((result) => { 
                    cookies.set('sessionTechChatRoomId', res.data.chatId )                    
                    this.setState({ chatRedirect: true  }) 
                })
            }
            else if(status == 0) {

                let to_connect = 'customer-user' + res.data.cus_user_id;
                let message = "Fixer rejected your request";
                let type = 0

                socket.emit('sendChatConfirmation', to_connect, message, type)

                swal.fire({
                    text: 'Request rejected',
                    type: 'success'
                }).then ((result) => { window.location.reload(false) })
            }
            else if(status == 2) {

                let to_connect = 'customer-user' + res.data.cus_user_id;
                let message = "Fixer snooze your request. Are you ready to wait ?";
                let type = 2

                socket.emit('sendChatConfirmation', to_connect, message, type)

                swal.fire({
                    text: 'Ticket Snoozed request sent',
                    type: 'success'
                }).then ((result) => { window.location.reload(false) })
            }
                
        }
        })
        .catch(err => console.log(err));
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    
    

    render() {
        if(this.state.chatRedirect) {
            // return <Redirect to="/ChatDetails?chatId="/>;
            return <Redirect to={{  pathname: '/ChatDetails'  }} />;
            // return <Redirect to={{
            //                     pathname: '/ChatDetails',
            //                     search: '?chatId='+this.state.chatId }} />;
            
        }

        if(this.state.loginRdirect) {
            return <Redirect to="/" />;
        }

        if(this.state.dashboardRedirect) {
            return <Redirect to="/Dashboard" />;
        }

    return (
        
    <div class="fixes__details">
        <div class="fixes__head"></div>
        <div class="fixes__content">


                    {/* <button onClick={() => this.send() }>Connect</button>
                    <input type="text" id="name" /> */}
                    <div id="users"></div>

                    {/* <button onClick={() => this.message() }>Send message</button>
                    <input type="text" id="messagevalue" /> */}
                    <div id="message"></div>   


            <div className={this.state.notTicketFound} style={{margin: '15px', backgroundColor: '#ffffff'}}>
                {/* No Fixes found */}
                <div class="text-center w-100 custom-screen-middle">
                    <img src="assets/images/empty-ticket.png" class="empty-ticket-img" />
                    <h5 class="empty-ticket-title">No Request</h5>

                </div>
            </div>


            {this.state.responseValue.map(values => (

            <div className="fixes__content_sng" key={values.fixes_request_id}>
                <div className="fixes__content_sng_hd connectmsg">
                    <p className="fixes__content_sng_hd_ttl"> {values.cus_user_name} wants to connect with you </p>
                </div>
                {/* <br /> */}
                <p className="snze-msg">{values.snooze_status}</p>
                <div className="fixesAcceptConfirmation">
                    <p className="reject" onClick={() => this.fixesRequestReply(values.fixes_request_id, '0' )} >
                    <span className="material-icons">highlight_off</span> Reject
                    </p>
                    <p className="accept" onClick={() => this.fixesRequestReply(values.fixes_request_id, '1' )} >
                        <span className="material-icons">done</span> Accept
                    </p>

                    { values.snooze_show =='1' ? 
                        <p className="snooze" onClick={() => this.fixesRequestReply(values.fixes_request_id, '2' )} >
                            <span className="material-icons">snooze</span> Snooze
                        </p>
                        :
                        null
                    }
                    
                </div>
            </div>
            
            ))}
            
        </div>
    </div>


  )
}
}

export default FixesNotificationComp