import React, {Component} from 'react'
import { Link, Redirect } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import './FixesDetails.css'
import { confirmAlert } from 'react-confirm-alert';
import queryString from 'query-string';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class FixesDetails extends Component {

    constructor(){
		super();
		this.state = {
            fixesDetails: '',
            tickets:[],
            updatedStatusClass: ''
		};
		this.getFixesDetails = this.getFixesDetails.bind(this);
    }

    componentDidMount() {
        this.getFixesDetails();
    }
    
    getFixesDetails() {
        var query = window.location.search.substring(1);
        var pair = query.split("=");
        var queryValue = pair[1];

        if(queryValue === undefined) {
            queryValue = cookies.get('fixesDetailsIdTechSupport');
        }
        this.state.fixesDetails = queryValue;

        const body = {
			cus_ticket_id: this.state.fixesDetails
          };
        axiosInstance.post(`getfixesDetails`, body)
		.then(res => {
		    if(res.data.status === 'success' && res.data.statusCode ==='200' )
		    {
		    	let ticket_res = res.data.fixes_details;
                let ticketDetails = ticket_res.map(ticket => { return ticket });
                this.setState({ tickets: ticketDetails });
                
                if(res.data.fixes_details[0].update_status == '1') {
                    let updatedStatusClass = 'hide__container';
                    this.setState({ updatedStatusClass });
                }
                
            }
		})
         .catch(err => console.log('Login: ', err));
    }

    submitFixesApproval(tech_id, tkt_id, tkt_as_id, status) {

        confirmAlert({
            message: 'Are you sure ?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    const body = {
                        tech_user_id: tech_id,
                        cus_ticket_id: tkt_id,
                        cus_ticket_assign_id: tkt_as_id,
                        status: status,
                        reason: ''
                      };
                    axiosInstance.post(`submitFixesApproval`, body)
                    .then(res => {
                        if(res.data.status === 'success' && res.data.statusCode ==='200' )
                        {
                            this.setState({fixesRedirect: true});
                        }
                    })
                     .catch(err => console.log('Login: ', err));
                }
              },
              {
                label: 'No'
              }
            ]
          });

        
    }
    
    render() {

        if (this.state.fixesRedirect) {
            return <Redirect to="/Fixes"/>;
        }
    return (
        
    <div>
        {this.state.tickets.map(FixesDetails => (
        <div>
        <div>
            <div className="text-component list-tkt">
                <div className="left-sd-tkt">
                    {/* <p>TKT000{FixesDetails.cus_ticket_id} - {FixesDetails.cus_ticket_title} </p> */}
                    <p>{FixesDetails.cus_ticket_title} </p>
                    <div className="ldetails list_cases">
                        <Link className="max-w20 cont-comp">{FixesDetails.cus_user_name} </Link>
                        {/* <span className="time_list"> <span className="lsep cre-sep">.</span>
                            <span> <i className="fa fa-clock-o" aria-hidden="true"></i>
                                <span className="time-dte-font">15 hours ago</span>
                            </span>
                        </span> */}
                    </div>
                </div>
            </div>
        </div>

        <div className="ticket__desc_status">
            <h6 className="ticket_status">Status : {FixesDetails.cus_ticket_assign_status}</h6>
        </div>

        {/* amount details */}
         {FixesDetails.cus_ticket_assign_status == 'Closed' || FixesDetails.cus_ticket_assign_status == 'closed' ? 
         <div className="ticket__desc_status">
            <h6 className="ticket_status">Amount Details : {FixesDetails.tech_ticket_amount}</h6>
        </div>
        :
        ''
        }

        {/* <div className="ticket__desc_decs"> */}
            {/* <h6><i className="material-icons desc_attch">attach_files</i> Attachments</h6> */}
        {/* </div> */}
        {/* <div className="ticket__desc_decs">
            <h6><i className="material-icons desc_histr">assignment_turned_in</i> Histoty
            </h6>
        </div> */}

       
        <div className="ticket__desc">
            <h6>Description</h6>
        </div>



        <div className="ticket__desc_decs">
        {FixesDetails.cus_ticket_desc == '' ? 
            <p className="ttl__des">No Description</p> : <p className="ttl__des"> {FixesDetails.cus_ticket_desc} </p>
        }
        </div>

        
        
        </div>
                
        ))}
    </div>

  )
}
}

export default FixesDetails