import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import { axiosInstance } from '../../Services/PostData';
import './SideDrawer.css'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const sideDrawer = props => {

  const baseUrl = window.$baseUrl;

    const fixerMenu = () => { 
      document.getElementById("fixerleftnav").classList.toggle("show");
    }
    
    const fixConnectionMenu = () => { 
      document.getElementById("fixconnectleftnav").classList.toggle("show");
    }
    
    const paymentMenu = () => { 
      document.getElementById("paymentleftnav").classList.toggle("show");
    }

    let drawerClasses = 'side-drawer'
    if (props.show) {
      drawerClasses = 'side-drawer open'
    }

    
    // drawerUserDetails();
    // function drawerUserDetails() {

    //   let queryValue = cookies.get('techUserId');
    //   const body = { user_id: queryValue };
    //   axiosInstance.post(`getUserDrawerDetails`, body)
    //   .then(res => {
    //   if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
    //   {
    //       let username = res.data.username;
    //       let userEmail = res.data.userEmail;

    //       document.getElementById("username").innerHTML = username;
    //       document.getElementById("useremail").innerHTML = userEmail;
    //   }
    //   })
    //   .catch(err => console.log('Login: ', err));

    // }

    const logoutFucntion = () => { 

      const body = { user_id: cookies.get('techUserId') };
      axiosInstance.post(`logout`, body)
      .then(res => {
      if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
      {
        cookies.remove('techUserId');
        window.location.reload(false)
      }
      })
      .catch(err => console.log(err));
    }


    return (
      <nav className={drawerClasses}>
        <ul>
            <li>
              <div className="user-view">
                  <div className="background">
                      <img src="assets/images/drawer-menu/office1.jpg" alt="" />
                  </div>
                  {/* <Link> <img className="circle" src="assets/images/drawer-menu/yuna.jpg" alt="" /> </Link> */}
                  <Link> <img className="circle" src={ baseUrl + "uploads/avatar/" + cookies.get('techFixerUserAvatar')} alt="" /> </Link>
                  <p className="white-text">Tech support</p>
                  <Link> <span className="white-text name" id="username">{cookies.get('techFixerUserName')}</span></Link>
                  <Link> <span className="white-text email" id="useremail">{cookies.get('techFixerUserEmail')}</span> </Link>
              </div>
            </li>
            <li className="nv__menu"><Link to="/Profile">
              {/* <i className="material-icons">person</i> */}
              {/* <img src="assets/icons/menu-icons/man.png" className="icon-drw" /> */}
              {/* <img src="assets/icons/menu-icons/images/profile-1.png" className="icon-drw" /> */}
              <img src="assets/icons/menu-icons-1/user.png" className="icon-drw" />
            Profile</Link></li>

            {/* <li className="nv__menu"><Link to="Notification">
              <img src="assets/icons/menu-icons-1/notification.png" className="icon-drw" />
              Notification</Link>
            </li> */}
            
            <li className="nv__menu" data-toggle="collapse" href="#fixerleftnav" aria-expanded="true" onClick={fixerMenu}>
              <a> 
                {/* <i className="material-icons cus_side__tkt">confirmation_numbers</i> */}
                {/* <img src="assets/icons/menu-icons/ticket.png" className="icon-drw" /> */}
                {/* <img src="assets/icons/menu-icons/images/ticket-1.png" className="icon-drw" /> */}
                <img src="assets/icons/menu-icons-1/fixes.png" className="icon-drw" />
              Fixes 
              <span className="pull-right-count"><i className="fa fa-chevron-down custom-icon"></i></span></a>
            </li>
            <div id="fixerleftnav" className='collapse'>
                <li className="nv__menu nv_submenu"><Link to="/FixesActive"><i className="material-icons"></i>Activefix</Link></li>
                <li className="nv__menu nv_submenu"><Link to="/FixesOpen"><i className="material-icons"></i>Openfixes</Link></li>
                <li className="nv__menu nv_submenu"><Link to="/Fixes"><i className="material-icons"></i>FixHistory</Link></li>
            </div>

            {/* <li className="nv__menu" data-toggle="collapse" href="#fixconnectleftnav" aria-expanded="true" onClick={fixConnectionMenu}>
              <a href="javascript:void(0);"> 
              <img src="assets/icons/menu-icons-1/fix-connection.png" className="icon-drw" />
              FixConnections
              <span className="pull-right-count"><i className="fa fa-chevron-down custom-icon"></i></span>
              </a>
            </li> */}

            {/* <div id="fixconnectleftnav" class="collapse">
                <li class="nv__menu nv_submenu"><Link to="/FixesActive"><i class="material-icons"></i>Active</Link></li>
                <li class="nv__menu nv_submenu"><Link to="/FixesAborted"><i class="material-icons"></i>Aborted</Link></li>
                <li class="nv__menu nv_submenu"><Link to="/FixesClosed"><i class="material-icons"></i>Closed</Link></li>
            </div> */}
            
            {/* <li className="nv__menu" data-toggle="collapse" href="#fixerleftnav" aria-expanded="true" onClick={paymentMenu}>
              <a> 
                <img src="assets/icons/menu-icons-1/amount.png" className="icon-drw" />
              Payment 
              <span className="pull-right-count"><i className="fa fa-chevron-down custom-icon"></i></span></a>
            </li> */}

            {/* <div id="paymentleftnav" class="collapse">
                <li class="nv__menu nv_submenu"><Link to="/PaymentCard"><i class="material-icons"></i>Card / G-Pay</Link></li>
                <li class="nv__menu nv_submenu"><Link to="/PaymentBalance"><i class="material-icons"></i>Balance</Link></li>
            </div> */}

            {/* <li className="nv__menu"><Link to="/Contact">
              <img src="assets/icons/menu-icons-1/contact-us.png" className="icon-drw" />
            Contact us</Link></li>

            <li className="nv__menu"><Link to="/AboutUs">
              <img src="assets/icons/menu-icons-1/contact.png" className="icon-drw" />
            About us</Link></li> */}

            {/* <li className="nv__menu"><Link to="/Faq">
              <img src="assets/icons/menu-icons-1/contact.png" className="icon-drw" />
            FAQ</Link></li> */}

            {/* <li className="nv__menu"><Link to="/Feedback">
              <img src="assets/icons/menu-icons-1/feedback.png" className="icon-drw" />
            Feedback</Link></li> */}

            <li className="nv__menu"><Link to="/PrivacyPolicy">
              <img src="assets/icons/menu-icons-1/legal.png" className="icon-drw" />
            Legal Information</Link></li>
                
            <li className="nv__menu"><a href="https://api.touchtofix.com/demo/sanyaa-api/VNC_SETUP/TouchtoFix_EF.msi">
              <img src="assets/icons/menu-icons-1/legal.png" className="icon-drw" />
            Download Fixer Connect</a></li>

                
            

            <li className="nv__menu" onClick={logoutFucntion}><Link>
            {/* <i className="material-icons">policy</i> */}
            {/* <img src="assets/icons/menu-icons/exit.png" className="icon-drw" /> */}
            {/* <img src="assets/icons/menu-icons/images/feedback.png" className="icon-drw" /> */}
            <img src="assets/icons/menu-icons-1/logout.png" className="icon-drw" />
            Logout</Link></li>
        </ul>
      </nav>
  )
}
  // }
export default sideDrawer