import React from 'react'
import './TS_details.css'

const TS_details = props => (
    <div className="profile-block">
    <div className="panel">
        <div className="user-heading text-center"> 
        <a href="#"><img src="assets/images/dummy.jpg" alt="" /></a>
        <h1>Elango Raj</h1>
        </div>

        <div className="ticket__desc_decs">
            <h6 className="descp">I have tried to send emails to my business email application and have had them returned to me and my pc goes to shut down. need immediate action. </h6>
        </div>
        
        <ul className="nav nav-pills nav-stacked">
            <li><a href="#"><i class="fa fa-envelope" aria-hidden="true"></i>
                <span className="head_label">Sanya Id&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <span  className="head_label">:</span> 5342 </a>
            </li>
            <li><a href="#"><i class="fa fa-history" aria-hidden="true"></i><span className="head_label">Experience&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <span className="head_label">:</span> 2 Years </a></li>
            <li><a href="#"><i class="fa fa-money" aria-hidden="true"></i>
                <span className="head_label">Rate per hours&nbsp; </span> <span className="head_label">:</span> $ 0.00</a>
            </li>
        </ul>
        
        
        <ul class="nav nav-pills nav-stacked">
            <li><a href="#" clasName="stacked_lst_itm">Certifications <span class="material-icons nextt">navigate_next</span></a></li>
        </ul>
    </div>
</div>

)

export default TS_details