import React, {Component} from 'react'
import './Legal.css'

class AboutUsComp extends Component  {

render() {
    return (
        <div className="container-fluid custom-screen-middle"> 
            <div style={{textAlign: 'center'}}>
                <img src="assets/images/logo-ts.png" width="50%" />
            </div>
            <p>The TSAS platform facilitates IT Infrastructure &amp; Technology Support in a seamless user friendly workflows to Clients, by chosen Tech Experts by means of combined services of Mobile App and Web Interfaces. </p>
            <p>The USP of the platform is that the End Users will be provided Industry standard services through secured, private Remote sessions. 
            The TSAS platform and services are compliant to applicable Industry standards.</p>
        </div>
    )
}
}

export default AboutUsComp