import React, {Component} from 'react'
// import './AllFixes.css'

class PrivacyPolicyComp extends Component  {

render() {
    return (
        <div className="container-fluid">
            <div className="">
            <div class="col-md-12">
     <h5 class="font-18 font-bold mt-3">ONLINE PRIVACY POLICY AGREEMENT</h5>
     <p class="custom-legal-p-tag">TSAS, is committed to keeping any and all personal information collected of those individuals that visit our website and make use of our online facilities and services accurate, confidential, secure and private. Our privacy policy has been designed and created to ensure those affiliated with TSAS. of our commitment and realization of our obligation not only to meet but to exceed most existing privacy standards.</p>
     <p class="custom-legal-p-tag">TSAS. may deem it necessary to follow websites and/or pages that our users may frequent in an effort to gleam what types of services and/or products may be the most popular to customers or the general public.</p>
     <h6 class="custom-legal-sub-title">Unsubscribe or Opt-Out</h6>
     <p class="custom-legal-p-tag">All users and/or visitors to our website have the option to discontinue receiving communication from us and/or reserve the right to discontinue receiving communications by way of email or newsletters. To discontinue or unsubscribe to our website please send an email that you wish to unsubscribe to info@spottechTSAS. If you wish to unsubscribe or opt-out from any third party websites, you must go to that specific website to unsubscribe and/or opt-out.</p>
     <h6 class="custom-legal-sub-title">Links to Other Web Sites</h6>
     <p class="custom-legal-p-tag">Our website does contain links to affiliate and other websites. TSAS. does not claim nor accept responsibility for any privacy policies, practices and/or procedures of other such websites. Therefore, we encourage all users and visitors to be aware when they leave our website and to read the privacy statements of each and every website that collects personally identifiable information. The aforementioned Privacy Policy Agreement applies only and solely to the information collected by our website.</p>
     <h6 class="custom-legal-sub-title">Security</h6>
     <p class="custom-legal-p-tag">TSAS. shall endeavor and shall take every precaution to maintain adequate physical, procedural and technical security with respect to our offices and information storage facilities so as to prevent any loss, misuse, unauthorized access, disclosure or modification of the user's personal information under our control.</p>
     <h6 class="custom-legal-sub-title">Changes to Privacy Policy Agreement</h6>
     <p class="custom-legal-p-tag">TSAS. reserves the right to update and/or change the terms of our privacy policy, and as such we will post those change to our website homepage at www.spottechTSAS, so that our users and/or visitors are always aware of the type of information we collect, how it will be used, and under what circumstances, if any, we may disclose such information. If at any point in time </p>
     <p class="custom-legal-p-tag">TSAS. decides to make use of any personally identifiable information on file, in a manner vastly different from that which was stated when this information was initially collected, the user or users shall be promptly notified by email. Users at that time shall have the option as to whether or not to permit the use of their information in this separate manner.</p>
     <h6 class="custom-legal-sub-title">Acceptance of Terms</h6>
     <p class="custom-legal-p-tag">Through the use of this website, you are hereby accepting the terms and conditions stipulated within the aforementioned Privacy Policy Agreement. If you are not in agreement with our terms and conditions, then you should refrain from further use of our sites. In addition, your continued use of our website following the posting of any updates or changes to our terms and conditions shall mean that you are in agreement and acceptance of such changes.</p>
     <h6 class="custom-legal-sub-title pad-l-5">1. Introduction</h6>
     <p class="custom-legal-p-tag pad-l-5">This Privacy Policy (“<b>Privacy Policy</b>“) describes how TSAS will gather, use, and maintain your Personal Information on the TSAS Platform. It will also explain your legal rights with respect to that information.<br />
         By using the TSAS Platform, you confirm that you have read and understand this Privacy Policy, and our Terms of Service (together referred to herein as the “<b>Agreement</b>”). The Agreement governs the use of the TSAS Platform. TSAS will collect, use, and maintain information consistent with the Agreement.
     </p>
     <p class="custom-legal-p-tag pad-l-5">If you are a California resident or data subject in Europe, please see the “Additional Disclosures for California Residents” and “Additional Disclosures for Data Subjects in the European Economic Area (EEA) and Switzerland” sections, respectively. If you have any questions or wish to exercise your rights and choices, please contact us as set out in the “Contact Us” section below.General Terms</p>
     <p class="custom-legal-p-tag pad-l-10">1. TSAS may be referred to as “<b>TSAS.com</b>,” “<b>we,</b>” “<b>our,</b>” or “<b>us.</b>”</p>
     <h6 class="custom-legal-sub-title pad-l-5">2. Information Collection</h6>
     <h6 class="custom-legal-sub-ul-title pad-l-10">1. Information you provide TSAS</h6>
     <p class="custom-legal-p-tag pad-l-10">TSAS collects certain personally identifiable information about you, including information that is reasonably capable of being associated with you (“Information”) through your use of the Sites, our Services and the TSAS Platform. Examples of Information that TSAS collects includes but are not limited to:</p>
     <p class="custom-legal-p-tag pad-l-10"><b>Contact Information.</b>This may include </p>
     <p class="custom-legal-p-tag pad-l-10"><b>Information from system</b></p>
     <p class="custom-legal-p-tag pad-l-12">1.Device Detection</p>
     <p class="custom-legal-p-tag pad-l-12">2.Browser Information</p>
     <p class="custom-legal-p-tag pad-l-12">3.operating system type</p>
     <p class="custom-legal-p-tag pad-l-12">4.timezone details</p>
     <p class="custom-legal-p-tag pad-l-12">5.ip address</p>
     <p class="custom-legal-p-tag pad-l-12">6.Username</p>
     <p class="custom-legal-p-tag pad-l-12">7.Pc name</p>
     <p class="custom-legal-p-tag pad-l-12">8.Password(user need to fill)</p>
     <p class="custom-legal-p-tag pad-l-12">9.Remote sharing/Screen sahring enabling</p>
     <p class="custom-legal-p-tag pad-l-10"><b>Software need to install</b></p>
     <p class="custom-legal-p-tag pad-l-12">1.RDP Wrapper if its windows</p>
     <p class="custom-legal-p-tag pad-l-12">2.Softether vpn client files</p>
     <p class="custom-legal-p-tag pad-l-10"><b>Content Information.</b> You also may choose to send TSAS Personal Information in an email or chat message containing inquiries about the TSAS Platform and Services. We use this Information in order to help us respond to your inquiry.
         We require that you furnish your Contact Information and Financial Information when you register a paid account with us in order to provide Services through the TSAS Platforms. We require you to furnish your name and e-mail when you register a free account with us. For example, if you are a Client, we collect your first and last name, email address, and your zip or postal code in order to create and administer your TSAS account. Further, if you are a Client who purchases one of our Services, we collect your first and last name, e-mail address, zip or postal code, address and your Financial Information to facilitate your payment. We also collect additional information in order to facilitate your technical support request, such as information about the technical support issue you are seeking to fix, the time, and the date. You may also register for a free account, in that instance, we collect your first name, last name, e-mail address, password and your communication preferences, if any.Information TSAS Collects Automatically
     </p>
     <h6 class="custom-legal-sub-title pad-l-5">3. Remote Control</h6>
     <p class="custom-legal-p-tag pad-l-10">As part of some of our Services, you may participate in a technical support session with one of our trained technical support agents. During such Service session, you may have the option to grant one of our technical support agents access to your computer screen or what’s referred to as the “Remote Control” functionality – this allows our technical support agents to see your computer screen and manually operate your computer device remotely. The technical support agent must first have your permission to launch and, if applicable to your version of the Services, record your Remote Control session. You will see a screen appear on your desktop asking you to accept or decline the technical support agent’s request to remotely access and control your computer device. If you accept the request, your Remote Control session will begin. To end the Remote Control session, you may click the red X or the “End Session” button on the available toolbar. If you are using a version of our Services that does not have the record feature, then no recording of the Remote Control Session will be made. If you are using a version of our technical support services that does have the record feature enabled, a recording of all on-screen activity during your Remote Control session will stored in your account file. We do not sell or provide recordings of Remote Control sessions or related information to any third party for any reason.
         record feature, then no recording of your SeeSupport session will be made.
     </p>
     <h6 class="custom-legal-sub-title pad-l-5">4. Instant Chat</h6>
     <p class="custom-legal-p-tag pad-l-10">As part of some of our Services, you may participate in a technical support session with one of our trained technical support agents. During such Services, the Instant Chat feature allows a technical support agent to engage in a two-way chat session with you without requiring you to download any software. The technical support agent must first have permission to launch and, if applicable to your version of the Services, record an Instant Chat session. Instant Chat is a feature that is available as part of the Services offered through our App and through your computer device via our website. Instant Chat allows Users to chat with the technical support agents about their technical issues, view their detailed session log, allow or deny a technical support agent the right to perform certain actions (e.g., SeeSupport, Remote Control) and end a session by clicking the “X” at the top left of the chat screen or the “End Session” button. If you are using a version of our Services that has the record feature enabled, a record of your Instant Chat messages is made and stored in your account file. We do not sell or provide stored Instant Chat sessions data or related information to any third party for any reason.TSAS’s Use of Information
     </p>
     <p class="custom-legal-p-tag pad-l-5"><b>5.</b> We collect and use information for business and commercial purposes in accordance with the practice described in this Privacy Policy. Our business purposes for collecting and using information include:
     </p>
     <p class="custom-legal-p-tag pad-l-10">1. To operate and make available the TSAS Platform. We use your data to enable payment for Services, in order to fulfill our contracts with you;</p>
     <p class="custom-legal-p-tag pad-l-10">2. For billing and fraud prevention, on the basis of our legitimate interests in ensuring a safe and secure environment in which Clients can use our Services and Platform, and in order to comply with our legal obligations;</p>
     <p class="custom-legal-p-tag pad-l-10">3. To analyze TSAS Platform usage as necessary for our legitimate interest in improving the TSAS Platform to grow our business;</p>
     <p class="custom-legal-p-tag pad-l-10">4. To contact you and deliver (via email, SMS, push notifications, or otherwise) transactional information, administrative notices, marketing notifications, offers and communications relevant to your use of the TSAS Platform and Services, with your consent when required under applicable law, as necessary for our legitimate interests in proper communication and engagement with our Users and in promoting our business;</p>
     <p class="custom-legal-p-tag pad-l-10">5. To provide you with the best customer support possible so that we can our obligations to our Users;</p>
     <p class="custom-legal-p-tag pad-l-10">6. To better understand the nature and cause of past and present technical support issues on User devices;</p>
     <p class="custom-legal-p-tag pad-l-10">7. For internal market research for our legitimate interest in improving the TSAS Platform and Services to grow our business;</p>
     <p class="custom-legal-p-tag pad-l-10">8. For troubleshooting problems for our legitimate interests in ensuring a safe and secure environment in which Users can receive the Services they opt to use;</p>
     <p class="custom-legal-p-tag pad-l-10">9. To assist Users in the resolution of complaints and disputes, as necessary for our legitimate interests in facilitating good customer service to all Users;</p>
     <p class="custom-legal-p-tag pad-l-10">10. To enforce our Terms of Service and our legitimate interests in ensuring our Agreement is complied with; and</p>
     <p class="custom-legal-p-tag pad-l-10">11. As otherwise set forth in the Agreement.</p>
     <p class="custom-legal-p-tag pad-l-5"><b>6. Interest-Based Advertising</b></p>
     <p class="custom-legal-p-tag pad-l-10">Ads are a standard part of user experience on the Internet, and TSAS believes that targeted advertising enhances this experience. TSAS and affiliate third parties may use cookies and other technologies to place ads where they believe interested Users will see them.</p>
     <p class="custom-legal-p-tag pad-l-10">We only share the Information we collect about you as described in this Privacy Policy or as described at the time of collection or sharing, including as follows:
         <b>Third Party Agents</b>. We share information, including Identity Information, with entities that process information on our behalf for our business purposes. Third Party Agents assist us with services such as payment processing, data analytics, marketing and advertising, website hosting, fraud prevention and detection, communication services, and technical support. We contractually prohibit our Third Party Agents from retaining, using, or disclosing information about you for any purposes other than performing the services for us, although we may permit them to use information that does not identify you (including information that has been aggregated or de-identified) for any purpose except as prohibited by applicable law.
         To operate the TSAS Platform, including processing your transactions, we may share your information with our agents, representatives, vendors and service providers (“<b>Third Party Agents</b>”) so they can provide us with support services as follows:
     </p>
     <p class="custom-legal-p-tag pad-l-12">1. Email origination;</p>
     <p class="custom-legal-p-tag pad-l-12">2. Identity checks (currently carried out by our providers);</p>
     <p class="custom-legal-p-tag pad-l-12">3. Receipt, invoice, or support services;</p>
     <p class="custom-legal-p-tag pad-l-12">4. Customer &nbsp; relationship &nbsp; management services;</p>
     <p class="custom-legal-p-tag pad-l-12">5. Financial transaction fulfillment (in which we are currentlysupported by Recurly, who processes your information in accordance with its own privacy policy at https://recurly.com/legal/privacy) and related chargeback and collection services; and</p>
     <p class="custom-legal-p-tag pad-l-12">6. To otherwise help us provide the TSAS Platform.</p>
     <p class="custom-legal-p-tag pad-l-5"><b>7. Legal Obligations</b></p>
     <p class="custom-legal-p-tag pad-l-10">TSAS and our Third Party Agents may disclose information or User Generated Content, including location data or communication data, to a third party if required or permitted to do so by law or pursuant to a court order, warrant or subpoena. TSAS reserves the right to disclose Personal Information from both private and public areas of the Site(s), Service(s), and the overall TSAS Platform(s) in the absence of a court order, warrant or subpoena, to the extent permitted by applicable law, if we are given reason to believe, in our sole discretion, that someone is causing injury to or interfering with the rights of Users, the general public, or TSAS or its partners, parents or affiliates.
         You may opt-out of receiving promotional communications from us and our Partners, remove your information from our database, choose to not receive future promotional communications related to the Sites, Services and/or overall TSAS Platform, or cancel your account by logging on to the Site(s) by contacting us.
     </p>
     <p class="custom-legal-p-tag pad-l-10"><b>Corrections to Profile.</b> You have the right to access, update, and correct inaccuracies in your TSAS Profile at any time by logging in and clicking on the “Your Account”. There, you can view, update, and correct your Account information. Our databases automatically update any information you edit in your Profile under “Your Account”.</p>
     <p class="custom-legal-p-tag pad-l-10"><b>Cookies and Pixels.</b> Most browsers accept cookies by default. You can instruct your browser, by changing its settings, to decline or delete cookies. If you use multiple browsers on your device, you will need to instruct each browser separately. Your ability to limit cookies is subject to your browser settings and limitations.</p>
     <p class="custom-legal-p-tag pad-l-10"><b>Remote Control, Instant Chat.</b> You have the right to access, request a copy of and/or request us to delete all records and/or recordings of, if any, of your Remote Control, SeeSupport or Instant Chat at any time. Users of the Services that contain the record feature can send an e-mail to privacy@TSAS to exercise these rights.</p>
     <p class="custom-legal-p-tag pad-l-10"><b>App and Location Technologies. </b> You can stop all collection of information via an app by uninstalling the app. You can also reset your device Id at any time through your device settings, which is designed to allow you to limit the use of information collected about you. If you do not want us to use your location anymore for the purposes set forth above, you should turn off the location services for the mobile application located in your device’s account settings, your mobile phone settings, and/or within the mobile application.</p>
     <p class="custom-legal-p-tag pad-l-5"><b>8.</b> We retain personal data for as long as you are a User in order to meet our obligations to you, and for such longer period as may be in our legitimate interests and to comply with our legal obligations</p>
     <p class="custom-legal-p-tag pad-l-5"><b>9.</b>9. Your TSAS account is password-protected so that only you and authorized TSAS staff have access to your account information. In order to maintain this protection, do not give your password to anyone. Also, if you share a computer, you should sign out of your TSAS account and close the browser window before someone else logs on. This will help protect your information entered on public terminals from disclosure to third parties.
         TSAS implements and maintains reasonable administrative, physical, and technical security safeguards to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. TSAS has staff dedicated to maintaining this Privacy Policy and other privacy initiatives, periodically reviewing security, and making sure that every TSAS employee is aware of our security practices. Nevertheless, transmission via the internet is not completely secure and we cannot guarantee the security of information about you
     </p>
     <p class="custom-legal-p-tag pad-l-5">Notification of Changes</p>
     <p class="custom-legal-p-tag pad-l-5"><b>10.</b> TSAS’s Privacy Policy is periodically reviewed and enhanced as necessary, in any event, our Privacy Policy is reviewed at a minimum of two (2) times in each twelve (12) month period. This Privacy Policy might change as TSAS updates and expands the TSAS Platform. TSAS will endeavor to notify you of any material changes by email. TSAS also encourages you to review this Privacy Policy periodically.Children’s Privacy</p>
     <p class="custom-legal-p-tag pad-l-5"><b>11.</b> This service is intended for a general audience, and is not directed at children under 18 years of age. In certain jurisdictions, this minimum age may be higher. Please check the Jurisdiction-specific Provisions below for more information.
         We do not knowingly gather personal information (as defined by the U.S. Children’s Privacy Protection Act, or “COPPA”) in a manner not permitted by COPPA. If you are a parent or guardian and you believe that we have collected information from your child in a manner not permitted by law, please let us know by contacting us here. We will remove the data.<br />
         We do not knowingly collect or “sell” the Personal Information of minors under 18 years old.<br />
         <b>Security of Collected Information.</b> While TSAS will use commercially reasonable efforts to ensure the security of all Financial Information and Personal Information, we expressly disclaim any liability for any unauthorized access to or use of our secure servers and/or any and all Personal Information and/or Financial Information stored therein, and you agree to hold TSAS harmless for any damages that may result therefrom. If you have any For more information on what we collect, including the sources we receive information from, review the Information Collection section of the Privacy Policy. We collect and use these categories of Personal Information for the business purposes described in the Use of Information section, including to provide and manage our Services.<br />
         TSAS does not generally sell information as the term “sell” is traditionally understood. However, to the extent “sale” under the CCPA is interpreted to include advertising technology activities such as those disclosed in the TSAS Use of Information section as a “sale,” we will comply with applicable law as to such activity.
     </p>
     <p class="custom-legal-p-tag pad-l-5">1. TSAS discloses the following categories of information for commercial purposes:</p>
     <p class="custom-legal-p-tag pad-l-10">■ Commercial Information;</p>
     <p class="custom-legal-p-tag pad-l-10">■ Demographic Data;</p>
     <p class="custom-legal-p-tag pad-l-10">■ Location Data;</p>
     <p class="custom-legal-p-tag pad-l-10">■ Identifiers;</p>
     <p class="custom-legal-p-tag pad-l-10">■ Inferences; and</p>
     <p class="custom-legal-p-tag pad-l-10">■ Internet activity.</p>
     <p class="custom-legal-p-tag pad-l-5">2. We use and partner with different types of entities to assist with our daily operations and manage our Service. Please review the Information Sharing section for more detail about the parties we share information with.
         Right to Know and Delete. If you are a California resident, you have the right to know certain information about our data practices in the preceding twelve (12) months. In particular, you have the right to request the following from us:
     </p>
     <p class="custom-legal-p-tag pad-l-10">■ The categories of Personal Information we have collected about you;</p>
     <p class="custom-legal-p-tag pad-l-10">■ The categories of sources from which the Personal Information was collected;</p>
     <p class="custom-legal-p-tag pad-l-10">■ The categories of Personal Information about you we disclosed for a business purpose or sold;</p>
     <p class="custom-legal-p-tag pad-l-10">■ The categories of third parties to whom the Personal Information was disclosed for a business person or sold;</p>
     <p class="custom-legal-p-tag pad-l-10">■ The business or commercial purpose for collecting or selling the Personal Information; and</p>
     <p class="custom-legal-p-tag pad-l-10">■ The specific pieces of Personal Information we have collected about you.</p>
     <p class="custom-legal-p-tag pad-l-5">3. In addition, you have the right to delete the Personal Information we have collected from you. However, this is not an absolute right and TSAS may have legal grounds for keeping such data.</p>
     <p class="custom-legal-p-tag"><b>How to Contact Us</b></p>
     <p class="custom-legal-p-tag">If you have any questions or concerns regarding the Privacy Policy Agreement related to our website, please feel free to contact us at the following email, telephone number or mailing address.</p>
     <p class="custom-legal-p-tag">Email: info@sanyaa.com</p>
     <p class="custom-legal-p-tag">Telephone Number: </p>
     <p class="custom-legal-p-tag">Mailing Address: TSAS.</p>
 </div>

            </div>
        </div>
    )
}
}

export default PrivacyPolicyComp