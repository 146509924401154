import React from 'react'
import {Link} from 'react-router-dom'
// import ChatDetailsComponent from './ChatDetailsComp'


const RenderMedia = props => {

    return(
        <div>
            <video width="320" height="240" controls autoplay>
                <source src={props.mediaFileUrl} type="video/mp4" />
                <source src={props.mediaFileUrl} type="video/ogg" />
            </video>  
        </div>
    )
}
 
export default RenderMedia