import React, {Component} from 'react';
// import FeedbackComp from '../component/Feedback/FeedbackComp'
import CustomToolbar from '../component/Toolbar/CustomToolbar'

class FeedBack extends Component {

    state = { sideDrawerOpen: false }

      drawerToggleClickHandler = () => {
        this.setState(prevState => {
          return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
      }

      backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
      }
      

    render() {
        
        return (
            <div style={{height: '100%'}}>

                <CustomToolbar drawerClickHandler={this.drawerToggleClickHandler} 
                         propsName = 'notificationDetails' 
                         headTitle = 'FeedBack'
                         addLink = ''
                         backLink = '/Dashboard' />


                <main>
                <div className="top__align__tool_nv"></div>
                  {/* <FeedbackComp /> */}


                  <div style={{margin: '15px', backgroundColor: '#ffffff'}}>
                      <div class="text-center w-100 custom-screen-middle">
                          <h5 class="empty-ticket-title">No FeedBack</h5>
                      </div>
                  </div>

                </main>

            </div>
        )
    }

}

export default FeedBack
