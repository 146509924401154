import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './index.css';
// import Splash from './Pages/Splash';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import Otp from './Pages/Otp';
import ForgotPassword from './Pages/ForgotPassword';
import Dashboard from './Pages/Dashboard';
import Fixes from './Pages/Fixes';
import FixesDetail from './Pages/FixesDetail';
import TechSupportList from './Pages/TechSupportList';
import TechSupportDetails from './Pages/TechSupportDetails';
import Profile from './Pages/Profile';
import ProfileSignup from './Pages/ProfileSignup';
import Certificate from './Pages/Certificate';
import AddCertificate from './Pages/AddCertificate';
import skills from './Pages/skills';
import ProfileEdit from './Pages/ProfileEdit';
import Authenticator from './Pages/Authenticator';
import FixesNotification from './Pages/FixesNotification';
// import ChatDetails from './Pages/ChatDetails';
import ChatDetails from './Pages/ChatDetails'
import ChatDashboard from './Pages/ChatDashboard'

import AboutUs from './Pages/AboutUs';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Notification from './Pages/Notification';
import FixesActive from './Pages/FixesActive';
import FixesAborted from './Pages/FixesAborted';
import FixesClosed from './Pages/FixesClosed';
import FixesOpen from './Pages/FixesOpen';
import PaymentCard from './Pages/PaymentCard';
import PaymentBalance from './Pages/PaymentBalance';
import Contact from './Pages/Contact';
import Faq from './Pages/Faq';
import Feedback from './Pages/Feedback';
import MyDevice from './Pages/MyDevice';

import SkillAdd from './Pages/SkillAdd';
import Appversion from './Pages/Appversion';

import TechProof from './Pages/TechProof';
import AddProof from './Pages/AddProof';

function App() {
  
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/Signup" component={Signup} />
        <Route exact path="/ForgotPassword" component={ForgotPassword} />
        <Route exact path="/Otp" component={Otp} />
        <Route exact path="/Dashboard" component={Dashboard} />
        <Route exact path="/Fixes" component={Fixes} />
        <Route exact path="/FixesDetail" component={FixesDetail} />
        <Route exact path="/TechSupportList" component={TechSupportList} />
        <Route exact path="/TechSupportDetails" component={TechSupportDetails} />
        <Route exact path="/Profile" component={Profile} />
        <Route exact path="/ProfileSignup" component={ProfileSignup} />
        <Route exact path="/Certificate" component={Certificate} />
        <Route exact path="/AddCertificate" component={AddCertificate} />
        <Route exact path="/skills" component={skills} />
        <Route exact path="/ProfileEdit" component={ProfileEdit} />
        <Route exact path="/Authenticator" component={Authenticator} />
        <Route exact path="/FixesNotification" component={FixesNotification} />
        <Route exact path="/ChatDetails" component={ChatDetails} />
        <Route exact path="/ChatDashboard" component={ChatDashboard} />

        <Route exact path="/AboutUs" component={AboutUs} />
        <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/Notification" component={Notification} />
        <Route exact path="/FixesActive" component={FixesActive} />
        <Route exact path="/FixesAborted" component={FixesAborted} />
        <Route exact path="/FixesClosed" component={FixesClosed} />
        <Route exact path="/FixesOpen" component={FixesOpen} />
        <Route exact path="/PaymentCard" component={PaymentCard} />
        <Route exact path="/PaymentBalance" component={PaymentBalance} />
        <Route exact path="/Contact" component={Contact} />
        <Route exact path="/Faq" component={Faq} />
        <Route exact path="/Feedback" component={Feedback} />
        <Route exact path="/MyDevice" component={MyDevice} />
      
        <Route exact path="/SkillAdd" component={SkillAdd} />
        <Route exact path="/Appversion" component={Appversion} />

        <Route exact path="/TechProof" component={TechProof} />
        <Route exact path="/AddProof" component={AddProof} />
      
      </Switch>
    </BrowserRouter>
  );
}

export default App;
