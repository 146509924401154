import React, {Component} from 'react'
import { Link, Redirect } from 'react-router-dom'
import './AllFixes.css'
import { axiosInstance } from '../../Services/PostData';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class FixesClosedComp extends Component  {

    constructor(){
		super();
		this.state = {
            tickets: [],
            notTicketFound: 'hide__container'
        };
		this.getTicketDetails = this.getTicketDetails.bind(this);
    }

    componentDidMount() { 
        if(cookies.get('techUserId') == undefined) {
            this.setState({loginRdirect: true})
         }
         else {
             this.getTicketDetails(); 
         }  
    }

	getTicketDetails()
	{
		const body = {
            user_id: cookies.get('techUserId'),
            type: "closed"
		  };

		axiosInstance.post(`getfixes`, body)
		.then(res => {
		    if(res.data.status === 'success' && res.data.statusCode ==='200' )
		    {
                let response = res.data.fixes_details;
                if(response.length ==0) {
                    this.setState({ notTicketFound: '' });
                }
                
                let resposeValue = response.map(ticket => { return ticket });
				this.setState({ tickets: resposeValue });
            }
            else {
                this.setState({ notTicketFound: '' });
            }
		})
		 .catch(err => console.log('Login: ', err));
    }
    
    setFixesDetailsId(id) {
        cookies.set('fixesDetailsIdTechSupport', id)
    }

render() {
    if(this.state.loginRdirect) {
        return <Redirect to="/" />;
    }
    return (
        
    <div class="fixes__details">
        {/* <div class="fixes__head"><span><i class="fa fa-ticket" aria-hidden="true"></i> </span> Fixes</div> */}
        <div class="fixes__head"></div>
        <div class="fixes__content">

            <div className={this.state.notTicketFound} style={{margin: '15px', backgroundColor: '#ffffff'}}>
                <div class="text-center w-100 custom-screen-middle">
                    <img src="assets/images/empty-ticket.png" class="empty-ticket-img" />
                    <h5 class="empty-ticket-title">No Active Fixes</h5>
                </div>
            </div>

            {this.state.tickets.map(ticket_details => (

                <div className="fixes__content_sng" key={ticket_details.cus_ticket_id}>
                    <div className="fixes__content_sng_hd">
                        {/* <p className="fixes__content_sng_hd_ttl"> {ticket_details.cus_ticket_title} </p> */}

                        <p className="trk__id_nmbr">
                            <img src="assets/icons/menu-icons/images/ticket-1.png" class="cs_ficn_cls" />
                            Tracking number : TKT000{ticket_details.cus_ticket_id}
                        </p>
                        

                        <p className="spacee"></p>
                        <p className="fixes__content_sng_hd_dte">
                            <img src="assets/icons/menu-icons/images/calendar.png" class="cs_ficn_cls" />
                            {ticket_details.cus_ticket_created_date} 
                        </p>
                    </div>

                    {/* <p className="trk__id_nmbr">Tracking number : TKT000{ticket_details.cus_ticket_id}</p> */}
                    <div className="ldetails list_cases">
                        <Link className="max-w20 cont-comp">
                            <img src="assets/icons/menu-icons/images/profile-1.png" class="cs_ficn_cls" />
                            {ticket_details.cus_user_name}
                        </Link>
                        {/* <span className="time_list"> <span className="lsep cre-sep">.</span>
                            <span> <i className="fa fa-clock-o" aria-hidden="true"></i> 
                                <span className="time-dte-font"> 15 hours ago</span>
                            </span>
                        </span> */}
                    </div>
                    <div className="fixes__content_ftr">
                        <p className="sts text-success">{ticket_details.cus_ticket_assign_status}</p>
                        <p className="spacee"></p>
                        {/* <Link to="/FixesDetail">Show details</Link> */} 
                        {/* <Link to={{ pathname: '/FixesDetail', search: 'ticketDetails=' + ticket_details.cus_ticket_id  }} >Show details</Link> */}
                        <Link onClick={() => this.setFixesDetailsId(ticket_details.cus_ticket_id) } 
                            to={{ pathname: '/FixesDetail', search: 'fixesDetail='+ticket_details.cus_ticket_id  }} >Show details</Link>
                        {/* <Link onClick={() => this.redirectFixesDetails(ticket_details.cus_ticket_id )} >Show details</Link> */}
                    </div>
                </div>

                ))}
        </div>

        {this.state.showSpinner ? 
            <div className="lds-dual-ring custom-screen-middle"></div>
            :
            null
        }
    </div>


    )
}
}

export default FixesClosedComp