import React, {Component} from 'react'
import { Link } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import './CompAddCertificate.css'
import swal from 'sweetalert2';
//zoom image
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Profilee extends Component {

    constructor() {
        super();
        this.state = {
            certificate_fixers: [],
            noCertificateClass: false
        };
        this.getProfile = this.getProfile.bind(this)
        this.deleteFunction = this.deleteFunction.bind(this)
      }
 
      componentDidMount() {
        const baseUrl = window.$baseUrl;
        this.setState({baseUrl})
        this.getProfile();
      }
      
      getProfile() {
        const body = {
			tech_user_id: cookies.get('techUserId')
        };
            
        axiosInstance.post(`getFixerProof`, body)
        .then(res => {
           if(res.data.status == 'success' && res.data.statusCode =='200' )
           {	
                if(res.data.proof_details.length == 0) {
                    this.setState({noCertificateClass: true})
                }
                let certificate_details = res.data.proof_details;
                let CertificateDetails = certificate_details.map(certificate => {
                    return certificate
                });
                this.setState({
                    certificate_fixers: CertificateDetails
                });
           }
           else {
            if(res.data.proof_details.length == 0) {
                this.setState({noCertificateClass: true})
            }
           }
        })
        .catch(err => console.log('Signup: ', err));
      }

      deleteFunction(id) {
        swal.fire({
            text: "Are you sure delete this certificate",
            showCancelButton: true,
            confirmButtonColor: '#D90429',
            confirmButtonText: 'Yes',
            cancelButtonText: "Cancel"
        }).then((result) => {
            if (result.value) {

                 //show spinner 
                let spinner = 'lds-dual-ring custom-screen-middle';
                let overlay = 'overlay';
                this.setState({ spinner, overlay });

                const body = { fixer_proof_id: id };

                axiosInstance.post(`deleteFixerProof`, body)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    //hide spinner
                    let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                    let overlay = 'overlay hide__container';
                    this.setState({ spinner, overlay });
                    this.getProfile();
                }
                else {

                }
                
                })
                .catch(err => console.log('Login: ', err));
            }
        });
      }

    render() {
        
    return (
        // <div class="mt-3 main-profilee">
        <div class="main-profilee">
            <div class="profile-block">
                
                {
                this.state.noCertificateClass ? 
                    <div style={{ backgroundColor: '#ffffff'}}>
                        <div class="text-center w-100 custom-screen-middle">
                            <img src="assets/images/empty-ticket.png" class="empty-ticket-img" />
                            <h5 class="empty-ticket-title">No Proof </h5>
                        </div>
                    </div>
                :
                null
                }

                {this.state.certificate_fixers.map(certificates => (
                    <div class="right__menu_cr text-center m-b-5">
                        
                        <Zoom> <img src={this.state.baseUrl +`uploads/proof/` + certificates.file_name} /> </Zoom>
                        <p>{certificates.proof_name}</p>
                        <p className="text-center color-tmp-5 cursorPointer" onClick={() => this.deleteFunction(certificates.fixer_proof_id) }>
                            <i class="fa fa-trash"></i> 
                        </p>
                    </div>
				))}
            </div>
        </div>
    )
}
}
 
export default Profilee