import React, {Component} from 'react'
import { Link, Redirect } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import './CompCertificate.css'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class CompAddCertificate extends Component {

    constructor() {
        super();
        this.state = {
            certificate_fixers: [],
            noCertificateClass: 'hide__container',
            imageFile: '',
            image_file: null
        };
        this.getProfile = this.getProfile.bind(this)
        this.submitProfileEdit = this.submitProfileEdit.bind(this)
      }
 
      componentDidMount() {
        // this.getProfile();
      }
      
      getProfile() {
        const body = {
			user_id: cookies.get('techUserId')
        };
            
        axiosInstance.post(`getFixerCertificate`, body)
        .then(res => {
           if(res.data.status == 'success' && res.data.statusCode =='200' )
           {	
               if(res.data.certificate_details.length == 0)
               {
                   this.state.noCertificateClass = 'show';
               }
                let certificate_details = res.data.certificate_details;
                let CertificateDetails = certificate_details.map(certificate => {
                    return certificate
                });
                this.setState({
                    certificate_fixers: CertificateDetails
                });
           }
        })
        .catch(err => console.log('Signup: ', err));
      }

        // Image Preview Handler
        handleImagePreview = (e) => {
            let image_as_base64 = URL.createObjectURL(e.target.files[0])
            let image_as_files = e.target.files[0];
        
            this.setState({
                image_preview: image_as_base64,
                image_file: image_as_files,
            })
        }

      submitProfileEdit() {
        if (this.state.image_file !== '' && this.state.imageFile !== undefined && this.state.image_file !== null) {
           
            this.setState({imageError: ""})

            let errorMessageresponse = '';
            let formData = new FormData();
            formData.append('imageFile', this.state.image_file);
            formData.append('user_id',  cookies.get('techUserId'));

            //show spinner 
            let spinner = 'lds-dual-ring custom-screen-middle';
            let overlay = 'overlay';
            this.setState({ spinner, overlay });

            axiosInstance.post(`addFixerCertificate`, formData)
            .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                //hide spinner
                let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                let overlay = 'overlay hide__container';
                this.setState({ spinner, overlay });

                this.setState({certificateRedirect: true});
            }
            else if(res.data.statusCode ==='400' ) {
                errorMessageresponse = res.data.msg
                this.setState({ errorMessageresponse});
            }
            })
            .catch(err => console.log('Login: ', err));
        }
        else {
            this.setState({imageError: "Please Choose Certificate"})
        }

        }

    render() {

        if (this.state.certificateRedirect) {
            return <Redirect to="/Certificate"/>;
        }

    return (
        <div class="mt-3 main-profilee">

            <div className="tickmarkClass">
                <Link onClick={this.submitProfileEdit}><i class="material-icons backBtnClas">check</i></Link>
            </div>

            <div class="row" style={{width: '100%', paddingTop: '20px'}}>
                <div class="col-lg-6 col-md-6 col-4 col-xs-6 ad__tk__lft">
                    <p>Choose File</p>
                </div>
                <div class="col-lg-6 col-md-6 col-8 col-xs-6 ad__tk__rgt" style={{padding: '0'}}>
                    <input type="file" class="form-control" name='image_url' onChange={this.handleImagePreview} />
                    <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.imageError}</span>
                </div>
            </div>

            <div className={this.state.overlay}>
			    <div className={this.state.spinner}></div>
		    </div>

        </div>
    )
}
}
 
export default CompAddCertificate