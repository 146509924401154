import React, {Component} from 'react';
import ProfileToolbar from '../component/Toolbar/ProfileToolbar'
import SideDrawer from '../component/SideDrawer/SideDrawer'
import Backdrop from '../component/Backdrop/Backdrop'
import BottomNavigation from '../component/BottomNavigation/BottomNavigation'
import Profilee from '../component/Profile/Profilee'

class Profile extends Component {

    state = {
        sideDrawerOpen: false,
      }

      drawerToggleClickHandler = () => {
        this.setState(prevState => {
          return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
      }

      backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
      }
      
      componentDidMount() {
        
      }

    render() {
        let backdrop

        if (this.state.sideDrawerOpen) {
          backdrop = <Backdrop click={this.backdropClickHandler} />
        }

        const isMobile = window.innerWidth <= 500;
        return (
            <div style={{height: '100%'}}>

                <ProfileToolbar drawerClickHandler={this.drawerToggleClickHandler}
                                propsName = 'profile' 
                                headTitle = 'Profile' />
                <SideDrawer show={this.state.sideDrawerOpen} />

                {/* BackDrop */}
                {backdrop}

                {/* *********** Main content starts here *********** */}

                <main>
                <div className="top__align__tool_nv"></div>
                   <Profilee />
                   <div class="adjust-screens-bottom"></div>
                </main>

                <BottomNavigation navigationPropsName = 'profile' />
            </div>
        )
    }

}

export default Profile
