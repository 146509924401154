import React from 'react'
import { Link } from "react-router-dom";
import './BottomNavigation.css'
import { axiosInstance } from '../../Services/PostData';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const BottomNavigation = props => {

    // let queryValue = cookies.get('sessionTechChatRoomId');
    // let chatStatus = 0;

    // const postvalues = { user_id: cookies.get('techUserId') };
    // axiosInstance.post(`getChatAvailableStatus`, postvalues)
    // .then(res => {
    // if(res.data.status === 'success' && res.data.statusCode ==='200' )
    // {
    //     let chat_response = res.data.chat_details;
    //     if(chat_response.length !=0) {
    //         cookies.set('sessionTechChatRoomId', chat_response[0].chat_session) 
    //     }
        
    //     let queryValue = cookies.get('sessionTechChatRoomId');
    //     if(queryValue != undefined) {
    //         chatStatus = queryValue
    //     }
    // }
    // })
    // .catch(err => console.log(err));

    let queryValue = cookies.get('sessionTechChatRoomId');
    let chatStatus = 0;
    if(queryValue != undefined) {
        chatStatus = queryValue
    }

    
    let fixesClassName = 'left-nav__link lf';
    let homeClassName = 'left-nav__link lf';
    let deviceClassName = 'left-nav__link rt';
    let profileClassName = 'left-nav__link rt';

    let iconFixesClassName= 'material-icons left-nav__icon';
    let iconHomeClassName = 'material-icons left-nav__icon';
    let iconDeviceClassName = 'material-icons left-nav__icon';
    let iconProfileClassName = 'material-icons left-nav__icon';

    if(props.navigationPropsName == 'fixes') {
        fixesClassName = 'left-nav__link lf left-nav__link--active';
        iconFixesClassName = 'material-icons left-nav__icon left-nav__link--active';
    }
    else if(props.navigationPropsName == 'home') {
        homeClassName = 'left-nav__link lf left-nav__link--active';
        iconHomeClassName = 'material-icons left-nav__icon left-nav__link--active';
    }
    else if(props.navigationPropsName == 'profile') {
        profileClassName = 'left-nav__link rt left-nav__link--active';
        iconProfileClassName = 'material-icons left-nav__icon left-nav__link--active';
    }
    else {
        deviceClassName = 'left-nav__link rt left-nav__link--active';
        iconDeviceClassName = 'material-icons left-nav__icon left-nav__link--active';
    }

    // getChatNotification();
    // function getChatNotification() {
    //     var notifiFunc = async function() {
    //         const user = { user_id: cookies.get('techUserId') };
    //         await axiosInstance.post(`getChatNotificationCount`, user)
    //         .then(res => {
    //         if(res.data.status === 'success' && res.data.statusCode ==='200' ) {
    //             let notificationCount = res.data.count;
    //             document.getElementById("notification_count").innerHTML = notificationCount;
    //             let chatRoomId = res.data.chat_room_id;
    //             this.setState({ chatRoomId });
    //         }
    //         else if(res.data.statusCode ==='400' ) {
    //             let notificationCount = res.data.count;
    //             document.getElementById("notification_count").innerHTML = notificationCount;
    //             let chatRoomId = res.data.chat_room_id;
    //             this.setState({ chatRoomId });
    //         }
    //         })
    //         .catch(err => console.log(err));
    //     }.bind(this);
                
    //     notifiFunc();
    //     // this.timer=setInterval(notifiFunc, 3000)
    // }


    return (
    //     <div>
    //         <nav className="left-nav">
    //         <div className="left__btm_menu">
    //             <Link to="/Fixes" className={fixesClassName} >
    //                 <i className={iconFixesClassName}>receipt</i>
    //                 <span className="left-nav__text">My Fixes</span>
    //             </Link>
    //             <Link to="/Dashboard" className={homeClassName}>
    //                 <i className={iconHomeClassName}>home</i>
    //                 <span className="left-nav__text">Home</span>
    //             </Link>
    //         </div>
    //         <div className="right__btm_menu">
    //             <Link to="/MyDevice" className={deviceClassName}>
    //                 <i className={iconDeviceClassName}>smartphone</i>
    //                 <span className="left-nav__text">My Device</span>
    //             </Link>
    //             <Link to="/Profile" className={profileClassName}>
    //                 <i className={iconProfileClassName}>person</i>
    //                 <span className="nav__text">Profile</span>
    //             </Link>

    //         </div>
    //     </nav>

    //     {chatStatus == '0' ? 
    //         <Link to="/ChatDashboard" className="left-nav__link highlight__menu">
    //             <i className="material-icons left-nav__icon">chat</i>
    //         </Link>
    //     :
    //         <Link to="/ChatDetails" className="left-nav__link highlight__menu">
    //             <i className="material-icons left-nav__icon">chat</i>
    //         </Link>
    //     }
    //     {/* <Link to="/ChatDashboard" className="left-nav__link highlight__menu">
    //         <i className="material-icons left-nav__icon">chat</i>
    //     </Link> */}
    // </div>
    <div></div>
  )
}

export default BottomNavigation