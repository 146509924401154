import React, {Component} from 'react';
import ProfileToolbar from '../component/Toolbar/ProfileToolbar'
import SideDrawer from '../component/SideDrawer/SideDrawer'
import Backdrop from '../component/Backdrop/Backdrop'
import BottomNavigation from '../component/BottomNavigation/BottomNavigation'
import CompProof from '../component/Profile/CompProof'

class Profile extends Component {

    state = {
        sideDrawerOpen: false,
      }

      drawerToggleClickHandler = () => {
        this.setState(prevState => {
          return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
      }

      backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
      }
      
      componentDidMount() {
        
      }

    render() {
        let backdrop

        if (this.state.sideDrawerOpen) {
          backdrop = <Backdrop click={this.backdropClickHandler} />
        }

        return (
            <div style={{height: '100%'}}>

                <ProfileToolbar drawerClickHandler={this.drawerToggleClickHandler}
                                propsName = 'certificateDetails' 
                                headTitle = 'Proof'
                                addLink = '/AddProof'
                                backLink = '/Profile' />
                <SideDrawer show={this.state.sideDrawerOpen} />

                {/* BackDrop */}
                {backdrop}

                {/* *********** Main content starts here *********** */}

                <main>
                <div className="top__align__tool_nv"></div>
                    <CompProof />
                    {/* <div class="adjust-screens-bottom"></div> */}
                </main>

                <BottomNavigation navigationPropsName = 'profile' />
            </div>
        )
    }

}

export default Profile
