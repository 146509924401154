import React, {Component} from 'react'
import './Payment.css'
import { axiosInstance } from '../../Services/PostData';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class PaymentBalanceComp extends Component  {

  constructor(){
		super();
		this.state = {
            response_Value:[],
            pending_amount_value: 0
        };
        this.getPendingAmountDetails = this.getPendingAmountDetails.bind(this);
    }
    
    componentDidMount() { 
        this.getPendingAmountDetails();
        this.setState({userCurrency: cookies.get('techFixerUserCurrency')})
    }

    getPendingAmountDetails() {

        let user_id = cookies.get('techUserId');
        const body = { user_id: user_id };
        axiosInstance.post(`getPendingAmountDetails`, body)
        .then(res => {
          if(res.data.status === 'success' && res.data.statusCode ==='200' )
          {
              let response = res.data.pending_amt_details;
              let pending_amount_value = res.data.total_pendingamount;
              this.setState({pending_amount_value})

              if(response.length ==0) {
                  this.setState({ notTicketFound: '' });
              }
              
              let resposeValue = response.map(pen_amount => { return pen_amount });
              this.setState({ response_Value: resposeValue });
          }
          else {
              this.setState({ notTicketFound: '' });
          }
        })
        .catch(err => console.log(err));

    }

render() {
    return (
        <div className="">
          {/* <div class="text-center w-100 custom-screen-middle">
                <h5 class="empty-ticket-title">No Data</h5>
          </div> */}

          <div className="user_pending_amnt text-center">
                <p className="text_pend_ttl">Total Pending </p>
                <p className="text_pend_cnt">{this.state.userCurrency} {this.state.pending_amount_value}</p>
                {/* <p>Pay now</p> */}
          </div>

          {/* strt pending amount list */}
          {/* <div className="user__pmnt_sng_cr">
              <div className="">
                <p style={{paddingBottom: '8px'}}><span className="color_ts fnt_bld" >Fixes Id &nbsp; :</span> TKT00111</p>
                <p><span className="color_ts fnt_bld">Amount :</span>  $ 12</p>
              </div>
          </div> */}

          {this.state.response_Value.map(pending_amount => (
            <div className="user__pmnt_sng_cr">
                <div className="">
                  <p style={{paddingBottom: '8px'}}>
                    <span className="color_ts fnt_bld" >Tracking Number &nbsp; :</span>
                     TKT000{pending_amount.cus_ticket_id}
                  </p>
                  <p><span className="color_ts fnt_bld">Amount :</span>  {this.state.userCurrency} {pending_amount.tech_ticket_amount}</p>
                </div>
            </div>
          ))}

        </div>
    )
    }
}

export default PaymentBalanceComp