import React, {Component} from 'react'
import { Link, Redirect } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import './CompProfileEdit.css'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Profilee extends Component {

    constructor() {
        super();
        this.state = { 
            teams: [],
            optionsState: '',
            reposeValue: []
        };
        this.getProfile = this.getProfile.bind(this)
        this.getCountryDetails = this.getCountryDetails.bind(this)
        this.onChangeHandler = this.onChangeHandler.bind(this)
        this.onGenderChange = this.onGenderChange.bind(this)
        this.avatarOnChange = this.avatarOnChange.bind(this)
        this.submitProfileEdit = this.submitProfileEdit.bind(this)
        this.handleValidation = this.handleValidation.bind(this)
        this.getAvatarDetails = this.getAvatarDetails.bind(this)
    }
 
    componentDidMount() {
        const baseUrl = window.$baseUrl;
        this.setState({baseUrl})
        
        this.getProfile();
        this.getCountryDetails();
        this.getAvatarDetails();
    }

    getCountryDetails() {
        axiosInstance.get(`getCountryDetails`)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {	
            //setting mobile number lengtha and prefix
            this.state.mobile_number_count =  res.data.country_details[0].mobile_length;
            this.state.prefix =  res.data.country_details[0].prefix;

                let country_details = res.data.country_details;
                let teamsFromApi = country_details.map(team => {
                    return team
                });
                this.setState({
                    teams: teamsFromApi
                });
        }
        })
        .catch(err => console.log('CountryDetails: ', err));
    }
      
      getProfile() {

        //show spinner 
        this.setState({ showSpinner: true });

        const body = {
			user_id: cookies.get('techUserId')
        };
            
        axiosInstance.post(`getProfiles`, body)
        .then(res => {
           if(res.data.status == 'success' && res.data.statusCode =='200' )
           {	
                let e_rate = res.data.profile_details[0].rate;
                let e_email = res.data.profile_details[0].user_email;
                let e_gender = res.data.profile_details[0].user_gender;
                let e_userName = res.data.profile_details[0].user_name;
                let e_phone = res.data.profile_details[0].user_phone;
                let optionsState = res.data.profile_details[0].country_id;
                let choosedAvatar = 'avatar'+ res.data.profile_details[0].avatar_id;
                let e_avatar_id = res.data.profile_details[0].avatar_id;
                let e_experience = res.data.profile_details[0].experience;
                let e_company_name = res.data.profile_details[0].company_name;

                this.setState({e_rate, e_email, e_gender, e_userName, e_phone, optionsState, choosedAvatar, e_avatar_id, e_experience, e_company_name});
           }
           else {
                let e_rate =  '';
                let e_email = '';
                let e_gender = '';
                let e_userName = '';
                let e_phone = '';
                let e_country = '';
                let e_company_name = '';
               
                this.setState({e_rate, e_email, e_gender, e_userName, e_phone, e_country, e_company_name});
           }

           //hide spinner 
            this.setState({ showSpinner: false });
        })
        .catch(err => console.log(err));
      }

    onChangeHandler(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onGenderChange(e) {
        this.setState({e_gender: e.target.value});
    }

    avatarOnChange(e) {
        this.setState({e_avatar_id: e.target.value});
        this.setState({choosedAvatar: 'avatar'+e.target.value});
    }

    getAvatarDetails() {
        
        const body = { user_id: cookies.get('techUserId') };
        axiosInstance.post(`getProfileAvatarDetails`, body)
        .then(res => {
        if(res.data.status == 'success' && res.data.statusCode =='200' )
        {
              let response = res.data.avatar_details;
              let response_value = response.map(values_response => { return values_response });
              this.setState({ reposeValue: response_value });
        }
        })
        .catch(err => console.log('Signup: ', err));
    }


    handleValidation = () => {
        let rateError = "";
        let nameError = "";
        let phoneError = "";
        let emailError = "";
        let countryError = "";
        let genderError = "";
        let experienceError = "";

        if (!this.state.e_rate) {
            rateError = "rate cannot be blank";
        }
        if (!this.state.e_userName) {
            nameError = "username cannot be blank";
        }
        if (!this.state.e_phone) {
            phoneError = "phone cannot be blank";
        }
        if (!this.state.e_email) {
            emailError = "email cannot be blank";
        }
        // if (!this.state.e_country) {
        if (!this.state.optionsState) {
            countryError = "country cannot be blank";
        }
        if (!this.state.e_gender) {
            genderError = "gender cannot be blank";
        }
        if (!this.state.e_experience) {
            experienceError = "gender cannot be blank";
        }

        if (rateError || nameError || phoneError || emailError || countryError || genderError, experienceError ) {
            this.setState({ rateError, nameError, phoneError, emailError, countryError, genderError, experienceError});
            return false;
        }
        return true;
    };

    submitProfileEdit() {
    const isValid = this.handleValidation();
    if (isValid) {
        const user = {
            tech_user_id: cookies.get('techUserId'),
            rate: this.state.e_rate,
            userName: this.state.e_userName,
            phone: this.state.e_phone,
            gender: this.state.e_gender,
            country: this.state.optionsState,
            email: this.state.e_email,
            avatar_id: this.state.e_avatar_id,
            experience: this.state.e_experience,
            company_name: this.state.e_company_name
        };

        //show spinner 
        this.setState({ showSpinner: true });

        let errorMessageresponse = '';
        axiosInstance.post(`editfixersProfile`, user)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            //hide spinner 
            this.setState({ showSpinner: false });

            this.setState({profileRedirect: true});
        }
        else if(res.data.statusCode ==='400' ) {
            errorMessageresponse = res.data.msg
            this.setState({ errorMessageresponse});
        }
        })
        .catch(err => console.log('Login: ', err));
    }
    }

    render() {
        if (this.state.profileRedirect) {
            return <Redirect to="/Profile"/>;
        }
    return (
        <div class="container-fluid mt-3" >

            <div className="tickmarkClass">
                <Link onClick={this.submitProfileEdit}><i class="material-icons backBtnClas">check</i></Link>
            </div>

            <div class="row">
                <div class="col-md-12 col-12">
                    <form>
                        <div class="custom-profile-grid mb-2">
                            <p><b>Choose Avatar</b></p>
                            <div className="row" id="avatars">
                                {this.state.reposeValue.map(values => (
                                    <div className="col-md-4 col-4">
                                        <label class="profile-avatars">
                                            <input type="radio" name="e_avatar_id"
                                                   value={values.avatar_id} 
                                                   onChange={this.avatarOnChange}
                                                   checked={this.state.choosedAvatar === 'avatar'+values.avatar_id } />
                                            <img src={this.state.baseUrl +"uploads/avatar/" + values.image_name} alt="" />
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div class="custom-profile-form mb-2">
                            <div class="row">
                                <div class="col-md-12 col-12 mb-2">
                                    <label class="mb-1">Rate Per Hours ($)</label>
                                    <input type="text" class="form-control borderRadius-20" name="e_rate" onChange={this.onChangeHandler} value={this.state.e_rate} readOnly/>
                                </div>
                                <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.rateError}</span>

                                <div class="col-md-12 col-12 mb-2">
                                    <label class="mb-1">Name</label>
                                    <input type="text" class="form-control borderRadius-20" name="e_userName" onChange={this.onChangeHandler} value={this.state.e_userName}  />
                                </div>
                                <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.nameError}</span>
                                
                                <div class="col-md-12 col-12 mb-2">
                                    <label class="mb-1">Experience</label>
                                    <input type="text" class="form-control borderRadius-20" name="e_experience" onChange={this.onChangeHandler} value={this.state.e_experience}  />
                                </div>
                                <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.experienceError}</span>

                                <div class="col-md-12 col-12 mb-2">
                                    <label class="mb-1">Company Name</label>
                                    <input type="text" class="form-control borderRadius-20" name="e_company_name" onChange={this.onChangeHandler} value={this.state.e_company_name}  />
                                </div>

                                <div class="col-md-12 col-12 mb-2">
                                    <label class="mb-1">Mobile Number</label>
                                    <input type="text" class="form-control borderRadius-20" name="e_phone" onChange={this.onChangeHandler} value={this.state.e_phone} readOnly/>
                                </div>
                                <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.phoneError}</span>
                                
                                <div class="col-md-12 col-12 mb-2">
                                    <label class="mb-1">Email</label>
                                    <input type="text" class="form-control borderRadius-20" name="e_email" onChange={this.onChangeHandler} value={this.state.e_email} readOnly />
                                </div>
                                <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.emailError}</span>

                                <div class="col-md-12 col-12 mb-2">
                                    <label class="mb-1">Country</label>
                                    {/* <select class="form-control" name="country">
                                        <option value="1"> India </option>
                                    </select> */}

                                    <select value={this.state.optionsState} className="form-control borderRadius-20"
                                            name="e_country" 
                                            onChange={(e) => this.setState({optionsState: e.target.value})}
                                            autoComplete="off">
                                        <option value="">Please Select Country </option>
                                        {this.state.teams.map(team => (
                                            <option key={team.country_id} value={team.country_id} > {team.country_name} </option>
                                        ))}
                                    </select>

                                </div>
                                <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.countryError}</span>

                                <div class="col-md-12 col-12 mb-2">
                                    <label class="mb-1">Gender</label>
                                            
                                    <div class="d-inline ml-5">
                                        <input type="radio" id="male" name="e_gender" value="male" checked={this.state.e_gender === 'male'} onChange={this.onGenderChange} />
                                        <label for="male">Male</label>
                                    </div>
                                    <div class="d-inline ml-2">
                                        <input type="radio" id="female" name="e_gender" value="female" checked={this.state.e_gender === 'female'} onChange={this.onGenderChange} />
                                        <label for="female">Female</label>
                                    </div>
                                    <div class="d-inline ml-2">
                                        <input type="radio" id="others" name="e_gender" value="others" checked={this.state.e_gender === 'others'} onChange={this.onGenderChange} />
                                        <label for="female">Others</label>
                                    </div>       
                                    
                                </div>
                                <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.genderError}</span>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {this.state.showSpinner ? 
                <div className="lds-dual-ring custom-screen-middle"></div>
                :
                null
            }

        </div>
    )
}
}
 
export default Profilee