import React, {Component} from 'react'


class ContactComp extends Component  {

render() {
    return (
        <div className="container-fluid custom-screen-middle"> 
            <div style={{textAlign: 'center'}}>
                <img src="assets/images/logo-ts.png" width="50%" />
            </div>
            <p class="custom-legal-p-tag"><b>How to Contact Us</b></p>
            <p class="custom-legal-p-tag">If you have any questions or concerns regarding the Privacy Policy Agreement related to our website, please feel free to contact us at the following email, telephone number or mailing address.</p>
            <p class="custom-legal-p-tag">Email: info@touchtofix.com</p>
            <p class="custom-legal-p-tag">Telephone Number: </p>
            <p class="custom-legal-p-tag">Mailing Address: TSAS.</p>
        </div>
    )
}
}

export default ContactComp