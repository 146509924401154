import React, {Component} from 'react'
import { Link, Redirect, Route, Switch } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import './AllFixes.css'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class AllFixes extends Component {

    constructor(){
		super();
		this.state = {
            tickets: [],
            notTicketFound: 'hide__container'
        };
		this.getAllTicketDetails = this.getAllTicketDetails.bind(this);
	}

    componentDidMount() { 
        if(cookies.get('techUserId') == undefined) {
           this.setState({loginRdirect: true})
        }
        else {
            this.getAllTicketDetails(); 
        }
    }
    
	getAllTicketDetails()
	{
        //show spinner 
        this.setState({ showSpinner: true });

		const body = {
            user_id: cookies.get('techUserId'),
            type: 'all'
		};

		axiosInstance.post(`getfixes`, body)
		.then(res => {
		    if(res.data.status === 'success' && res.data.statusCode ==='200' )
		    {
                //hide spinner
                this.setState({ showSpinner: false });

                let ticket_res = res.data.fixes_details;
                if(ticket_res.length ==0) {
                    this.setState({ notTicketFound: '' });
                }
                
                let ticketDetails = ticket_res.map(ticket => { return ticket });
				this.setState({ tickets: ticketDetails });
            }
		})
		 .catch(err => console.log('Login: ', err));
	}

    redirectFixesDetails(id) {
        this.setState({redirect: true});
    }

    setFixesDetailsId(id) {
        cookies.set('fixesDetailsIdTechSupport', id)
    }

    render() {
        
        if (this.state.redirect) {
            let asas = '?asasasa' + '1';
            return <Redirect push to="/FixesDetail" {...asas} />;
        }

        if(this.state.loginRdirect) {
            return <Redirect to="/" />;
        }

    return (
        
    <div class="fixes__details">
        <div class="fixes__head"></div>
        <div class="fixes__content">

            <div className={this.state.notTicketFound} style={{margin: '15px', backgroundColor: '#ffffff'}}>
                {/* No Fixes found */}
                <div class="text-center w-100 custom-screen-middle">
                    <img src="assets/images/empty-ticket.png" class="empty-ticket-img" />
                    <h5 class="empty-ticket-title">No Fixes</h5>
                </div>
            </div>


            {this.state.tickets.map(ticket_details => (

            <div className="fixes__content_sng" key={ticket_details.cus_ticket_id}>
                <div className="fixes__content_sng_hd">
                    {/* <p className="fixes__content_sng_hd_ttl"> {ticket_details.cus_ticket_title} </p> */}

                    <p className="trk__id_nmbr">
                        <img src="assets/icons/menu-icons/images/ticket-1.png" class="cs_ficn_cls" />
                        Tracking number : TKT000{ticket_details.cus_ticket_id}
                    </p>
                    

                    <p className="spacee"></p>
                    <p className="fixes__content_sng_hd_dte">
                        <img src="assets/icons/menu-icons/images/calendar.png" class="cs_ficn_cls" />
                        {ticket_details.cus_ticket_created_date} 
                    </p>
                </div>

                {/* <p className="trk__id_nmbr">Tracking number : TKT000{ticket_details.cus_ticket_id}</p> */}
                <div className="ldetails list_cases">
                    <Link className="max-w20 cont-comp">
                        <img src="assets/icons/menu-icons/images/profile-1.png" class="cs_ficn_cls" />
                        {ticket_details.cus_user_name}
                    </Link>
                    {/* <span className="time_list"> <span className="lsep cre-sep">.</span>
                        <span> <i className="fa fa-clock-o" aria-hidden="true"></i> 
                            <span className="time-dte-font"> 15 hours ago</span>
                        </span>
                    </span> */}
                </div>
                <div className="fixes__content_ftr">
                    <p className="sts text-success">{ticket_details.cus_ticket_assign_status}</p>
                    <p className="spacee"></p>
                    {/* <Link to="/FixesDetail">Show details</Link> */} 
                    {/* <Link to={{ pathname: '/FixesDetail', search: 'ticketDetails=' + ticket_details.cus_ticket_id  }} >Show details</Link> */}
                    <Link onClick={() => this.setFixesDetailsId(ticket_details.cus_ticket_id) } 
                        to={{ pathname: '/FixesDetail', search: 'fixesDetail='+ticket_details.cus_ticket_id  }} >Show details</Link>
                    {/* <Link onClick={() => this.redirectFixesDetails(ticket_details.cus_ticket_id )} >Show details</Link> */}
                </div>
            </div>
            
            ))}
            
        </div>

        {this.state.showSpinner ? 
            <div className="lds-dual-ring custom-screen-middle"></div>
            :
            null
        }
    </div>


  )
}
}

export default AllFixes