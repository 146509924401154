import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../logo-ts.png';
import { axiosInstance } from '../Services/PostData';
import swal from 'sweetalert2';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Signup extends Component  {

	constructor(){
		super();
		this.state = {
			name: '',
			email: '',
			mobile_number: '',
			password: '',
			confirm_password: '',
			gender: '',
			country: '',
			nameError: '',
			ip_address: '',
			client_ip_address: '',
			teams: [],
			prefix: '',
			mobile_number_count: ''
		};
		this.SignUp = this.SignUp.bind(this)
		this.onChange = this.onChange.bind(this)
		this.getCountryPrefix  = this.getCountryPrefix.bind(this)
		this.getCountryDetails = this.getCountryDetails.bind(this)
	}

	getCountryPrefix(e) {
		this.state.prefix = e.target.value;
		this.state.mobile_number_count = e.target.selectedOptions[0].getAttribute('data-length');
	}

	validate = () => {
		let nameError = "";
		let emailError = "";
		let passwordError = "";
		let confirmPasswordError = "";
		let countryError = "";
		let phoneError = "";
		let genderError = "";

		//username
		if (!this.state.name) {
		  nameError = "Name cannot be blank";
		}
		//Email
		if (!this.state.email.includes("@")) {
		  emailError = "invalid email";
		}
		// password
		const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

		if( this.state.password.length < 8) {
			passwordError = "Password should be 8 characters";
		}
		else if( ! strongRegex.test(this.state.password)) {
			passwordError = "use special character, number and capital letter";
		}
		//confirm password
		if(this.state.password !== this.state.confirm_password)
		{
			confirmPasswordError = "Wrong password";
		}
		//country
		if (!this.state.country && this.state.country === '') {
			countryError = "Select Country";
		}
		//Mobile Number
		if (!this.state.mobile_number) {
			phoneError = "Enter Mobile Number";
		}
		else if(this.state.mobile_number.length != this.state.mobile_number_count)
		{
			phoneError = "Your mobile number should be " + this.state.mobile_number_count + " Digit";
		}
		//Gender
		if (!this.state.gender) {
			genderError = "Select Gender";
		}

		if (emailError || nameError || passwordError || confirmPasswordError || countryError || phoneError || genderError) {
		  this.setState({ emailError, nameError, passwordError, confirmPasswordError, countryError, phoneError, genderError });
		  return false;
		}
	
		return true;
	  };
	  
	//   async getPublicAddress() {
	// 	let ClientIpAddress = '';
	// 	const publicIp = require('public-ip');
	// 	ClientIpAddress = publicIp.v4();
	// 	this.setState({ ClientIpAddress });
	//   }

	SignUp() {
		let deviceTokenFcm = cookies.get('deviceTokenFcm');
		if(deviceTokenFcm == undefined) {
			deviceTokenFcm = ''
		}
		const user = {
			name: this.state.name,
			email: this.state.email,
			mobile_number: this.state.mobile_number,
			password: this.state.password,
			confirm_password: this.state.confirm_password,
			gender: this.state.gender,
			country: this.state.country,
			prefix: this.state.prefix,
			deviceTokenFcm: deviceTokenFcm,
			type: 'w'
		};
	
		//calling signup API
		let errorMessageresponse = '';
    	const isValid = this.validate();
    	if (isValid) {

			let spinner = 'lds-dual-ring custom-screen-middle';
			let overlay = 'overlay';
			this.setState({ spinner, overlay });

			axiosInstance.post(`SignUp`, user)
			.then(res => {
			   if(res.data.status === 'success' && res.data.statusCode ==='200' )
			   {
				//  this.props.history.push("/");

				// let messageBox = 'show';
				// this.setState({ messageBox });

				swal.fire({
					icon: 'info',
					title: 'Registration Success',
					// text: 'do you want to continuew',
					confirmButtonText: 'ok',
					confirmButtonColor: '#D90429'
				}).then ((result) => {
					this.props.history.push("/");
				})

			   }
			   else if(res.data.statusCode ==='400' ) {
					errorMessageresponse = res.data.msg
					this.setState({ errorMessageresponse});
				}

				let spinner = 'lds-dual-ring custom-screen-middle hide__container';
				let overlay = 'overlay hide__container';
				this.setState({ spinner, overlay });

			})
			.catch(err => console.log('SignUp: ', err));
		}
	}

	

	onChange(e) {
		this.setState({[e.target.name]: e.target.value})
	}	
	getCountryDetails() {
		axiosInstance.get(`getCountryDetails`)
		.then(res => {
		   if(res.data.status === 'success' && res.data.statusCode ==='200' )
		   {	
			   //setting mobile number lengtha and prefix
			   this.state.mobile_number_count =  res.data.country_details[0].mobile_length;
			   this.state.prefix =  res.data.country_details[0].prefix;

			    let country_details = res.data.country_details;
				let teamsFromApi = country_details.map(team => {
					return team
				});
				this.setState({
					teams: teamsFromApi
				});
		   }
		})
		.catch(err => console.log('CountryDetails: ', err));
	}

	
	componentDidMount() {
		this.getCountryDetails();
	}


	render() {
		
    return (
    //     <div className="limiter">
	// 	<div className="container-login100 login-bg-screen">
	// 		<div className="wrap-login100 p-l-55 p-r-55 p-t-10 p-b-10">
	// 			<div className="text-center"><img className="customlogo" src={Logo} alt="" /></div>
	// 			<form className="login100-form validate-form" method="post">
	// 				<span className="login100-form-title p-b-30">
	// 					Signup
	// 				</span>

	// 				<div className="wrap-input100 validate-input m-b-10" data-validate = "Name is reauired">
	// 					<span className="label-input100">Name <span className="required__field">*</span></span>
	// 					<input className="input100 inpt-rnd" type="text" name="name" autoComplete="off" placeholder="Your Name" onChange={this.onChange} />
	// 					<span className="focus-input100" data-symbol="&#xf206;"></span>
	// 				</div>
	// 				<span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.nameError}</span>

 
	// 				<div className="wrap-input100 validate-input m-b-10" data-validate = "Mobile Number is reauired">
	// 					<span className="label-input100">Mobile Number <span className="required__field">*</span></span>
	// 					{/* <input className="input100 mobile_input" type="number" name="mobile_number" autoComplete="off"
	// 						   placeholder="Type your Mobile No" onChange={this.onChange} /> */}
	// 					<div className="selected-flag" >
	// 						<select onChange={this.getCountryPrefix}>
	// 						{this.state.teams.map(team => (
    //         					<option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > {team.prefix} </option>
	// 						  ))}
	// 						</select>

	// 						<input className="input100 mobile_input sp" type="number" name="mobile_number" autoComplete="off"
	// 						   placeholder="Type your Mobile No" onChange={this.onChange} />
	// 					</div> 
	// 				</div>
	// 				<span  className="m-b-10" style={{ fontSize: 12, color: "#D90429" }}>{this.state.phoneError}</span>

	// 				<div className="wrap-input100 validate-input m-b-10" data-validate = "Email is reauired">
	// 					<span className="label-input100">Email ID <span className="required__field">*</span></span>
	// 					<input className="input100 inpt-rnd" type="email" name="email" autoComplete="off" placeholder="Type your Email ID" onChange={this.onChange} />
	// 					<span className="focus-input100" data-symbol="&#x2709;"></span>
	// 				</div>
	// 				<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.emailError}</span>

	// 				<div className="wrap-input100 validate-input m-b-10" data-validate="Country is required">
	// 					<span className="label-input100">Country <span className="required__field">*</span></span>
	// 					<select className="input100 inpt-rnd" type="text" name="country" onChange={this.onChange} autoComplete="off">
	// 						<option value="">Please Select Country </option>
	// 						{this.state.teams.map(team => (
    //         					<option key={team.country_id} value={team.country_id} > {team.country_name} </option>
	// 					  	))}
    //   					</select>
	// 					<span className="focus-input100" data-symbol="&#x2708;"></span>
	// 				</div>
	// 				<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.countryError}</span>

	// 				<div className="wrap-input100 validate-input m-b-10 bb-none" data-validate = "Gender is reauired">
	// 					<span className="label-input100">Gender <span className="required__field">*</span> </span>
	// 					<div className="d-inline ml-5">
	// 						<input type="radio" id="male" name="gender" value="male" onChange={this.onChange} />
	// 						<label style={{fontSize: '14px'}} className="m-l-5">Male</label>
	// 					</div>
	// 					<div className="d-inline ml-2"> 
	// 						<input type="radio" id="female" name="gender" value="female" onChange={this.onChange} />
	// 						<label style={{fontSize: '14px'}} className="m-l-5">Female</label>
	// 					</div> 
	// 					<div className="d-inline ml-2">
	// 						<input type="radio" id="female" name="gender" value="others" onChange={this.onChange} />
	// 						<label style={{fontSize: '14px'}} className="m-l-5">Others</label>
	// 					</div>
	// 				</div>
	// 				<span  className="m-b-10" style={{ fontSize: 12, color: "#D90429" }}>{this.state.genderError}</span>

	// 				<div className="wrap-input100 validate-input" data-validate="Password is required">
	// 					<span className="label-input100">Password <span className="required__field">*</span></span>
	// 					<input className="input100 inpt-rnd" type="password" name="password" placeholder="Type your password" onChange={this.onChange} />
	// 					<span className="focus-input100" data-symbol="&#xf190;"></span>
	// 				</div>
	// 				<span style={{display: 'block', fontSize: '10px', marginTop: '5px'}}><span style={{color: 'green', fontWeight: 'bold' }}>** Note :</span> Use special character, number, small letter and capital letter</span>
	// 				<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.passwordError}</span>

	// 				<div className="wrap-input100 validate-input m-b-10" data-validate="Password is required">
	// 					<span className="label-input100">Confirm Password</span>
	// 					<input className="input100 inpt-rnd" type="password" name="confirm_password" placeholder="Confirm your password" onChange={this.onChange} />
	// 					<span className="focus-input100" data-symbol="&#xf190;"></span>
	// 				</div>
	// 				<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.confirmPasswordError}</span>

					
	// 				<div className="container-login100-form-btn p-t-20">
	// 					<div className="wrap-login100-form-btn">
	// 						<div className="login100-form-bgbtn"></div>
	// 						<a className="custom-txt-none custom-a-line-none">
	// 							<button type="button" className="login100-form-btn" onClick={this.SignUp}>SignUp</button>
	// 						</a>
	// 					</div>
	// 				</div>
	// 				<div  className="m-b-5 text-center m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#D90429" }}>{this.state.errorMessageresponse}</div>
					
	// 				<div className="p-t-8 p-b-50">
	// 					<div className="float-left" style={{fontSize: '16px'}}>
    //                         Already Have an Account? <Link style={{color: '#2B2D42'}} to="/">Login</Link>
	// 					</div>
	// 				</div>
	// 			</form>
	// 		</div>
	// 	</div>

	// 	<div className={this.state.overlay}>
	// 		<div className={this.state.spinner}></div>
	// 	</div>

	// </div>

	<div className="limiter-login">
		<div className="top-container-login" style={{textAlign: 'center'}}>
			<img className="logo-classcustom" src="assets/images/logo-white.png" alt="" style={{width: '200px'}} />
				<span className="login100-form-title color-tmp-2 p-b-30"> Expert Fixers Register </span>
			</div>
		<div className="container-login100" style={{marginTop: '-30%'}}>
			<div className="wrap-login100 p-l-55 p-r-55 p-t-10 p-b-10" style={{borderRadius: '20px'}}>
				{/* <div className="text-center">
					<img className="customlogo" src={Logo} alt="" />
					</div> */}
				<form className="login100-form validate-form" method="post">
					{/* <span className="login100-form-title p-b-30">
						Signup
					</span> */}

					<div className="wrap-input100 validate-input m-b-10" data-validate = "Name is reauired">
						<span className="label-input100">Name <span className="required__field">*</span></span>
						<input className="input100 inpt-rnd" type="text" name="name" autoComplete="off" placeholder="Your Name" onChange={this.onChange} />
						<span className="focus-input100" data-symbol="&#xf206;"></span>
					</div>
					<span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.nameError}</span>

 
					<div className="wrap-input100 validate-input m-b-10" data-validate = "Mobile Number is reauired">
						<span className="label-input100">Mobile Number <span className="required__field">*</span></span>
						{/* <input className="input100 mobile_input" type="number" name="mobile_number" autoComplete="off"
							   placeholder="Type your Mobile No" onChange={this.onChange} /> */}
						<div className="selected-flag" >
							<select onChange={this.getCountryPrefix}>
							{this.state.teams.map(team => (
            					<option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > {team.prefix} </option>
							  ))}
							</select>

							<input className="input100 mobile_input sp" type="number" name="mobile_number" autoComplete="off"
							   placeholder="Type your Mobile No" onChange={this.onChange} />
						</div> 
					</div>
					<span  className="m-b-10" style={{ fontSize: 12, color: "#D90429" }}>{this.state.phoneError}</span>

					<div className="wrap-input100 validate-input m-b-10" data-validate = "Email is reauired">
						<span className="label-input100">Email ID <span className="required__field">*</span></span>
						<input className="input100 inpt-rnd" type="email" name="email" autoComplete="off" placeholder="Type your Email ID" onChange={this.onChange} />
						<span className="focus-input100" data-symbol="&#x2709;"></span>
					</div>
					<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.emailError}</span>

					<div className="wrap-input100 validate-input m-b-10" data-validate="Country is required">
						<span className="label-input100">Country <span className="required__field">*</span></span>
						<select className="input100 inpt-rnd" type="text" name="country" onChange={this.onChange} autoComplete="off">
							<option value="">Please Select Country </option>
							{this.state.teams.map(team => (
            					<option key={team.country_id} value={team.country_id} > {team.country_name} </option>
						  	))}
      					</select>
						<span className="focus-input100" data-symbol="&#x2708;"></span>
					</div>
					<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.countryError}</span>

					<div className="wrap-input100 validate-input m-b-10 bb-none" data-validate = "Gender is reauired">
						<span className="label-input100">Gender <span className="required__field">*</span> </span>
						<div className="d-inline ml-5">
							<input type="radio" id="male" name="gender" value="male" onChange={this.onChange} />
							<label style={{fontSize: '14px'}} className="m-l-5">Male</label>
						</div>
						<div className="d-inline ml-2"> 
							<input type="radio" id="female" name="gender" value="female" onChange={this.onChange} />
							<label style={{fontSize: '14px'}} className="m-l-5">Female</label>
						</div> 
						<div className="d-inline ml-2">
							<input type="radio" id="female" name="gender" value="others" onChange={this.onChange} />
							<label style={{fontSize: '14px'}} className="m-l-5">Others</label>
						</div>
					</div>
					<span  className="m-b-10" style={{ fontSize: 12, color: "#D90429" }}>{this.state.genderError}</span>

					<div className="wrap-input100 validate-input" data-validate="Password is required">
						<span className="label-input100">Password <span className="required__field">*</span></span>
						<input className="input100 inpt-rnd" type="password" name="password" placeholder="Type your password" onChange={this.onChange} />
						<span className="focus-input100" data-symbol="&#xf190;"></span>
					</div>
					<span style={{display: 'block', fontSize: '10px', marginTop: '5px'}}><span style={{color: 'green', fontWeight: 'bold' }}>** Note :</span> Use special character, number, small letter and capital letter</span>
					<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.passwordError}</span>

					<div className="wrap-input100 validate-input m-b-10" data-validate="Password is required">
						<span className="label-input100">Confirm Password</span>
						<input className="input100 inpt-rnd" type="password" name="confirm_password" placeholder="Confirm your password" onChange={this.onChange} />
						<span className="focus-input100" data-symbol="&#xf190;"></span>
					</div>
					<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.confirmPasswordError}</span>

					
					<div className="container-login100-form-btn p-t-20">
						<div className="wrap-login100-form-btn">
							<div className="login100-form-bgbtn"></div>
							<a className="custom-txt-none custom-a-line-none">
								<button type="button" className="login100-form-btn" onClick={this.SignUp}>SignUp</button>
							</a>
						</div>
					</div>
					<div  className="m-b-5 text-center m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#D90429" }}>{this.state.errorMessageresponse}</div>
					
					<div className="p-t-8 p-b-50">
						<div className="float-left" style={{fontSize: '16px'}}>
                            Already Have an Account? <Link style={{color: '#2B2D42'}} to="/">Login</Link>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div className={this.state.overlay}>
			<div className={this.state.spinner}></div>
		</div>

	</div>
)
}
}

export default Signup