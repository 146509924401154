import React, {Component} from 'react';
import ProfileToolbar from '../component/Toolbar/ProfileToolbar'
import SideDrawer from '../component/SideDrawer/SideDrawer'
import Backdrop from '../component/Backdrop/Backdrop'
import BottomNavigation from '../component/BottomNavigation/BottomNavigation'

class Authenticator extends Component {

  timer=0;
  constructor(){
		super();
		this.state = {
			  sideDrawerOpen: false,
        randomNumber: '452375'
		};
		this.componentDidMount = this.componentDidMount.bind(this)
  }
  


      drawerToggleClickHandler = () => {
        this.setState(prevState => {
          return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
      }

      backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
      }
      
      componentDidMount() { 
        this.authenticatorNumberFunc();
       }

      authenticatorNumberFunc() {
        var query = window.location.search.substring(1);

        var countdownNumberEl = document.getElementById('countdown-number');
        var countdown = 30;
        countdownNumberEl.textContent = countdown;
        setInterval(function() {
            countdown = --countdown <= 0 ? 30 : countdown;
            countdownNumberEl.textContent = countdown;
        }, 1000);

        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

      var ms = 30000;
      var func = function() {
          var randNum = getRandomInt(100000, 999999);
          document.getElementById("element_id").innerHTML = randNum;
      };
        
        func();
        this.timer=setInterval(func, ms);
      }
       
      componentWillUnmount() {
        clearInterval(this.timer);
      }

    render() {
        let backdrop
        if (this.state.sideDrawerOpen) {
          backdrop = <Backdrop click={this.backdropClickHandler} />
        }

        return (
            <div style={{height: '100%'}}>
                <ProfileToolbar drawerClickHandler={this.drawerToggleClickHandler}
                                propsName = 'authNumber' 
                                headTitle = 'Authentication Number'
                                addLink = ''
                                backLink = '/Dashboard' />
                <SideDrawer show={this.state.sideDrawerOpen} />

                {/* BackDrop */}
                {backdrop}


                <main>
                <div className="top__align__tool_nv"></div>

                <div id="countdown" class="text-center">
                    <div id="countdown-number"></div>
                    {/* <svg className="authSvg">
                        <circle r="18" cx="20" cy="20"></circle>
                    </svg> */}
                </div>

                <div class="text-center w-100 custom-screen-center">
                    <h5 class="auth_custom-auth-title">Authentication Number</h5>
                    <h5 id="element_id" class="custom-auth-no-style "></h5>
                </div>

                </main>

                <BottomNavigation navigationPropsName = 'home' />
            </div>
        )
    }

}

export default Authenticator
