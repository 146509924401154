import axios from "axios";

export const axiosInstance = axios.create({
//   baseURL: 'http://localhost/main-folder/projects/tsas-sanyaa/TsasTechSupport/'
//      baseURL: 'http://35.213.150.135/demo/sanyaa-api/index.php/TsasTechSupport/'
    
      //baseURL: 'https://api.touchtofix.com/demo/sanyaa-api/index.php/TsasTechSupport/'
//      baseURL: 'https://api.touchtofix.com/demo/sanyaa-api/index.php/TsasTechSupport_v_1_1_3/'
     // baseURL: 'https://api.touchtofix.com/demo/sanyaa-api/index.php/TsasTechSupportModel_v_1_1_3_v1/'
 baseURL: 'https://api.touchtofix.com/demo/sanyaa-api/index.php/TsasTechSupport_v_1_1_3_v1/'

});
