import React, {Component, useState} from 'react'
import { Redirect } from 'react-router-dom'
import './ChatDetailsComp.css'
import { axiosInstance } from '../../Services/PostData';
import { SocketConnection } from '../../Services/SocketConnection';
import swal from 'sweetalert2';
import io from "socket.io-client";
import RenderMediaComponent from './RenderMedia'

//camera
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from './ImagePreview';

//zoom image
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

//cookes
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// const [dataUri, setDataUri] = useState('');

let socket = io.connect(SocketConnection)

class ChatDetailsComp extends Component  {

    constructor(){
		super();
		this.state = {
            responseValue: [],
            description: '',
            image_file: '',
            chatMessageError: '',
            VideoMessageSection: ''
        };
		this.getRoomDetails = this.getRoomDetails.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.handleTakePhotoAnimationDone = this.handleTakePhotoAnimationDone.bind(this);
		this.openMedia = this.openMedia.bind(this);
		this.getChatAvailableStatus = this.getChatAvailableStatus.bind(this);
    }

    componentDidMount() { 
      
      const baseUrl = window.$baseUrl;
      this.setState({baseUrl})

      this.getChatAvailableStatus(); 
      this.getRoomDetails(); 

      socket.emit('join room', cookies.get('tech_socket_subscribeId'))

       //***get socket chat details ***
      socket.on('get_chat_details', (name, data) => {
        let response = data;
        let responseValue_arr = this.state.responseValue
        responseValue_arr.push(response[0]);
        
        let responseDetails = responseValue_arr.map(detail_values => { return detail_values });
        this.setState({ responseValue: responseDetails });

        //***scroll to top ***
        this.scrollToBottom();

      })

      //***close ticket ***
      socket.on('closeChatRoonConnection', (name, message) => {
        this.closeChatConnection(name, message)
      })

    }

    getChatAvailableStatus() {
      const postvalues = { user_id: cookies.get('techUserId') };
      axiosInstance.post(`getChatAvailableStatus`, postvalues)
      .then(res => {
      if(res.data.status === 'success' && res.data.statusCode ==='200' )
      {
          let chat_response = res.data.chat_details;
          if(chat_response.length ==0) {
              cookies.remove('sessionTechChatRoomId') 
          }
      }
      })
      .catch(err => console.log(err));
    }

    getRoomDetails()
    {
      
      let sessionTechChatRoomId = cookies.get('sessionTechChatRoomId');
      if(sessionTechChatRoomId == undefined) {
        this.setState({dashboardRedirect: true})
      } 
      else {
        this.getChatDetails(sessionTechChatRoomId);
        this.getChatRoomDetails(sessionTechChatRoomId);
      }

      // const body = {
      //   chat_room_id: queryValue
      // };

      //     var notifiFunc = async function() {
      //         const user = { user_id: cookies.get('techUserId') };
      //         await axiosInstance.post(`getChatRoomDetails`, body)
      //         .then(res => {
      //             if(res.data.status === 'success' && res.data.statusCode ==='200' )
      //             {
      //                 let response = res.data.chat_details;
      //                 if(response.length !=0)
      //                 {
      //                     let notTicketFound = 'hide__container';
      //                     this.setState({ notTicketFound });
      //                 }
      //                 let responseDetails = response.map(detail_values => { return detail_values });
      //                 this.setState({ responseValue: responseDetails });
      //                 // document.getElementById('focusSection').focus();
      //                 this.nameInput.focus();
      //             }
      //         })
      //         .catch(err => console.log(err));
      //     }.bind(this);
                  
          // notifiFunc();
          // this.timer=setInterval(notifiFunc, 3000)
      }

      getChatRoomDetails(id) {
          const body = { chat_room_id: id };
          axiosInstance.post(`getChatRoomDetails`, body)
          .then(res => {
              if(res.data.status === 'success' && res.data.statusCode ==='200' )
              {
                  let response = res.data.chat_details;
                  if(response.length !=0)
                  {
                      let notTicketFound = 'hide__container';
                      this.setState({ notTicketFound });
                  }
                  let responseDetails = response.map(detail_values => { return detail_values });
                  this.setState({ responseValue: responseDetails });
                  
                  //***scroll to top ***
                  this.scrollToBottom();
              }
          })
          .catch(err => console.log(err));
      }

      getChatDetails(id) {
        const body = { chat_id: id };
        axiosInstance.post(`getChatDetails`, body)
        .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
              let room_response = res.data.chat_details;
              if(room_response.length !=0) {
                this.getChatRoomDetails(room_response[0].chat_room_id)

                // let chat_room_detail_id = room_response[0].chat_room_id;
                // let chat_room_tech_id   = room_response[0].tech_user_id;
                // let chat_room_cus_id    = room_response[0].cus_user_id;
                let chat_room_session   = room_response[0].chat_session;
                let socketUsername   = room_response[0].user_name;

                socket.emit('join room', chat_room_session)
                this.setState({chat_room_session, socketUsername });
                // this.setState({ chat_room_detail_id, chat_room_tech_id, chat_room_cus_id, chat_room_session });
               
              }
            }
        })
        .catch(err => console.log(err));
      }

      closeChatConnection(name, message) {
        swal.fire({
          text: message,
          type: 'success'
        }).then ((result) => { this.cancelFixes() })
      }

      cancelFixes() {
        let queryValue = cookies.get('sessionTechChatRoomId');
        const body = { chat_room_id: queryValue };
        axiosInstance.post(`closeFixes`, body)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
        {
            cookies.remove('sessionTechChatRoomId');
            this.setState({dashboardRedirect: true})
        }
        })
        .catch(err => console.log('Login: ', err));
      }

      handleImagePreview = (e) => {

        console.log(e.target.files[0])
          let image_as_base64 = URL.createObjectURL(e.target.files[0])
          let image_as_files = e.target.files[0];
          let attach_file_name = e.target.files[0].name;
      
          this.setState({
              image_preview: image_as_base64,
              image_file: image_as_files,
              attach_file_name: attach_file_name
          })
          // this.submitInsertChat();
      }


      submitInsertChat() {

        let queryValue = cookies.get('sessionTechChatRoomId');

        let formData = new FormData();
        formData.append('imageFile', this.state.image_file);
        formData.append('chat_room_id', queryValue );
        formData.append('description', this.state.description);

  
        if(this.state.description != '' || this.state.image_file != '') {
          axiosInstance.post(`insertFixersChatMessage`, formData)
          .then(res => {

            let socket_message = this.state.description;
            //reset chat form
            this.state.description = '';
            this.state.attach_file_name = '';
            this.state.image_file = '';
            document.getElementById("image_url").value = ''
            this.setState({chatMessageError: ''})
            document.getElementById("sendBtnCht").disabled = true;
            document.getElementById("typing-loader").classList.add("show");

          if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
          {
            let image_url = "";
            let file_extension = '';
            if(res.data.chat_details.length != 0) {
              image_url = res.data.chat_details[0].file_name;
            }
            // let to_connect   = 'tech-user' + this.state.chat_room_tech_id;
            let to_connect   = this.state.chat_room_session;
            // let message = this.state.description;
            let message = socket_message;
            let type = 'T';
            let userNameSocket = this.state.socketUsername;

            socket.emit('get_chat_details', to_connect, message, image_url, type, userNameSocket, file_extension)

            this.state.description = '';
            // this.state.image_file = '';
            // document.getElementById("image_url").value = '';
            // this.setState({chatMessageError: ''})

            document.getElementById("typing-loader").classList.remove("show");
            document.getElementById("sendBtnCht").disabled = false;
            // this.getRoomDetails();
          }
          })
          .catch(err => console.log('Login: ', err));
        }
        else {
          let chatMessageError = "please type something or choose file to send";
          this.setState({chatMessageError})
          document.getElementById("sendBtnCht").disabled = false;
        }
      }
  
      onChangeHandler(e) {
          this.setState({[e.target.name]: e.target.value});
      }
    
      scrollToBottom() {
        const messagess = document.getElementById('chatMessagess');
        messagess.scrollTop = messagess.scrollHeight;
      }

      //********** camera
     
      handleTakePhotoAnimationDone (dataUri) {
      // console.log(dataUri)
      this.setState({dataUri})

      let unique_name = Date.now()+'.png';
      var file_ = new File([this.state.dataUri], unique_name, { type: 'image/png', length: 1 } );
      let image_as_base64 = URL.createObjectURL(file_)
      let image_as_files = file_;
      let attach_file_name = file_.name;

      console.log(file_)
      // console.log(file_)
      // console.log(image_as_base64)
      // console.log(image_as_files)
      // console.log(attach_file_name)

      let queryValue = cookies.get('sessionTechChatRoomId'); 

      let formData = new FormData();
      formData.append('imageFile', image_as_files);
      formData.append('chat_room_id', queryValue );
      formData.append('description', 'desc');

      axiosInstance.post(`insertFixersChatMessage`, formData)
      .then(res => { 

      })
      .catch(err => console.log(err));

      
      // getBase64(dataUri)
    }

    

    openCamera() {
      this.setState({cameraOpen: true})
    }

    openMedia(id) {
      this.setState({ showMediaSection : id})
      return RenderMediaComponent
    }
    
    render() {
      if(this.state.dashboardRedirect) {
        return <Redirect to={{ pathname: '/Dashboard' }} />;
      }

      // if(this.state.cameraOpen) {
      //   return(
      //     <div>
      //       <Camera onTakePhotoAnimationDone = {this.handleTakePhotoAnimationDone} />
      //     </div>
      //   )
      // }
        return(
          
          <div className="container-chat clearfix no-pad">
              <div className="chat">
                <div className="chat-history" id="chatMessagess">
                  <ul>
                  {this.state.responseValue.map(values => (

                    <li className="clearfix" key={values.chat_history_id}>
                      {(values.type === 'T') ? 
                        <span>
                          <div className="message-data align-right"> 
                             <span className="message-data-name">Me</span> <i className="fa fa-circle me"></i>
                           </div>
                           {/* <div className="message other-message float-right"> */}
                             
                             {values.file_name != '' ?
                             <div className="message other-message float-right cht-immg">
                               <Zoom>
                                <img src={this.state.baseUrl + 'uploads/chatAttach/'+values.file_name} 
                                  alt=""
                                  width="150px"
                                  style={{float: 'right'}} />
                              </Zoom>
                              {values.description}
                              </div>
                              :
                              <div className="message other-message float-right">
                                {values.description}
                                </div>
                             }
                           {/* <img src={this.state.baseUrl + 'uploads/chatAttach/'+values.file_name} 
                                    alt=""
                                    width="150px"
                                    style={{float: 'right'}} />
                             {values.description} */}
                          {/* </div> */}
                        </span>
                       :
                       <span>
                          <div class="message-data">
                            <span class="message-data-name"><i class="fa fa-circle online"></i> {values.userName}</span>
                          </div>
                          {/* <div class="message my-message"> */}
                          {/* <img src={this.state.baseUrl + 'uploads/chatAttach/'+values.file_name} 
                                    alt=""
                                    width="150px" /> */}

                          {values.file_extension == 'jpg' || values.file_extension == 'png' || values.file_extension == 'jpeg' ||  
                           values.file_extension == 'JPG' || values.file_extension == 'PNG' || values.file_extension == 'JPEG' ? 
                            <div class="message my-message cht-immg">
                              <Zoom>
                            <img src={this.state.baseUrl+ 'uploads/chatAttach/'+values.file_name} 
                                  alt=""
                                  width="150px" />
                                  </Zoom>
                            </div>
                            :
                            values.file_extension == 'mp4' || values.file_extension == 'MP4' || values.file_extension == '3gp'?
                            <div>
                            {/* <video width="320" height="240" controls> */}
                              {/* <source src={this.state.baseUrl+ 'uploads/chatAttach/'+values.file_name} type="video/mp4" /> */}
                              {/* <source src={this.state.baseUrl+ 'uploads/chatAttach/'+values.file_name} type="video/ogg" /> */}
                            {/* </video>   */}

                            <div class="message my-message">
                              <a href='javascript:void(0);' className="text-white" style={{textDecoration: 'none'}}
                                                            onClick={() => this.openMedia(values.chat_history_id) }>
                              {/* {values.file_name} */}
                              <span class="material-icons">videocam</span> Click to play video
                              </a>
                            </div>
                            
                            

                            {this.state.showMediaSection == values.chat_history_id  ? 
                            <div class="message my-message cht-immg">
                              <RenderMediaComponent mediaFileUrl= {this.state.baseUrl+ 'uploads/chatAttach/'+values.file_name} 
                                                  mediaFileName = {values.file_name} 
                                                  onClick={true} 
                                                  />
                            </div>
                            :
                            null
                                                
                            }
                            
                            </div>
                            :
                            <div class="message my-message">
                              {values.description}
                            </div>
                          }


                          
                          {/* </div> */}
                       </span>
                      }
                      
                    </li>

                    ))}


                  </ul>
                </div> 
                
                    <div className="chat-message clearfix">
                      <textarea name="description" id="description" 
                                placeholder="Type your message" 
                                rows="3" 
                                value={this.state.description}
                                onChange={this.onChangeHandler}></textarea>
                              
                        {/* <input className="fa fa-file-image-o" type="file" id="image_url" name='image_url' onChange={this.handleImagePreview} /> */}
                        <label for="image_url">
                          {/* <i className="fa fa-paperclip"></i> */}
                          <span class="material-icons" style={{fontSize: '26px'}}>attach_file</span>
                        </label> &nbsp;
                        <input type="file" id="image_url" name='image_url' onChange={this.handleImagePreview} style={{display: "none"}} />
                        <span style={{fontSize: '12px'}}>{this.state.attach_file_name}</span>

                        {/* <label><i className="fa fa-camera" onClick={() => this.openCamera() }></i></label> &nbsp; */}

                        {this.state.cameraOpen ? 
                          <Camera onTakePhotoAnimationDone = {this.handleTakePhotoAnimationDone} />
                          :
                          null
                        }

                        {this.state.previewImage ? 
                          <ImagePreview dataUri={this.state.dataUri} />
                          :
                          null
                        }
                        
                        <span style={{color: '#D90429', fontSize: '12px'}}>{this.state.chatMessageError}</span>

                        <div className="typing-loader" id="typing-loader"></div>

                      <button className="send-cht-btn" id="sendBtnCht" onClick={() => this.submitInsertChat() }>Send
                        <span className="material-icons chat_mat_icn">send</span>
                      </button>
                    </div> 
                
              </div> 


            </div>
          
      )
    }
}

export default ChatDetailsComp