import React, {Component} from 'react';
import FixesActiveComp from '../component/Fixes/FixesActiveComp'
import CustomToolbar from '../component/Toolbar/CustomToolbar'
import SideDrawer from '../component/SideDrawer/SideDrawer'
import Backdrop from '../component/Backdrop/Backdrop'
import BottomNavigation from '../component/BottomNavigation/BottomNavigation'

class FixesActive extends Component {

  state = {
      sideDrawerOpen: false,
    }

    drawerToggleClickHandler = () => {
      this.setState(prevState => {
        return { sideDrawerOpen: !prevState.sideDrawerOpen }
      })
    }

    backdropClickHandler = () => {
      this.setState({ sideDrawerOpen: false })
    }
    

  render() {
      let backdrop

      if (this.state.sideDrawerOpen) {
        backdrop = <Backdrop click={this.backdropClickHandler} />
      }

      // const isMobile = window.innerWidth <= 500;
      return (
          <div style={{height: '100%'}}>

              <CustomToolbar drawerClickHandler={this.drawerToggleClickHandler} 
                       propsName = 'fixes' 
                       headTitle = 'Active Fixes' />

              <SideDrawer show={this.state.sideDrawerOpen} />

              {/* BackDrop */}
              {backdrop}

              {/* *********** Main content starts here *********** */}

              <main>
              <div className="top__align__tool_nv"></div>
                  
                <FixesActiveComp />
                
                {/* <div class="adjust-screens-bottom"></div> */}
              </main>

              <BottomNavigation navigationPropsName = 'fixes' />
          </div>
      )
  }

}

export default FixesActive
