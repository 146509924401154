import React, {Component} from 'react'
import { Link, Redirect } from "react-router-dom";
import './Profilee.css'
import { axiosInstance } from '../../Services/PostData';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Profilee extends Component {

    constructor(){
        super();
        this.state = {
          
        };
        this.getProfile = this.getProfile.bind(this)
      }
 
      componentDidMount() {
        const baseUrl = window.$baseUrl;
        this.setState({baseUrl})

        if(cookies.get('techUserId') == undefined) {
            this.setState({loginRdirect: true})
        }
        else {
            this.getProfile();
        }
        
      }
      
      getProfile() {

        //show spinner 
        this.setState({ showSpinner: true });

        const body = {
			user_id: cookies.get('techUserId'),
            };
            
        axiosInstance.post(`getProfiles`, body)
        .then(res => {
           if(res.data.status == 'success' && res.data.statusCode =='200' )
           {	
                let tech_user_name = res.data.profile_details[0]['user_name'];
                let tech_user_email = res.data.profile_details[0]['user_email'];
                let tech_rate       = res.data.profile_details[0]['rate'];
                let avatar          = res.data.profile_details[0]['image_name'];
                let user_currency   = res.data.profile_details[0]['currency_symbol'];

                this.setState({ tech_user_name, tech_user_email, tech_rate, avatar, user_currency});

                cookies.set('techFixerUserName', res.data.profile_details[0].user_name)
                cookies.set('techFixerUserEmail', res.data.profile_details[0].user_email )
                cookies.set('techFixerUserAvatar', res.data.profile_details[0].image_name )
                cookies.set('techFixerUserCurrency', res.data.profile_details[0].currency_symbol )

                //hide spinner
                this.setState({ showSpinner: false });
           }
        })
        .catch(err => console.log('Signup: ', err));
      }

      

    render() {
        if(this.state.loginRdirect) {
            return <Redirect to="/" />;
        }
        
    return (
        <div className="main-profilee">
            <div className="profile-block">
                <div className="panel text-center">
                    <div className="user-heading"> 
                        <Link><img src={ this.state.baseUrl + "uploads/avatar/" +this.state.avatar} alt="" title="" /> </Link>
                        <h1>{this.state.tech_user_name}</h1>
                        <p>{this.state.tech_user_email}</p>
                        <p>Rate per hours : {this.state.user_currency} {this.state.tech_rate}</p>
                    </div>
                    <ul className="nav nav-pills nav-stacked">
                        <li className="active">
                            <Link to="/Certificate">
                                {/* <i className="fa fa-certificate"></i> */}
                                {/* <img src="assets/icons/menu-icons/images/certificate.png" className="icon-drw" /> */}
                                <img src="assets/icons/menu-icons-1/experience.png" className="icon-drw" />
                            Certifications 
                                <span className="material-icons nextt">navigate_next</span>
                            </Link>
                        </li>

                        <li className="active">
                            <Link to="/skills">
                                {/* <i className="fa fa-tasks"></i> */}
                                {/* <img src="assets/icons/menu-icons/images/skill.png" className="icon-drw" /> */}
                                <img src="assets/icons/menu-icons-1/star.png" className="icon-drw" />
                            Skills 
                                <span className="material-icons nextt">navigate_next</span>
                            </Link>
                        </li>
                        
                        <li className="active">
                            <Link to="/TechProof">
                                <img src="assets/icons/menu-icons-1/proof.png" className="icon-drw" />
                                    Proofs 
                                <span className="material-icons nextt">navigate_next</span>
                            </Link>
                        </li>

                        <li><Link>
                        {/* <i className="fa fa-usd" aria-hidden="true"></i> */}
                        {/* <img src="assets/icons/menu-icons/images/payment.png" className="icon-drw" /> */}
                        <img src="assets/icons/menu-icons-1/money.png" className="icon-drw" />
                        Transaction History 
                        <span className="material-icons nextt">navigate_next</span></Link></li>

                        {/* <li><Link>
                        <img src="assets/icons/menu-icons-1/share.png" className="icon-drw" />
                        Share with friends </Link></li> */}
                    </ul>
                </div>
            </div>

            {this.state.showSpinner ? 
                <div className="lds-dual-ring custom-screen-middle"></div>
                :
                null
            }
        </div>
    )
}
}

export default Profilee