import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../logo-ts.png';
import { axiosInstance } from '../Services/PostData';
// import Popup from "reactjs-popup";
import swal from 'sweetalert2';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Login extends Component {
	apitoken;
	constructor(){
		super();
		this.state = {
			prefix: '',
			username: '',
			password: '',
			open: false,
			fcmtoken: '',
			teams: [],
			prefix: '',
			mobile_number_count: ''
		};
		this.login = this.login.bind(this)
		this.onChange = this.onChange.bind(this)
		this.forgotPassword = this.forgotPassword.bind(this)
		this.getCountryDetails = this.getCountryDetails.bind(this)
		this.getCountryPrefix = this.getCountryPrefix.bind(this)
	}

	componentDidMount() {
		// if(isMobile) {
        //     window.FirebasePlugin.getToken(function(token) {
		// 		this.apitoken = token;
		// 		document.getElementById('token').value = token;
        //     }, function(error) {
        //         console.error(error);
        //     });   
		// }
		// else if(isBrowser)
		// {
		// 	this.apitoken="test"
		// }		

		// var query = window.location.search.substring(1);
        // var pair = query.split("=");
        // var queryString = pair[0];
        // var queryValue = pair[1];
		// if(queryString == 'token') {
		// 	cookies.set('deviceTokenFcm', queryValue );
		// }

		this.getCountryDetails();
				
		let sessionTechid = cookies.get('techUserId');
		if(sessionTechid != undefined)
		{
			this.props.history.push({
				pathname: '/Dashboard'
			})
		}
	}

	getCountryPrefix(e) {
		this.state.prefix = e.target.value;
		this.state.mobile_number_count = e.target.selectedOptions[0].getAttribute('data-length');
	}

	getCountryDetails() {
		axiosInstance.get(`getCountryDetails`)
		.then(res => {
		   if(res.data.status === 'success' && res.data.statusCode ==='200' )
		   {	
			   //setting mobile number lengtha and prefix
			   this.state.mobile_number_count =  res.data.country_details[0].mobile_length;
			   this.state.prefix =  res.data.country_details[0].prefix;

			    let country_details = res.data.country_details;
				let teamsFromApi = country_details.map(team => {
					return team
				});
				this.setState({
					teams: teamsFromApi
				});
		   }
		})
		.catch(err => console.log('CountryDetails: ', err));
	}

	validate = () => {
		let usernameError = "";
		let passwordError = "";

		//username
		// if (!this.state.username) {
		// 	usernameError = "username cannot be blank";
		// }

		//Mobile Number
		if (!this.state.username) {
			usernameError = "Enter Mobile Number";
		}
		else if(this.state.username.length != this.state.mobile_number_count)
		{
			usernameError = "Your mobile number should be " + this.state.mobile_number_count + " Digit";
		}

		//password
		if (!this.state.password) {
			passwordError = "Password cannot be blank";
		}

		if (usernameError || passwordError ) {
		  this.setState({ usernameError, passwordError});
		  return false;
		}
	
		return true;

	  };

	login() {

		const user = {
			prefix: this.state.prefix,
			username: this.state.username,
			password: this.state.password,
			type: 'w'
			// token: this.apitoken
		  };

		  const isValid = this.validate();
    		if (isValid) {
				let spinner = 'lds-dual-ring custom-screen-middle';
				let overlay = 'overlay';
				this.setState({ spinner, overlay });

				let passwordError = '';
				this.setState({ passwordError});
				
				let errorMessageresponse = '';
				const body = user;
				axiosInstance.post(`Login`, body)
		 		.then(res => {
			  	if(res.data.status === 'success' && res.data.statusCode ==='200' )
			  	{
					//this.props.history.push("/Otp");
					this.props.history.push({
						pathname: '/Otp',
						search: '?userid=' + res.data.user_details
					})
				}
				else if(res.data.statusCode ==='400' ) {
					errorMessageresponse = res.data.msg
					this.setState({ errorMessageresponse});
				}

				let spinner = 'lds-dual-ring custom-screen-middle hide__container';
				let overlay = 'overlay hide__container';
				this.setState({ spinner, overlay });

		 		})
				 .catch(err => console.log('Login: ', err));
			}

	}

	forgotPassword() {
		const user = {
			prefix: this.state.prefix,
			username: this.state.username
		  };

		let usernameError = "";
		// if(this.state.username != '')
		// {
		if (!this.state.username) {
			usernameError = "Enter Mobile Number";
			this.setState({ usernameError});
		}
		else if(this.state.username.length != this.state.mobile_number_count)
		{
			usernameError = "Your mobile number should be " + this.state.mobile_number_count + " Digit";
			this.setState({ usernameError});
		}
		else 
		{	
			let spinner = 'lds-dual-ring custom-screen-middle';
			let overlay = 'overlay';
			this.setState({ spinner, overlay });

			let errorMessageresponse = '';
			axiosInstance.post(`forgotPassword`, user)
		 	.then(res => {
			if(res.data.status === 'success' && res.data.statusCode ==='200' )
			{
				//this.props.history.push("/Otp");
			  this.props.history.push({
				pathname: '/Otp',
				search: '?otpid=' + res.data.user_details
			  })
			}
			else if(res.data.statusCode ==='400' ) {
				errorMessageresponse = res.data.msg
				this.setState({ errorMessageresponse});
			}

			let spinner = 'lds-dual-ring custom-screen-middle hide__container';
			let overlay = 'overlay hide__container';
			this.setState({ spinner, overlay });
				
		 	})
		 	.catch(err => console.log('Login: ', err));
		}
		// else {
		// 	usernameError = "username cannot be blank";
		// 	this.setState({ usernameError});
		// }
	}
	
	onChange(e) {
		this.setState({[e.target.name]: e.target.value});
	}	

	
	render() {

	if(isMobile) {		
    return (
        
	// <div className="limiter">
	// 	<div className="container-login100">
	// 		<div className="wrap-login100 p-l-55 p-r-55 p-t-20 p-b-20">
	// 			<div className="text-center">
	// 				{/* <img className="customlogo" src={Logo} alt="" /> */}
	// 				{/* <img className="" src="assets/images/touchtofix.png" alt="" style={{width: '230px'}} /> */}
	// 				<img className="customlogo" src="assets/images/logo-white.png" alt="" />
	// 			</div>
	// 			<form method="post" className="login100-form validate-form">
	// 				{/* <span className="login100-form-title p-b-30">
	// 					Tech Support Login
	// 				</span> */}

	// 					<div className="wrap-input100 validate-input m-b-10">
	// 						<span className="label-input100">Mobile Number</span>
	// 						<div className="selected-flag" >
	// 							<select onChange={this.getCountryPrefix}>
	// 							{this.state.teams.map(team => (
	// 								<option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > 
	// 								{team.prefix} 
	// 								</option>
	// 							))}
	// 							</select>

	// 							<input className="input100 mobile_input sp" type="number" name="username" autoComplete="off"
	// 							   placeholder="Enter your mobile number" onChange={this.onChange} />
	// 						</div> 
	// 					</div>

	// 				<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.usernameError}</span>
					
	// 				<div className="wrap-input100 validate-input" data-validate="Password is required">
	// 					<span className="label-input100">Password</span>
	// 					<input className="input100 inpt-rnd" type="password" name="password" onChange={this.onChange} placeholder="Type your password" />
	// 					<span className="focus-input100" data-symbol="&#xf190;"></span>
	// 				</div>
	// 				<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.passwordError}</span>
	// 				{/* <input id="token" /> */}
	// 				<div className="container-login100-form-btn p-t-20">
	// 					<div className="wrap-login100-form-btn">
	// 						<div className="login100-form-bgbtn"></div>
	// 						<div  className="custom-txt-none custom-a-line-none">
	// 						<button type="button" className="login100-form-btn" onClick={this.login}>
	// 							Login
	// 						</button>
	// 						</div>
	// 					</div>
	// 				</div>
	// 				<div  className="m-b-5 text-center m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#D90429" }}>{this.state.errorMessageresponse}</div>
					
	// 				<div className="p-t-8 p-b-50">
	// 					<div className="float-right">
	// 						<p className="cursorPointer linkUnderline p-t-6" style={{color: '#EDF2F4'}} onClick={this.forgotPassword}> Forgot Password ?</p>
	// 					</div>
	// 					<div className="float-left">
	// 						<Link style={{color: '#EDF2F4'}} to="/Signup"> Sign Up </Link>
	// 					</div>
	// 				</div>
	// 			</form>
	// 		</div>
	// 	</div>


	// 	<div className={this.state.overlay}>
	// 		<div className={this.state.spinner}></div>
	// 	</div>

	// </div>

	
	<div className="limiter-login">
		<div className="top-container-login" style={{textAlign: 'center'}}>
			<img className="logo-classcustom" src="assets/images/logo-white.png" alt="" style={{width: '200px'}} />
				<span className="login100-form-title color-tmp-2 p-b-30"> TouchToFix-EF Login </span>
			</div>
			<div className="middle-container-login-form">
			<form method="post" className="login100-form validate-form">
			{/* <div className="wrap-input100 validate-input m-b-10">
				<span className="label-input100">Mobile Number</span>
				<div className="selected-flag" >
					<select onChange={this.getCountryPrefix}>
					{this.state.teams.map(team => (
						<option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > 
						{team.prefix} 
						</option>
					))}
					</select>

					<input className="input100 mobile_input sp" type="number" name="username" autoComplete="off"
						placeholder="Enter your mobile number" onChange={this.onChange} />
				</div> 
			</div> */}

			<div className="wrap-input100 validate-input m-b-10">
				<span className="label-input100">Country Code and Mobile Number</span>
				<div className="selected-flag" >
					<div className="flag-select-dropdown-country">
					<select onChange={this.getCountryPrefix} className="select-wrp">
						{this.state.teams.map(team => (
							<option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > {team.prefix} </option>
						))}
					</select>
					
					{/* <div className="class-arrow-mark"></div> */}
					</div>
					<input className="input100 mobile_input sp flag-select-input" type="number" name="username" autoComplete="off"
						placeholder="Enter your mobile number" onChange={this.onChange} />
				</div> 
			</div>
			<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.usernameError}</span>
			
			<div className="wrap-input100 validate-input" data-validate="Password is required">
				<span className="label-input100">Password</span>
				<input className="input100 inpt-rnd" type="password" name="password" onChange={this.onChange} placeholder="Type your password" />
				<span className="focus-input100" data-symbol="&#xf190;"></span>
			</div>
			<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.passwordError}</span>
			{/* <input id="token" /> */}
			<div className="container-login100-form-btn p-t-20">
				<div className="wrap-login100-form-btn">
					<div className="login100-form-bgbtn"></div>
					<div  className="custom-txt-none custom-a-line-none">
					<button type="button" className="login100-form-btn" onClick={this.login}>
						Login
					</button>
					</div>
				</div>
			</div>
			<div  className="m-b-5 text-center m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#D90429" }}>{this.state.errorMessageresponse}</div>

			{/* <p className="cursorPointer linkUnderline p-t-6 text-center color-tmp-1" style={{color: '#EDF2F4'}} onClick={this.forgotPassword}> Forgot Password ?</p> */}
			
			{/* <div className="p-t-8 p-b-50">
				<div className="float-right">
					<p className="cursorPointer linkUnderline p-t-6" style={{color: '#EDF2F4'}} onClick={this.forgotPassword}> Forgot Password ?</p>
				</div>
				<div className="float-left">
					<Link style={{color: '#EDF2F4'}} to="/Signup"> Sign Up </Link>
				</div>
			</div> */}
		</form>
			</div>

		{/* <div className="bottom-rgister-section">
			<p className="no-accnt color-tmp-1">Dont have an account ?</p>
			<Link to="/Signup" className="no-accnt-rgstr-btn color-tmp-5 linkUnderline">Register</Link>
		</div> */}
		
		<div className={this.state.overlay}>
			<div className={this.state.spinner}></div>
		</div>

	</div>
	)
	}
	else {
		return (
			<div className="limiter">
				<div className="container-login100 login-bg-screen">
					<div className="wrap-login100 p-l-55 p-r-55 p-t-20 p-b-20">
						<div className="text-center">
							<img className="customlogo" src={Logo} alt="" />
							{/* <img className="" src="assets/images/touchtofix.png" alt="" style={{width: '230px'}} /> */}
						</div>
						<form method="post" className="login100-form validate-form">
							<span className="login100-form-title p-b-30">
								TouchToFix Login
							</span>

								{/* <div className="wrap-input100 validate-input m-b-10">
									<span className="label-input100">Mobile Number</span>
									<div className="selected-flag" >
										<select onChange={this.getCountryPrefix}>
										{this.state.teams.map(team => (
											<option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > 
											{team.prefix} 
											</option>
										))}
										</select>

										<input className="input100 mobile_input sp" type="number" name="username" autoComplete="off"
										placeholder="Enter your mobile number" onChange={this.onChange} />
									</div> 
								</div> */}

					<div className="wrap-input100 validate-input m-b-10">
						<span className="label-input100">Country Code and Mobile Number</span>
						<div className="selected-flag" >
							<div className="flag-select-dropdown-country">
							<select onChange={this.getCountryPrefix} className="select-wrp">
								{this.state.teams.map(team => (
									<option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > {team.prefix} </option>
								))}
							</select>
							
							{/* <div className="class-arrow-mark"></div> */}
							</div>
							<input className="input100 mobile_input sp flag-select-input" type="number" name="username" autoComplete="off"
								placeholder="Enter your mobile number" onChange={this.onChange} />
						</div> 
					</div>

							<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.usernameError}</span>
							
							<div className="wrap-input100 validate-input" data-validate="Password is required">
								<span className="label-input100">Password</span>
								<input className="input100 inpt-rnd" type="password" name="password" onChange={this.onChange} placeholder="Type your password" />
								<span className="focus-input100" data-symbol="&#xf190;"></span>
							</div>
							<span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.passwordError}</span>
							{/* <input id="token" /> */}
							<div className="container-login100-form-btn p-t-20">
								<div className="wrap-login100-form-btn">
									<div className="login100-form-bgbtn"></div>
									<div  className="custom-txt-none custom-a-line-none">
									<button type="button" className="login100-form-btn" onClick={this.login}>
										Login
									</button>
									</div>
								</div>
							</div>
							<div  className="m-b-5 text-center m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#D90429" }}>{this.state.errorMessageresponse}</div>
							
							{/* <div className="p-t-8 p-b-50">
								<div className="float-right">
									<p className="cursorPointer linkUnderline p-t-6" style={{color: '#EDF2F4'}} onClick={this.forgotPassword}> Forgot Password ?</p>
								</div>
								<div className="float-left">
									<Link style={{color: '#EDF2F4'}} to="/Signup"> Sign Up </Link>
								</div>
							</div> */}
						</form>
					</div>
				</div>
				<div className={this.state.overlay}>
					<div className={this.state.spinner}></div>
				</div>
			</div>
		)
		
	}

	}
}

export default Login