import React, {Component} from 'react';
import SignupToolbar from '../component/Toolbar/SignupToolbar'
import SideDrawer from '../component/SideDrawer/SideDrawer'
import Backdrop from '../component/Backdrop/Backdrop'
// import CompProfileSignup from '../component/ProfileSignup/CompProfileSignup'
import { axiosInstance } from '../Services/PostData';
import queryString from 'query-string';
import '../component/ProfileSignup/CompProfileSignup.css'
import Select from 'react-select';


import Cookies from 'universal-cookie';
import { array } from 'prop-types';
const cookies = new Cookies();


// const colourOptions = [
//   { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
//   { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
//   { value: 'purple', label: 'Purple', color: '#5243AA' },
//   { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
//   { value: 'orange', label: 'Orange', color: '#FF8B00' },
//   { value: 'yellow', label: 'Yellow', color: '#FFC400' },
//   { value: 'green', label: 'Green', color: '#36B37E' },
//   { value: 'forest', label: 'Forest', color: '#00875A' },
//   { value: 'slate', label: 'Slate', color: '#253858' },
//   { value: 'silver', label: 'Silver', color: '#666666' },
// ];

const colourOptions = [];

class ProfileSignup extends Component {

    constructor(){
      super();
      this.state = {
        skills: [],
        image_file: null,
        image_preview: '',
        rate: '',
        experience: '',
        skill: [],
        skillset: '',
        avatar: '',
        redirect: "a",
        reposeValue: []
  
      };
      this.getSkillDetails = this.getSkillDetails.bind(this)
      this.onChange = this.onChange.bind(this)
      this.handleSubmitFile = this.handleSubmitFile.bind(this)
      this.getAvatarDetails = this.getAvatarDetails.bind(this)
      this.handleChange = this.handleChange.bind(this)
    }
  
      // Image Preview Handler
      handleImagePreview = (e) => {
          let image_as_base64 = URL.createObjectURL(e.target.files[0])
          let image_as_files = e.target.files[0];
  
          this.setState({
              image_preview: image_as_base64,
              image_file: image_as_files,
          })
      }
  
  
    validate = () => {
      let avatarError = "";
      let rateError = "";
      let experienceError = "";
      let skillError = "";
      let skillsetError = "";
  
      if (!this.state.avatar) {
        avatarError = "Choose avatar";
      }
      if (!this.state.rate) {
        rateError = "Rate cannot be blank";
      }
      if (!this.state.experience) {
        experienceError = "Experience cannot be blank";
      }
      if (this.state.skill == '') {
        skillError = "skill cannot be blank";
      }
      if (!this.state.skillset) {
        skillsetError = "skillset cannot be blank";
      }
  
      if (avatarError || rateError || experienceError || skillError || skillsetError) {
        this.setState({ avatarError, rateError, experienceError, skillError, skillsetError});
        return false;
      }
    
      return true;
  
      };
  
  
    onChange(e) {
      this.setState({[e.target.name]: e.target.value})
    }	
  
    onChangeSkill(e) {
  
      const options = this.state.skill;
      let index;
      if (e.target.checked) {
        options.push(+e.target.value)
      }
      else {
        index = options.indexOf(+e.target.value)
        options.splice(index, 1)
      }
    }

    handleChange = (selectedOptions) => {
      // console.log(selectedOptions)
      // console.log(selectedOptions[0].skill_category)

      const options_1 = [];
      for(var i=0; i < selectedOptions.length; i++) {
        options_1.push(selectedOptions[i].value)
      }
      
      this.setState({skill: options_1})
    }
  
    getSkillDetails() {
      axiosInstance.get(`getSkillsDetails`)
      .then(res => {
         if(res.data.status == 'success' && res.data.statusCode =='200' )
         {	
          console.log(colourOptions)
          let skill_details = res.data.skill_details;
          let skillDetails = skill_details.map(skill_category => {
            return skill_category
          });
          this.setState({
            skills: skillDetails
          });

          colourOptions.push(skillDetails);
          console.log(colourOptions)
         }
      })
      .catch(err => console.log(err));
    }
  
    handleSubmitFile() {
          // if (this.state.image_file !== null) {
            const isValid = this.validate();
            if (isValid) {
          
              let params = queryString.parse(this.props.location.search);
              this.state.user_id = params.userDetails;
  
              let formData = new FormData();
              if(this.state.image_file == null)
              {
                this.state.image_file = '';
              }
              formData.append('imageFile', this.state.image_file);
              formData.append('avatar_id', this.state.avatar);
              formData.append('rate', this.state.rate);
              formData.append('experience', this.state.experience);
              formData.append('skill', this.state.skill);
              formData.append('skillset', this.state.skillset);
              formData.append('user_id', this.state.user_id);
        
              // the image field name should be similar to your api endpoint field name
              // in my case here the field name is customFile
  
              axiosInstance.post(`techProfileDetails`, formData)
              .then(res => {
              if(res.data.status == 'success' && res.data.statusCode =='200' )
              {
                this.props.history.push({
                  pathname: '/Dashboard',
                  search: '?userid=' + res.data.userDetails
                  })
              }
              })
              .catch(err => console.log('Signup: ', err));
            
              }
      }
  
    componentDidMount() {
      const baseUrl = window.$baseUrl;
      this.setState({baseUrl})

      this.getSkillDetails();
      this.getAvatarDetails()
    }

    getAvatarDetails() {
        let params = queryString.parse(this.props.location.search);
        let user_id = params.userDetails;

        const body = { user_id: user_id };
        axiosInstance.post(`getProfileAvatarDetails`, body)
        .then(res => {
        if(res.data.status == 'success' && res.data.statusCode =='200' )
        {
              let response = res.data.avatar_details;
              let currency_symbol = res.data.avatar_details[0].currency_symbol;
              let response_value = response.map(values_response => { return values_response });

              this.setState({ reposeValue: response_value, currency_symbol });
        }
        })
        .catch(err => console.log('Signup: ', err));
    }

    render() {
        let backdrop

        if (this.state.sideDrawerOpen) {
          backdrop = <Backdrop click={this.backdropClickHandler} />
        }

        return (
            <div>
                <SignupToolbar drawerClickHandler={this.drawerToggleClickHandler} />
                <main>
                <div className="top__align__tool_nv"></div>
                    
                    {/* <CompProfileSignup /> */}
                    
                    <div >
                    <div className="container-fluid mt-3 main-top-top">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <form>
                                <div className="custom-profile-grid mb-2">
                                    <p><b>Choose Avatar</b></p>
                                    <div className="row" id="avatars">

                                    {this.state.reposeValue.map(values => (
                                      <div className="col-md-4 col-4 ">
                                            <label class="profile-avatars">
                                            <input type="radio" name="avatar" value={values.avatar_id} onChange={this.onChange} />
                                            <img src={this.state.baseUrl+"uploads/avatar/" + values.image_name} alt="" />
                                            </label>
                                      </div>
                                    ))}
        
                                        {/* <div className="col-md-4 col-4 profile-avatars">
                                            <input type="radio" name="avatar" value="1" onChange={this.onChange} />
                                            <img src="assets/images/avator-1.png" alt="" />
                                        </div>
                                        <div className="col-md-4 col-4 profile-avatars">
                                            <input type="radio" name="avatar" value="2" onChange={this.onChange} />
                                            <img src="assets/images/avator-2.png" alt="" />
                                        </div>
                                        <div className="col-md-4 col-4 profile-avatars">
                                        <input type="radio" name="avatar" value="3" onChange={this.onChange} />
                                            <img src="assets/images/avator-3.png" alt="" />
                                        </div>
                                        <div className="col-md-4 col-4 profile-avatars">
                                        <input type="radio" name="avatar" value="4" onChange={this.onChange} />
                                            <img src="assets/images/avator-1.png" alt="" />
                                        </div>
                                        <div className="col-md-4 col-4 profile-avatars">
                                        <input type="radio" name="avatar" value="5" onChange={this.onChange} />
                                            <img src="assets/images/avator-1.png" alt="" />
                                        </div> */}
                                    </div>
                                    <span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.avatarError}</span>
                                </div>

                                <div className="custom-profile-form mb-2">
                                    <div className="row">
                                        <div className="col-md-12 col-12 mb-2">
                                            <label className="mb-1">Expected Rate Per Hour ( {this.state.currency_symbol} )</label>
                                            <input type="number" name="rate" className="form-control borderRadius-20" onChange={this.onChange} autoComplete="off" />
                                            <span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.rateError}</span>
                                        </div>

                                        <div className="col-md-12 col-12 mb-2">
                                            <label className="mb-1">Experience in years</label>
                                            <input type="number" name="experience" className="form-control borderRadius-20" onChange={this.onChange} autoComplete="off" />
                                            <span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.experienceError}</span>
                                        </div>
                                          
                                        {/* <div className="col-md-12 col-12 mb-2">
                                            <label className="mb-1" style={{display: 'block'}}>Skills</label>
                                            {this.state.skills.map(skill => (
                                              <span key={skill.skill_category_id}>
                                              <input type="checkbox" id={skill.skill_category_id} name="skills" value={skill.skill_category_id} onChange={this.onChangeSkill.bind(this)} />
                                              <label for={skill.skill_category_id}>{skill.skill_category}</label>
                                              </span>
                                            ))}
                                            <span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.skillError}</span>
                                        </div> */}

{/* <Select
  defaultValue={[colourOptions[2], colourOptions[3]]}
  isMulti
  name="colors"
  options={colourOptions}
  className="basic-multi-select"
  classNamePrefix="select"
/> */}

                                        <div className="col-md-12 col-12 mb-2">
                                            <label className="mb-1" style={{display: 'block'}}>Skills</label>
                                            <Select 
                                              className="select__skill_category"
                                              isMulti
                                              name="colors"
                                              options={this.state.skills}
                                              className="basic-multi-select"
                                              classNamePrefix="select" 
                                              onChange={this.handleChange}
                                            />
                                          </div>

                                        <div className="col-md-12 col-12 mb-2">
                                            <label className="mb-1">Skill Set</label>
                                            <textarea placeholder="Ex: Java, Ubuntu, Linux.." className="form-control txt-fld borderRadius-20" rows="2" name='skillset' onChange={this.onChange}></textarea>
                                            <span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.skillsetError}</span>
                                        </div>


                                        <div className="col-md-12 col-12 mb-2">
                                            <label className="mb-1">Certifications</label>
                                            <input type="file" className="form-control cert-inp " name="image_url" onChange={this.handleImagePreview} />
                                        </div>
                                        <div className="col-md-12 col-12 mb-2" onClick={this.handleSubmitFile}>
                                            <div className="btn-cntue_pr">
                                            <p className="contue-btn cursorPointer">Continue</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                  </div>
                  </div>

                </main>

            </div>
        )
    }

}

export default ProfileSignup
