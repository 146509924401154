import React, {Component} from 'react'
import { Link, Redirect } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import './CompCertificate.css'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class CompAddProof extends Component {

    constructor() {
        super();
        this.state = {
            certificate_fixers: [],
            noCertificateClass: 'hide__container',
            imageFile: '',
            image_file: null,
            proof_name: ""
        };
        this.getProfile = this.getProfile.bind(this)
        this.submitProfileEdit = this.submitProfileEdit.bind(this)
        this.onChangeHandler = this.onChangeHandler.bind(this)
      }
 
      componentDidMount() {
        // this.getProfile();
      }
      
      getProfile() {
        const body = {
			user_id: cookies.get('techUserId')
        };
            
        axiosInstance.post(`getFixerCertificate`, body)
        .then(res => {
           if(res.data.status == 'success' && res.data.statusCode =='200' )
           {	
               if(res.data.certificate_details.length == 0)
               {
                   this.state.noCertificateClass = 'show';
               }
                let certificate_details = res.data.certificate_details;
                let CertificateDetails = certificate_details.map(certificate => {
                    return certificate
                });
                this.setState({
                    certificate_fixers: CertificateDetails
                });
           }
        })
        .catch(err => console.log('Signup: ', err));
      }

        // Image Preview Handler
        handleImagePreview = (e) => {
            let image_as_base64 = URL.createObjectURL(e.target.files[0])
            let image_as_files = e.target.files[0];
        
            this.setState({
                image_preview: image_as_base64,
                image_file: image_as_files,
            })
        }

      submitProfileEdit() {

        if(this.state.proof_name == '') {
            this.setState({prrof_name_error: "Proof name should not be blank"})
        }
        else if (this.state.image_file !== '' && this.state.imageFile !== undefined && this.state.image_file !== null) {
           
            this.setState({imageError: "", prrof_name_error: ""})

            let errorMessageresponse = '';
            let formData = new FormData();
            formData.append('imageFile', this.state.image_file);
            formData.append('proof_name', this.state.proof_name);
            formData.append('tech_user_id',  cookies.get('techUserId'));

            //show spinner 
            let spinner = 'lds-dual-ring custom-screen-middle';
            let overlay = 'overlay';
            this.setState({ spinner, overlay });

            axiosInstance.post(`addFixerProof`, formData)
            .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                //hide spinner
                let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                let overlay = 'overlay hide__container';
                this.setState({ spinner, overlay });

                this.setState({proofRedirect: true});
            }
            else if(res.data.statusCode ==='400' ) {
                errorMessageresponse = res.data.msg
                this.setState({ errorMessageresponse});
            }
            })
            .catch(err => console.log('Login: ', err));
        }
        else {
            this.setState({imageError: "Please Choose Certificate", prrof_name_error: ""})
        }

        }

    onChangeHandler(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {

        if (this.state.proofRedirect) {
            return <Redirect to="/TechProof"/>;
        }

    return (
        <div class="mt-3 main-profilee">

            <div className="tickmarkClass">
                <Link onClick={this.submitProfileEdit}><i class="material-icons backBtnClas">check</i></Link>
            </div>

            <div className="row" style={{width: '100%', paddingTop: '20px'}}>
                <div class="col-lg-4 col-md-4 col-4 col-xs-4 ad__tk__lft">
                    <p>Proof Name : </p>
                </div>
                <div class="col-lg-8 col-md-8 col-8 col-xs-8" style={{padding: '0'}}>
                    <input type="text" class="form-control borderRadius-20" name='proof_name' onChange={this.onChangeHandler} />
                    <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.prrof_name_error}</span>
                </div>
            </div>
            <div class="row" style={{width: '100%', paddingTop: '20px'}}>
                <div class="col-lg-4 col-md-4 col-4 col-xs-4 ad__tk__lft">
                    <p>Choose File :</p>
                </div>
                <div class="col-lg-8 col-md-8 col-8 col-xs-8 ad__tk__rgt" style={{padding: '0'}}>
                    <input type="file" class="form-control" name='image_url' onChange={this.handleImagePreview} />
                    <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.imageError}</span>
                </div>
            </div>

            <div className={this.state.overlay}>
			    <div className={this.state.spinner}></div>
		    </div>

        </div>
    )
}
}
 
export default CompAddProof