import React, {Component} from 'react'
import {Link, Redirect } from 'react-router-dom'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import './Toolbar.css'
import { axiosInstance } from '../../Services/PostData';
import { SocketConnection } from '../../Services/SocketConnection';

import io from "socket.io-client";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

let socket = io.connect(SocketConnection)
// const toolbar = props => {

class toolbar extends Component {

  timer=0;
  constructor(props) {
      super(props);
      this.state = {
          sideDrawerOpen: props.drawerClickHandler 
      };
      // this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount()  {
      this.getNotification()

      socket.emit('join room', cookies.get('tech_socket_subscribeId'))

      socket.on('tech_fixer_notification_count', (count) => {
        document.getElementById("notification_count").innerHTML = count;
      })   

      document.onclick = function() {
        var dropdowns2 = document.getElementById("myDropdown");
        if(dropdowns2 != null) {
          dropdowns2.classList.remove("show");
        }
      }

    }

    getNotification() {
        var notifiFunc = function() {
            const user = { user_id: cookies.get('techUserId') };
            axiosInstance.post(`getNotificationCount`, user)
            .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' ) {
                let notificationCount = res.data.count_details;
                document.getElementById("notification_count").innerHTML = notificationCount;
            }
            })
            .catch(err => console.log(err));
        };
        notifiFunc();
        // this.timer=setInterval(notifiFunc, 3000)
    }

    readNotificationStatus() {
        const user = { user_id: cookies.get('techUserId') };
        axiosInstance.post(`readNotificationStatus`, user)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' ) {
            
        }
        })
        .catch(err => console.log(err));
    }

    componentWillUnmount() {
      clearInterval(this.timer);
    }

    topMenu = event => { 
      document.getElementById("myDropdown").classList.toggle("show");
      event.preventDefault();
    }

render() {

    // const topMenu = () => { 
    //   document.getElementById("myDropdown").classList.toggle("show");
    // }

    document.onclick = function() {
      var dropdowns2 = document.getElementById("myDropdown");
      if(dropdowns2 != null) {
        dropdowns2.classList.remove("show");
      }
    }

    const logoutFucntion = () => { 

      const body = { user_id: cookies.get('techUserId') };
      axiosInstance.post(`logout`, body)
      .then(res => {
      if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
      {
        cookies.remove('techUserId');
        window.location.reload(false)
      }
      })
      .catch(err => console.log(err));

      // cookies.remove('techUserId')
      // window.location.reload(false)
    }
    var notificationCount=1;


return (  
    <header className="toolbar">
        <nav className="toolbar__navigation">
            <div className="toggle___button">
              <DrawerToggleButton click={this.state.sideDrawerOpen} />
            </div>
            <div className="toolbar__logo cd-logo"><img src="assets/images/customer-logo.png" alt="" /></div>
          <div className="spacer" />
          <div className="toolbar_navigation-items">
            {/* <ul>
              <li>
                <a href="/"></a>
              </li>
            </ul> */}
          </div>

          {/* <ul className="js-cd-nav__list">
              <li className=" top_toolbr"> */}
                  {/* <span className="bellIcons">
                    <Link to="/FixesNotification" onClick={this.readNotificationStatus}>
                      <i className="fa fa-bell" aria-hidden="true" style={{color: '#ffffff'}}></i>
                      <span className="badge" id="notification_count"></span>
                    </Link>
                  </span> */}
                  {/* <span className="ellipseIcons" >
                      <i onClick={this.topMenu} className="fa fa-ellipsis-v dropbtn" aria-hidden="true" style={{color: '#ffffff'}}></i>
                  </span> */}
              {/* </li>
          </ul> */}

        </nav>

        <div id="myDropdown" className="dropdown-content">
            {/* <Link to="/Authenticator?auth">Authenticator</Link> */}
            {/* <Link >App Version</Link> */}
            <Link onClick={logoutFucntion}>Logout</Link>
        </div>
      </header>
)
}
}

export default toolbar