import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom'
import Toolbar from '../component/Toolbar/Toolbar'
import SideDrawer from '../component/SideDrawer/SideDrawer'
import Backdrop from '../component/Backdrop/Backdrop'
import BottomNavigation from '../component/BottomNavigation/BottomNavigation'
import CompDashboard from '../component/Dashboard/CompDashboard'
import { axiosInstance } from '../Services/PostData';
// import queryString from 'query-string';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Cookies from 'universal-cookie';
const cookies = new Cookies();


    

class Dashboard extends Component {

    timer=0;
    notificationCount=0;
    constructor(){
		super();
		this.state = {
            sideDrawerOpen: false,
            negotiationForm: 'hide__container',
            techDashboarClass: 'hide__container',
            negotiationAmountFixers:'',
            afterApproval: 'pad-2 color-dark',
            // count_details: '0'
        }
		this.handleoOnchangeNegotionForm = this.handleoOnchangeNegotionForm.bind(this)
		this.handleSubmitNegotiationForm = this.handleSubmitNegotiationForm.bind(this)
		this.getNotification = this.getNotification.bind(this)
    }

      drawerToggleClickHandler = () => {
        this.setState(prevState => {
          return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
      }

      backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
      }


    // *************************************************************************************************

    async componentDidMount() { 
        if(cookies.get('techUserId') == undefined) {
            // this.props.history.push({
			// 	pathname: '/'
            // })

            this.setState({redirect: true});

        }
        else {

            let spinner = 'lds-dual-ring custom-screen-middle';
            let overlay = 'overlay';
            this.setState({ spinner, overlay });

            await this.getDashboardDetails(); 
            // this.getNotification();
            this.getUserDetails();

            let getChatSessionRoomId = cookies.get('sessionTechChatRoomId');
            if(getChatSessionRoomId != undefined) {
                this.setState({getChatSessionRoomId: true})
            }

            this.getChatAvailableStatus();
        }
    }

    getUserDetails() {
        const user = { user_id: cookies.get('techUserId') };
        axiosInstance.post(`getProfiles`, user)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' ) {
            cookies.set('techFixerUserName', res.data.profile_details[0].user_name)
            cookies.set('techFixerUserEmail', res.data.profile_details[0].user_email )
            cookies.set('techFixerUserAvatar', res.data.profile_details[0].image_name )
            cookies.set('techFixerUserCurrency', res.data.profile_details[0].currency_symbol )
        }
        })
        .catch(err => console.log(err));
    }

    getChatAvailableStatus() {
        const postvalues = { user_id: cookies.get('techUserId') };
        axiosInstance.post(`getChatAvailableStatus`, postvalues)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            let chat_response = res.data.chat_details;
            if(chat_response.length !=0) {
                cookies.set('sessionTechChatRoomId', chat_response[0].chat_room_id) 
            }
        }
        })
        .catch(err => console.log(err));
    }
    

    getNotification() {

        var notifiFunc = function() {
            const user = { user_id: cookies.get('techUserId') };
            axiosInstance.post(`getNotificationCount`, user)
            .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' ) {
                this.notificationCount = res.data.count_details;
                console.log(this.notificationCount)
            }
            })
            .catch(err => console.log(err));
        };
                
        notifiFunc();
        // this.timer=setInterval(notifiFunc, 3000)


        // const user = {
        //     user_id: cookies.get('techUserId')
        // };
        
        // axiosInstance.post(`getNotificationCount`, user)
        // .then(res => {
        // if(res.data.status === 'success' && res.data.statusCode ==='200' ) {
        //     let count_details = res.data.count_details;
        //     this.setState({count_details})
        // }
        // })
        // .catch(err => console.log(err));
    }


    async getDashboardDetails()
    {            
        // this.state.user_id = localStorage.getItem('techUserId');
        this.state.user_id =  cookies.get('techUserId')
        const user = {
            userDetails: this.state.user_id
        };

        await axiosInstance.post(`getDashboardDetails`, user)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            let status_approval = res.data.approval_status;
            let negotiate_request = res.data.negotiate_request;
            let suspend_status = res.data.suspend_status;

            this.setState({ status_approval, negotiate_request, suspend_status });

            if(status_approval == 0)
            {
                this.negotiationLayoutFunction(res);

                // let techDashboarClass = 'main__top_dsh hide__container';
                // this.setState({ techDashboarClass });

                // //check tech approval
                // if(res.data.fixer_accepted_status == '1')
                // {
                //     let techAcceptedStatusYes = 'pad-2 color-green';
                //     let techNegotiationStatus = 'hide__container';
                //     let afterApproval = 'hide__container';

                //     let techAcceptedAmount = res.data.negotiate_amount;
                //     this.setState({ techAcceptedStatusYes, techAcceptedAmount, techNegotiationStatus, afterApproval });
                // }
                // else {
                //     let techAcceptedStatusYes = 'hide__container';
                //     let techNegotiationStatus = '';
                //     this.setState({ techAcceptedStatusYes, techNegotiationStatus });
                // }
                
                // let fixer_rate = res.data.fixer_rate;
                // let negotiate_rate = res.data.negotiate_amount;
                // let negotiation_Id = res.data.negotiationId;
                // this.setState({ fixer_rate, negotiate_rate, negotiation_Id});

                // if(res.data.negotiationId == '') {
                //     let techNegotiationStatus = 'hide__container';
                //     this.setState({techNegotiationStatus});
                // }
                // if(res.data.negotiate_type == 'T') {
                //     let acceptanceClassName = 'hide__container';
                //     this.setState({acceptanceClassName});
                // }
            }
            else {

                if(negotiate_request == 1) {
                    this.negotiationLayoutFunction(res);

                    //let techDashboarClass = 'main__top_dsh hide__container';
                    //this.setState({ techDashboarClass });
                }
                else {

                    let techDashboarClass = 'main__top_dsh';
                    this.setState({ techDashboarClass });
                    let approvalDashboardClass = 'hide__container';
                    this.setState({ approvalDashboardClass });

                    let assign_ticket    = res.data.assign_ticket;
                    let open_ticket    = res.data.open_ticket;
                    let private_ticket = res.data.private_ticket;
                    let abort_ticket   = res.data.abort_ticket;
                    let closed_ticket  = res.data.closed_ticket;
                    this.setState({ assign_ticket, open_ticket, private_ticket, abort_ticket, closed_ticket });
                }

            }
        
            }
        })
        .catch(err => console.log('Login: ', err));
    }

    negotiationLayoutFunction(res) {

        let techDashboarClass = 'main__top_dsh hide__container';
        this.setState({ techDashboarClass });

        //check tech approval
        if(res.data.fixer_accepted_status == '1')
        {
            let techAcceptedStatusYes = 'pad-2 color-green';
            let techNegotiationStatus = 'hide__container';
            let afterApproval = 'hide__container';

            let techAcceptedAmount = res.data.negotiate_amount;
            this.setState({ techAcceptedStatusYes, techAcceptedAmount, techNegotiationStatus, afterApproval });
        }
        else {
            let techAcceptedStatusYes = 'hide__container';
            let techNegotiationStatus = '';
            this.setState({ techAcceptedStatusYes, techNegotiationStatus });
        }
        
        let fixer_rate = res.data.fixer_rate;
        let negotiate_rate = res.data.negotiate_amount;
        let negotiation_Id = res.data.negotiationId;
        this.setState({ fixer_rate, negotiate_rate, negotiation_Id});

        if(res.data.negotiationId == '') {
            let techNegotiationStatus = 'hide__container';
            this.setState({techNegotiationStatus});
        }
        if(res.data.negotiate_type == 'T') {
            let acceptanceClassName = 'hide__container';
            this.setState({acceptanceClassName});
        }
    }

    acceptFixersAmount(userId) {
        confirmAlert({
            message: 'Yes i am accepting this rate',
            buttons: [
              {
                label: 'Submit',
                onClick: () => {
                     const user = {
                        user_id: userId,
                        approval_status: '1',
                        amount: this.state.negotiate_rate,
                        negotiateId : this.state.negotiation_Id
                      };
                    
                    axiosInstance.post(`fixersAmountApprovalStatus`, user)
                    .then(res => {
                    if(res.data.status === 'success' && res.data.statusCode ==='200' )
                    {
                        window.location.reload(false)
                    }
                    })
                    .catch(err => console.log(err));
                }
              },
              {
                label: 'Cancel'
              }
            ]
          });
    }

    //negotiation form

    showNegotiationForm() {
        let negotiationForm = 'show__container';
        this.setState({ negotiationForm });
    }
    
    handleoOnchangeNegotionForm(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmitNegotiationForm() {
        if(this.state.negotiationAmountFixers == '')
        {
            let negotiationFieldError = 'Rate should cannot be blank';
            this.setState({ negotiationFieldError });
        }
        else if(this.state.negotiationAmountFixers != '' && this.state.negotiationAmountFixers != undefined) {

            let negotiationFieldError = '';
            this.setState({ negotiationFieldError });

            const postvalues = {
                user_id: this.state.user_id,
                amount: this.state.negotiationAmountFixers,
                type: 'T'
            };

            axiosInstance.post(`submitNegotiationAmount`, postvalues)
            .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                window.location.reload(false)
            }
            })
            .catch(err => console.log(err));
        }
    }
      

    render() {
        let backdrop;
        if (this.state.sideDrawerOpen) {
          backdrop = <Backdrop click={this.backdropClickHandler} />
        }

        if (this.state.redirect) {
            return <Redirect push to="/"/>;
        }
        
        
        if(this.state.suspend_status == 1 ) {
            return (
                
                <div style={{height: '100%'}}>

                <Toolbar drawerClickHandler={this.drawerToggleClickHandler}  />
                <SideDrawer show={this.state.sideDrawerOpen} />
                {backdrop}
                <div className="text-center w-100 custom-screen-middle">
                    <img src="assets/images/not-approval.png" className="empty-ticket-img" alt="" />
                    <h5 className="empty-ticket-refresh">
                        Yor are suspended by TouchToFix, Please contact our support team.
                    </h5>
                </div>

                {/* <BottomNavigation navigationPropsName = 'home' /> */}
                </div>
                
            )
        }
        else if( this.state.status_approval == 1 && this.state.negotiate_request == 0 ) {
            return (
                <div style={{height: '100%'}}>

                     <Toolbar drawerClickHandler={this.drawerToggleClickHandler}  />
                     <SideDrawer show={this.state.sideDrawerOpen} />
                     {backdrop}
    
                     <main>
                     <div className="top__align__tool_nv"></div>
                     {this.state.getChatSessionRoomId ? 
                    <Link className="chatContainer" to="/ChatDetails">
                        <div className="you-are-in-chat">
                        {/* <i class="material-icons left-nav__icon chat-online-icn chat-online-blink">chat</i>  */}
                        <i className="fa fa-circle chat-online-icn chat-online-blink"></i>
                        <span>You are in Chat</span>
                        </div> 
                    </Link>
                        : 
                        null
                    }
                     <div className={this.state.techDashboarClass}>

                    
                     <ul className="main-assin_num">
                         <li><Link>{this.state.assign_ticket}<span className="fix__crc">Fixes assigned <br /> to me</span></Link></li>
                     </ul>
                  
                     {/* <img src="assets/images/img.jpg" width="100%;" /> */}
                     <img src="assets/images/dash-img-1.png" width="100%;" />
                     {/* <img src="assets/images/image.png" width="100%;" /> */}
    
                     <div className="col-md-12 col-12 ticket__section">
                         <div className="row customer-row">
                             <div className="col-md-3 col-3">
                                 <Link to="/FixesActive" style={{textDecoration: 'none'}}>
                                     <div className="customer-ticket-grid text-center">
                                         <img src="assets/images/icons/ticket.png" className="customer-icon" />
                                         <span style={{paddingLeft: '5px', display: 'block'}} className="ticket-grid-count">{this.state.open_ticket}</span>
                                         <h6 className="customer-ticket-title">Active</h6>
                                     </div>
                                 </Link>
                             </div>
                             <div className="col-md-3 col-3">
                                 <Link to="/FixesOpen" style={{textDecoration: 'none'}}>
                                     <div className="customer-ticket-grid text-center">
                                         <img src="assets/images/icons/security.png" className="customer-icon" />
                                         <span style={{paddingLeft: '5px', display: 'block'}} className="ticket-grid-count">{this.state.private_ticket}</span>
                                         <h6 className="customer-ticket-title">Open</h6>
                                     </div>
                                 </Link>
                             </div>
                             <div className="col-md-3 col-3">
                                 <Link to="/FixesAborted" style={{textDecoration: 'none'}}>
                                     <div className="customer-ticket-grid text-center">
                                         <img src="assets/images/icons/error.png" className="customer-icon" />
                                         <span style={{paddingLeft: '5px', display: 'block'}} className="ticket-grid-count">{this.state.abort_ticket}</span>
                                         <h6 className="customer-ticket-title">Aborted</h6>
                                     </div>
                                 </Link>
                             </div>
                             <div className="col-md-3 col-3">
                                 <Link to="/FixesClosed" style={{textDecoration: 'none'}}>
                                     <div className="customer-ticket-grid text-center">
                                         <img src="assets/images/icons/complete.png" className="customer-icon" />
                                         <span style={{paddingLeft: '5px', display: 'block'}} className="ticket-grid-count">{this.state.closed_ticket}</span>
                                         <h6 className="customer-ticket-title">Closed</h6>
                                     </div>
                                 </Link>
                             </div>
                             </div>
                         </div>
                     </div>
    
                       {/* <div className="adjust-screens-bottom"></div> */}
                     </main>
    
                     <BottomNavigation navigationPropsName = 'home' />
                 </div>
            )
        }
        else if( this.state.status_approval == 0 || this.state.negotiate_request == 1) {
            return (
                <div style={{height: '100%'}}>

                <Toolbar drawerClickHandler={this.drawerToggleClickHandler}  />
                <SideDrawer show={this.state.sideDrawerOpen} />
                {backdrop}

                <main>
                <div className="top__align__tool_nv"></div>
                <div className={this.state.approvalDashboardClass} >
                
                  <div className="cd-content-wrapper mystyle" id="main">
                    <div className="container-fluid mt-3">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="row">
                                    <div className="text-center w-100 custom-screen-middle">
                                        <img src="assets/images/not-approval.png" className="empty-ticket-img" alt="" />

                                        <h6 className={this.state.techAcceptedStatusYes}>Your Accepted amount is : {this.state.techAcceptedAmount}</h6>

                                        {/* <h5 className="empty-ticket-refresh">You are not yet approval for your rate from sanyaa</h5> */}
                                        <h5 className="empty-ticket-refresh">we are validating your profile please patient with touchtofix</h5>

                                        <h6 className={this.state.afterApproval}>Your amount is: {this.state.fixer_rate} </h6>
                                        <div className={this.state.techNegotiationStatus}>
                                            <h6 className="pad-4 color-green">Negotiate amount is: {this.state.negotiate_rate} </h6>
                                            <div className={this.state.acceptanceClassName} id="pad-2">
                                                <button className="btn btn-success custom-amount-btn" 
                                                    onClick={() => this.acceptFixersAmount(this.state.user_id, )} >Accept</button >
                                                    &nbsp;&nbsp;
                                                <button className="btn btn-danger custom-amount-btn" 
                                                    onClick={() => this.showNegotiationForm(this.state.user_id, )}>Reject</button >
                                            </div>
                                            <div className={this.state.negotiationForm}>
                                                <p className="pad-1 pad-t-5">Enter Your amount :</p>
                                                <input className="m-b-2" type="text" name="negotiationAmountFixers" onChange={this.handleoOnchangeNegotionForm} />
                                                <button className="btn btn-custom custom-amount-btn" onClick={() => this.handleSubmitNegotiationForm() }>Submit</button>
                                                <span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.negotiationFieldError}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                </div>

                  {/* <div className="adjust-screens-bottom"></div> */}
                </main>

                <BottomNavigation navigationPropsName = 'home' />
            </div>
            )
        }
        else {
            return (
                
                <div style={{height: '100%'}}>

                <Toolbar drawerClickHandler={this.drawerToggleClickHandler}  />
                <SideDrawer show={this.state.sideDrawerOpen} />
                {backdrop}

                <div className={this.state.overlay}>
                    <div className={this.state.spinner}></div>
                </div>

                <BottomNavigation navigationPropsName = 'home' />
                </div>
                
            )
        }
        
        

        // return (
            // <p></p>
            // <div style={{height: '100%'}}>

            //     <Toolbar drawerClickHandler={this.drawerToggleClickHandler}  />
            //     <SideDrawer show={this.state.sideDrawerOpen} />
            //     {backdrop}

            //     <main style = {{ marginTop: '56px'}}>
                    
            //     <div className={this.state.approvalDashboardClass} >
                
            //       <div className="cd-content-wrapper mystyle" id="main">
            //         <div className="container-fluid mt-3">
            //             <div className="row">
            //                 <div className="col-md-12 col-12">
            //                     <div className="row">
            //                         <div className="text-center w-100 custom-screen-middle">
            //                             <img src="assets/images/not-approval.png" className="empty-ticket-img" alt="" />

            //                             <h6 className={this.state.techAcceptedStatusYes}>Your Accepted amount is : {this.state.techAcceptedAmount}</h6>

            //                             <h5 className="empty-ticket-refresh">You are not yet approval for your rate from sanyaa</h5>

            //                             <h6 className={this.state.afterApproval}>Your amount is: {this.state.fixer_rate} </h6>
            //                             <div className={this.state.techNegotiationStatus}>
            //                                 <h6 className="pad-4 color-green">Negotiate amount is: {this.state.negotiate_rate} </h6>
            //                                 <div className={this.state.acceptanceClassName} id="pad-2">
            //                                     <button className="btn btn-success custom-amount-btn" 
            //                                         onClick={() => this.acceptFixersAmount(this.state.user_id, )} >Accept</button >
            //                                         &nbsp;&nbsp;
            //                                     <button className="btn btn-danger custom-amount-btn" 
            //                                         onClick={() => this.showNegotiationForm(this.state.user_id, )}>Reject</button >
            //                                 </div>
            //                                 <div className={this.state.negotiationForm}>
            //                                     <p className="pad-1 pad-t-5">Enter Your amount :</p>
            //                                     <input className="m-b-2" type="text" name="negotiationAmountFixers" onChange={this.handleoOnchangeNegotionForm} />
            //                                     <button className="btn btn-custom custom-amount-btn" onClick={() => this.handleSubmitNegotiationForm() }>Submit</button>
            //                                     <span  className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.negotiationFieldError}</span>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
                
            //     </div>

            //     <div className={this.state.techDashboarClass}>
            //     <ul className="main-assin_num">
            //         <li><Link>{this.state.assign_ticket}<span className="fix__crc">Fixes assigned <br /> to me</span></Link></li>
            //     </ul>
                
            //     <img src="assets/images/img.jpg" width="100%;" />

            //     <div className="col-md-8 col-12 ticket__section">
            //         <div className="row customer-row">
            //             <div className="col-md-3 col-3">
            //                 <Link style={{textDecoration: 'none'}}>
            //                     <div className="customer-ticket-grid text-center">
            //                         <img src="assets/images/icons/ticket.png" className="customer-icon" />
            //                         <span style={{paddingLeft: '5px'}} className="ticket-grid-count">{this.state.open_ticket}</span>
            //                         <h6 className="customer-ticket-title">Open</h6>
            //                     </div>
            //                 </Link>
            //             </div>
            //             <div className="col-md-3 col-3">
            //                 <Link style={{textDecoration: 'none'}}>
            //                     <div className="customer-ticket-grid text-center">
            //                         <img src="assets/images/icons/security.png" className="customer-icon" />
            //                         <span style={{paddingLeft: '5px'}} className="ticket-grid-count">{this.state.private_ticket}</span>
            //                         <h6 className="customer-ticket-title">Private</h6>
            //                     </div>
            //                 </Link>
            //             </div>
            //             <div className="col-md-3 col-3">
            //                 <Link style={{textDecoration: 'none'}}>
            //                     <div className="customer-ticket-grid text-center">
            //                         <img src="assets/images/icons/error.png" className="customer-icon" />
            //                         <span style={{paddingLeft: '5px'}} className="ticket-grid-count">{this.state.abort_ticket}</span>
            //                         <h6 className="customer-ticket-title">Aborted</h6>
            //                     </div>
            //                 </Link>
            //             </div>
            //             <div className="col-md-3 col-3">
            //                 <Link style={{textDecoration: 'none'}}>
            //                     <div className="customer-ticket-grid text-center">
            //                         <img src="assets/images/icons/complete.png" className="customer-icon" />
            //                         <span style={{paddingLeft: '5px'}} className="ticket-grid-count">{this.state.closed_ticket}</span>
            //                         <h6 className="customer-ticket-title">Closed</h6>
            //                     </div>
            //                 </Link>
            //             </div>
            //             </div>
            //         </div>
            //     </div>

            //       <div className="adjust-screens-bottom"></div>
            //     </main>

            //     <BottomNavigation navigationPropsName = 'home' />
            // </div>
        // )
    }

}

export default Dashboard

