import React, {Component} from 'react'
import { Link } from "react-router-dom"
import { axiosInstance } from '../../Services/PostData'
import './CompAddCertificate.css'
//zoom image
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Profilee extends Component {

    constructor() {
        super();
        this.state = {
            certificate_fixers: [],
            noCertificateClass: false
        };
        this.getProfile = this.getProfile.bind(this)
      }
 
      componentDidMount() {

        const baseUrl = window.$baseUrl;
        this.setState({baseUrl})

        this.getProfile();
      }
      
      getProfile() {
        const body = {
			user_id: cookies.get('techUserId')
        };
            
        axiosInstance.post(`getFixerCertificate`, body)
        .then(res => {
           if(res.data.status == 'success' && res.data.statusCode =='200' )
           {	
                if(res.data.certificate_details.length == 0) {
                    this.setState({noCertificateClass: true})
                }

                let certificate_details = res.data.certificate_details;
                let CertificateDetails = certificate_details.map(certificate => {
                    return certificate
                });
                this.setState({ certificate_fixers: CertificateDetails });
           }
           else {
                if(res.data.certificate_details.length == 0) {
                    this.setState({noCertificateClass: true})
                }
           }
        })
        .catch(err => console.log('Signup: ', err));
      }

    render() {
        
    return (
        // <div class="mt-3 main-profilee">
        <div class="main-profilee">
            <div class="profile-block">
                {/* <div className={this.state.noCertificateClass}>
                    <p> No Certificate Added</p>
                </div> */}

                {
                this.state.noCertificateClass ? 
                    <div style={{ backgroundColor: '#ffffff'}}>
                        <div class="text-center w-100 custom-screen-middle">
                            <img src="assets/images/empty-ticket.png" class="empty-ticket-img" />
                            <h5 class="empty-ticket-title">No Certificate</h5>
                        </div>
                    </div>
                :
                null
                }

                {this.state.certificate_fixers.map(certificates => (
                    <div class="right__menu_cr text-center">
                        <Zoom><img src={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} /></Zoom>
                    </div>
				))}
            </div>
        </div>
    )
}
}
 
export default Profilee