import React, {Component} from 'react'
import './faqcomp.css'

class FaqComp extends Component  {

render() {
    return (
        <div className="container-fluid"> 
        <div className="faq-single">
            <p className="faq-head">What is TouchToFix?</p>
            <p className="faq-head-ans"> Technical Support As Server(TSAS)-TouchToFix is a Brand name for Mobile App which provides
             IT Expert Support Services online Solution and Troubleshooting.</p>
        </div>
        
        <div className="faq-single">
            <p className="faq-head">What’s the difference Between getting a solution from local IT support Shop/Vendor and TouchTofix?</p>
            <p className="faq-head-ans">TouchTofix is ad hoc basis remote support to your Computers and IT devices Via mobile app.
                    Time Based payment, IT Experts,Connecting Securely, transparently interact with your Computer without leaving or handovering,Speed and Quality.
            </p>
        </div>
            
        <div className="faq-single">
            <p className="faq-head">What are Types of TSAS Mobile applications available?</p>
            <p className="faq-head-ans-list">
            a).FixCustomer- A Mobile application to get support free or Time based services.
            </p>
            <p className="faq-head-ans-list">
            b).ExpertFixer -A application for IT Tech Expert can be used to register, connect to a solution requester.
            </p>
        </div>
            
        <div className="faq-single">
            <p className="faq-head">What types Tech support can avail from the TouchToFix Mobile application?</p>
            <p className="faq-head-ans">
            Any Computer/IT Related Expert Support Service Either Offline(Rich Chat) or Online Remote Connection.
            </p>
        </div>
            
        <div className="faq-single">
            <p className="faq-head">Who are ExpertFixers?</p>
            <p className="faq-head-ans">
            ExpertFixers are Virtual Employees of TSAS-TouchToFix, Not freelancers.they are selected using an extensive onboarding process with terms and conditions.
            </p>
        </div>
            
        <div className="faq-single">
            <p className="faq-head">What are the types of TSAS security used?</p>
            <p className="faq-head-ans-list"> ❖	Trust no one (TNO) Policy.</p>
            <p className="faq-head-ans-list"> ❖	Multi Factor Authentication.(Password and OTP).</p>
            <p className="faq-head-ans-list"> ❖	Remote Connection FixCustomer -ExpertFixer through end-to-end SSL.</p>
            <p className="faq-head-ans-list"> ❖	Every connection between FixCustomer -ExpertFixer encrypted and uniqueSeesionID.</p>
            <p className="faq-head-ans-list"> ❖	Privacy Between FixCustomer -ExpertFixer.(Including names and pictures).</p>
        </div>
            
        <div className="faq-single">
            <p className="faq-head">What is the Payment Method?</p>
            <p className="faq-head-ans-list">Our Mobile App is Freemium is designed to Search IT Expert who consistently deliver at the Free /hourly based Services of their field.Mode of payments are Gpay,card,others</p>
            
        </div>
            
        </div>
    )
}
}

export default FaqComp