import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import './ChatDashboard.css'
import { axiosInstance } from '../../Services/PostData';
import swal from 'sweetalert2';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class CompChatDashboard extends Component  {

    render() {
        return(

            <div class="clearfix no-pad custom-scroll">

            <div class="text-center w-100 custom-screen-middle">
                    <h5 class="empty-ticket-title">No chat available</h5>
              </div>
                    
            </div>
              
        // <div class="clearfix no-pad custom-scroll">

        //     <div class="row chat-profile-spacing">
        //         <div class="col-md-3 col-3">
        //             <img class="chat-p-img" src="assets/images/avator-1.png" />
        //         </div>
        //         <a style={{textDecoration: 'none', width: '50%'}}>
        //             <div class="col-md-9 col-9 no-pl">
        //                 <h6 class="custom-chat-name">Customer 001</h6>
        //                 <p class="custom-last-chat">Hi Customer 001</p>
        //             </div>
        //         </a>
        //     </div>
        //     <div class="row chat-profile-spacing">
        //         <div class="col-md-3 col-3">
        //             <img class="chat-p-img" src="assets/images/avator-1.png" />
        //         </div>
        //         <a style={{textDecoration: 'none', width: '50%'}}>
        //             <div class="col-md-9 col-9 no-pl">
        //                 <h6 class="custom-chat-name">Customer 002</h6>
        //                 <p class="custom-last-chat">Hi Ayyappan! See you after work?</p>
        //             </div>
        //         </a>
        //     </div>
                
        // </div>
    
      )
    }
}

export default CompChatDashboard