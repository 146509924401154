import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import './CompDashboard.css'
import { axiosInstance } from '../../Services/PostData';
import queryString from 'query-string';


class CompDashboard extends Component {

    constructor(){
		super();
		this.state = {
            tickets: [],
            techies: [],
            testvalue: '',
            user_id: ''
        };
		this.getDashboardDetails = this.getDashboardDetails.bind(this);
	}
	
	getDashboardDetails()
	{
        let params = queryString.parse(this.props.location.search);
        this.state.user_id = params.userid;

        const user = {
			userDetails: this.state.user_id
          };
          
		axiosInstance.post(`getDashboardDetails`, user)
		.then(res => {
		    if(res.data.status === 'success' && res.data.statusCode ==='200' )
		    {
                let status_approval = res.data.approval_status;
                
                if(status_approval == 0)
                {
                    let techDashboarClass = 'main__top_dsh hide__container';
                    this.setState({ techDashboarClass });
                }
                else {
                    let techDashboarClass = 'main__top_dsh';
                    this.setState({ techDashboarClass });
                }
				
            }
		})
		 .catch(err => console.log('Login: ', err));
	}

	componentDidMount() { 
        this.getDashboardDetails(); 
        
    }
    
    render() {
        
    return (
        <div>

            <div>
                <h6 style={{padding: '10px', textAlign: 'center', lineHeight: '2', color: '#D90429' }}>Your are not het aprroval for your rate for sanyaa</h6>
            </div>

            <div className={this.state.techDashboarClass}>
                <ul className="main-assin_num">
                    <li><a href="#">129 <span className="fix__crc">Fixes assigned <br /> to me</span></a></li>
                </ul>
                <img src="assets/images/img.jpg" width="100%;" />

                <div className="col-md-8 col-12 ticket__section">
                    <div className="row customer-row">
                        <div className="col-md-3 col-3">
                            <a href="noticket.html" style={{textDecoration: 'none'}}>
                                <div className="customer-ticket-grid text-center">
                                    <img src="assets/images/icons/ticket.png" className="customer-icon" />
                                    <span className="ticket-grid-count">25</span>
                                    <h6 className="customer-ticket-title">Open</h6>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-3">
                            <a href="noticket.html" style={{textDecoration: 'none'}}>
                                <div className="customer-ticket-grid text-center">
                                    <img src="assets/images/icons/security.png" className="customer-icon" />
                                    <span className="ticket-grid-count">37</span>
                                    <h6 className="customer-ticket-title">Private</h6>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-3">
                            <a href="noticket.html" style={{textDecoration: 'none'}}>
                                <div className="customer-ticket-grid text-center">
                                    <img src="assets/images/icons/error.png" className="customer-icon" />
                                    <span className="ticket-grid-count">12</span>
                                    <h6 className="customer-ticket-title">Aborted</h6>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 col-3">
                            <a href="noticket.html" style={{textDecoration: 'none'}}>
                                <div className="customer-ticket-grid text-center">
                                    <img src="assets/images/icons/complete.png" className="customer-icon" />
                                    <span className="ticket-grid-count">12</span>
                                    <h6 className="customer-ticket-title">Closed</h6>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>

  )
}
}

export default CompDashboard