import React, {Component} from 'react'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import './Toolbar.css'


class SignupToolbar extends Component {

render() {


return (  
    <header className="toolbar">
        <nav className="toolbar__navigation">
            <div className="toggle___button">
              {/* <DrawerToggleButton click={this.state.sideDrawerOpen} /> */}
            </div>
            <div className="toolbar__logo cd-logo"><img src="assets/images/customer-logo.png" alt="" /></div>
          <div className="spacer" />
          
        </nav>

      </header>
)
}
}

export default SignupToolbar