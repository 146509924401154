import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import CustomToolbar from '../component/Toolbar/CustomToolbar'
const cookies = new Cookies();

class Appversion extends Component {

    // componentDidMount() {
    //     windowcordova.getAppVersion.getVersionNumber(function (version) {
    //         alert(version);
    //     });
    // }
render() {
return (
      <div>
          <CustomToolbar drawerClickHandler={this.drawerToggleClickHandler} 
                         propsName = 'aboutDetails' 
                         headTitle = 'App Version'
                         addLink = ''
                         backLink = '/Dashboard' />

          <p>App Version</p>
      </div>
    )
  }
}
 
export default Appversion